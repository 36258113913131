<div class="slideout-content default-slideout incident-view-sidebar">
	<div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
		<div class="modal-dialog full-width-sideout">
			<div class="modal-content pt-0 pb-0">
				<div class="modal-header d-flex justify-content-between align-items-center">
					<h5 class="modal-title fw-600 mb-0">
						<div *ngIf="sourceID" class="avatar bg-light-primary avatar-sm squer-border mr-25">
							<div class="avatar-content">
								<i 
									class="fa-light fa-{{
										sourceIconMap[
										sourceID
										]
									}}"
								></i>
							</div>
						</div>
						{{ process_data_copy?.display_id }}
					</h5>
					<div class="d-flex align-items-center">
						<!-- <button class="btn btn-flat-primary btn-sm waves-effect px-50 body-color mr-1" rippleeffect>
							<i class="fa-light fa-rectangle-history-circle-plus"></i>
							{{'UI.k_new_window' | translate}}
						</button> -->
						<button
							type="button"
							data-dismiss="modal"
							id="btnClose"
							name="btnClose"
							aria-label="Close"
							(click)="incidentDetailSidebar('processData-view-sidebar')"
							class="btn btn-flat-secondary btn-icon"
							rippleEffect
							[attr.data-testid]="'btnClose'"
						>
							<i class="fa-light fa-xmark"></i>
						</button>
					</div>
				</div>
				<div class="modal-content-body flex-1 position-relative" id="custom-padding">
					<div class="sidebar-menu-left bg-body border-right flex-auto-height">
						<!-- <span class="menu-heading flex-initial"> 
              <i class="fa-light fa-ticket"></i>
              <span>{{"UI.k_ticket_categories" | translate}}</span>
            </span>                                      -->
						<div class="list-group incident-option-list flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<ng-container *ngFor="let item of configuration_list; let i = index">
									<a
										*ngIf="!item?.diableSSP || (item?.diableSSP && !is_ssp_login)"
										id="btn{{ item.handle }}"
										name="menu{{ item.handle }}"
										class="list-group-item list-group-item-action bg-body span-{{i}}"
										[class.active]="selectedItem === i"
										(click)="menuClick(item.handle, i, item)"
										attr.data-testid="btn_{{ item.handle }}"
									>
										<i class="fa-light {{ item.icon }}" attr.data-testid="btn_{{ item.handle }}_{{ item.icon }}"></i>
										<span class="align-middle">
											<ng-container *ngIf="item.handle === 'incident_details'"><span class="ml-0">{{moduleName}} </span> <span class="ml-0">{{"UI.k_details" | translate}}</span></ng-container>
											<ng-container *ngIf="item.handle !== 'incident_details'">
												{{ item.title }}
											</ng-container>
										</span>
									</a>
								</ng-container>
							</ng-scrollbar>
						</div>
					</div>
					<div *ngIf="dataLoaded" class="flex-auto-height">
						<div class="incident-view-detail-grid flex-1">
							<div class="flex-auto-height">
								<div class="flex-initial">
									<div class="p-1 border-bottom d-flex justify-content-between align-items-start">
										<div class="d-flex flex-column">
											<div class="title-color fw-500">
												{{
													process_data_copy?.basic_info?.summary?.length > 100
														? (process_data_copy?.basic_info?.summary | slice: 0:100) + "..."
														: process_data_copy?.basic_info?.summary
												}}
											</div>
											<span class="xs-font">
												{{ "UI.k_last_updated_on" | translate }}
												{{ process_data_copy?.last_update_time }}
												{{ "UI.k_and_created_on" | translate }}
												{{ process_data_copy?.creation_time }}
											</span>
										</div>
										<div class="d-flex justify-content-between align-items-start">
											<div class="inciEditSidebar pr-50 border-right" *ngIf="permissions?.edit && disable_config === false && !is_ssp_login">
												<ng-container *ngIf="moduleName === 'Incident'">
													<a
													name="btnIncidentEdit"
													[attr.data-testid]="'btnIncidentEdit'"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													placement="left"
													id="btnIncidentEdit"
													(click)="toggleInLineEdit('dns-incident-edit-sidebar')"
													class="pull-right"
												>
													<img
														src="assets/images/incident-edit.svg"
														alt="Avatar"
														width="14"
														height="14"
													/>
												</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Request'">
													<a
													id="btnRequestEdit"
													name="btnRequestEdit"
													[attr.data-testid]="'btnRequestEdit'"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													placement="left"
													(click)="toggleInLineEdit('request-edit-sidebar')"
													class="pull-right"
												>
													<img
														src="assets/images/incident-edit.svg"
														alt="Avatar"
														width="14"
														height="14"
													/>
												</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Problem'">
													<a
													id="btnProblemEdit"
													name="btnProblemEdit"
													[attr.data-testid]="'btnProblemEdit'"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													placement="left"
													(click)="toggleInLineEdit('problem-edit-sidebar')"
													class="pull-right"
												>
													<img
														src="assets/images/incident-edit.svg"
														alt="Avatar"
														width="14"
														height="14"
													/>
												</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Change'">
													<a
													id="btnChangeEdit"
													name="btnChangeEdit"
													[attr.data-testid]="'btnChangeEdit'"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													placement="left"
													(click)="toggleInLineEdit('change-edit-sidebar')"
													class="pull-right"
												>
													<img
														src="assets/images/incident-edit.svg"
														alt="Avatar"
														width="14"
														height="14"
													/>
												</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Release'">
													<a
													id="btnReleaseEdit"
													name="btnReleaseEdit"
													[attr.data-testid]="'btnReleaseEdit'"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													placement="left"
													(click)="toggleInLineEdit('release-edit-sidebar')"
													class="pull-right"
												>
													<img
														src="assets/images/incident-edit.svg"
														alt="Avatar"
														width="14"
														height="14"
													/>
												</a>
												</ng-container>
											</div>
											<ng-container *ngIf="moduleName !== 'Release'">
												<div
												*ngIf="
												((process_data_copy?.basic_info?.state?.id < 4 &&
												this.request_permissions?.add &&
												!process_data_copy?.request?.request_id && permissions?.view) || 
												(process_data_copy?.basic_info?.state?.id < 4 &&
												this.change_permissions?.add &&
												!process_data_copy?.change?.change_id && permissions?.view) || 
												(process_data_copy?.basic_info?.state?.id < 4 && this.ticket_permissions?.add
												&& !process_data_copy?.incident?.incident_id) || 
												(process_data_copy?.incident?.incident_id && this.ticket_permissions?.view) || 
												((process_data_copy?.basic_info?.state?.id === 4 ||
												process_data_copy?.basic_info?.state?.id === 5) &&
											!process_data_copy?.kb_id &&
											this.kb_permissions?.add && moduleName !== 'Change') || 
											(process_data_copy?.request?.request_id && this.request_permissions?.view) || 
											(process_data_copy?.change?.change_id && this.change_permissions?.view) || 
											(process_data_copy?.problem?.problem_id && this.problem_permissions?.view) || 
											((process_data_copy?.basic_info?.state?.id === 4 ||
											process_data_copy?.basic_info?.state?.id === 5) &&
											process_data_copy?.kb_id &&
											this.kb_permissions?.view)) && !is_ssp_login
												"
												ngbDropdown
												class="no-arrow incident-action-dropdown"
												[placement]="'bottom-right'"
											>
												<span ngbDropdownToggle class="px-50 mr-50" id="drdActionUser">
													<i class="fa-light fa-ellipsis-vertical fw-500 lg-font"></i>
												</span>
												<div ngbDropdownMenu>
													<ng-container *ngIf="moduleName === 'Incident'">
															<a
															*ngIf="
																process_data_copy?.basic_info?.state?.id < 4 &&
																this.request_permissions?.add &&
																!process_data_copy?.request?.request_id && permissions?.view
															"
															(click)="OnShowConvInciToReq()"
															id="btnConvInciToReq"
															name="btnConvInciToReq"
															[attr.data-testid]="'btnConvInciToReq'"
															ngbDropdownItem
															href="javascript:void(0)"
															>{{ "UI.k_add_request" | translate }}
															</a>
															<a
															*ngIf="
																process_data_copy?.basic_info?.state?.id < 4 &&
																this.change_permissions?.add &&
																!process_data_copy?.change?.change_id && permissions?.view
															"
															(click)="onShowInciToChn()"
															id="btnConvInciToChn"
															name="btnConvInciToChn"
															[attr.data-testid]="'btnConvInciToChn'"
															ngbDropdownItem
															href="javascript:void(0)"
															>{{ "UI.k_add_change" | translate }}
														</a>
														<a
														*ngIf="
															process_data_copy?.basic_info?.state?.id < 4 &&
															this.problem_permissions?.add &&
															!process_data_copy?.problem?.problem_id && permissions?.view
														"
														(click)="onShowInciToProb()"
														id="btnInciToProb"
														name="btnInciToProb"
														[attr.data-testid]="'btnInciToProb'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_add_problem" | translate }}
														</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Request'">
													<a
													*ngIf="process_data_copy?.basic_info?.state?.id < 4 && this.ticket_permissions?.add
														&& !process_data_copy?.incident?.incident_id"
													(click)="OnShowConvReqToInci()"
													id="btnConvReqToInci"
													name="btnConvReqToInci"
													[attr.data-testid]="'btnConvReqToInci'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_add_ticket" | translate }}
													</a>
													<a
													*ngIf="process_data_copy?.incident?.incident_id && this.ticket_permissions?.view"
													(click)="onShowConvReqToInciView()"
													id="btnConvReqToInciView"
													name="btnConvReqToInciView"
													[attr.data-testid]="'btnConvReqToInciView'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_view_ticket" | translate }}
													</a>
													<a
													*ngIf="
													process_data_copy?.basic_info?.state?.id < 4 &&
														this.change_permissions?.add &&
														!process_data_copy?.change?.change_id
														" 
													(click)="onShowReqToChn()"
													id="btnReqToChn"
													name="btnReqToChn"
													[attr.data-testid]="'btnReqToChn'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_add_change" | translate }}
													</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Problem'">
													<a
													*ngIf="process_data_copy?.basic_info?.state?.id < 4 && this.ticket_permissions?.add
														&& !process_data_copy?.incident?.incident_id"
													(click)="OnShowConvProbToInci()"
													id="btnConvProbToInci"
													name="btnConvProbToInci"
													[attr.data-testid]="'btnConvProbToInci'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_add_ticket" | translate }}
													</a>
													<a
													*ngIf="process_data_copy?.incident?.incident_id && this.ticket_permissions?.view"
													(click)="onShowConvProbToInciView()"
													id="btnConvProbToInciView"
													name="btnConvProbToInciView"
													[attr.data-testid]="'btnConvProbToInciView'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_view_ticket" | translate }}
													</a>
													<a
													*ngIf="
													process_data_copy?.basic_info?.state?.id < 4 &&
														this.change_permissions?.add &&
														!process_data_copy?.change?.change_id
														" 
													(click)="onShowProbToChn()"
													id="btnProbToChn"
													name="btnProbToChn"
													[attr.data-testid]="'btnProbToChn'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_add_change" | translate }}
													</a>
												</ng-container>
												<ng-container *ngIf="moduleName === 'Change'">
													<a
													*ngIf="process_data_copy?.basic_info?.state?.id < 4 && this.ticket_permissions?.add
														&& !process_data_copy?.incident?.incident_id"
													(click)="OnShowConvChnToInci()"
													id="btnConvChnToInci"
													name="btnConvChnToInci"
													[attr.data-testid]="'btnConvChnToInci'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_add_ticket" | translate }}
													</a>
													<a
													*ngIf="process_data_copy?.incident?.incident_id && this.ticket_permissions?.view"
													(click)="onShowConvChnToInciView()"
													id="btnConvChnToInciView"
													name="btnConvChnToInciView"
													[attr.data-testid]="'btnConvChnToInciView'"
													ngbDropdownItem
													href="javascript:void(0)"
													>{{ "UI.k_view_ticket" | translate }}
													</a>
												</ng-container>
													<ng-container *ngIf="moduleName !== 'Change'">
														<ng-container *ngIf="(moduleName == 'Incident' || moduleName == 'Problem')">
															<a
															*ngIf="
																(process_data_copy?.basic_info?.state?.id === 4 ||
																	process_data_copy?.basic_info?.state?.id === 5) &&
																!process_data_copy?.kb_id &&
																this.kb_permissions?.add && !this.isinfraonAIFeautreFlagActive
															"
															(click)="toggleInciToKbSidebar('app-dns-kb-add')"
															id="btnKbAdd"
															name="btnKbAdd"
															[attr.data-testid]="'btnKbAdd'"
															ngbDropdownItem
															href="javascript:void(0)"
															>{{ "UI.k_conv_kb" | translate }}
														</a>
														</ng-container>
														<ng-container *ngIf="(moduleName != 'Incident' && moduleName != 'Problem')">
															<a
															*ngIf="
																(process_data_copy?.basic_info?.state?.id === 4 ||
																	process_data_copy?.basic_info?.state?.id === 5) &&
																!process_data_copy?.kb_id &&
																this.kb_permissions?.add
															"
															(click)="toggleInciToKbSidebar('app-dns-kb-add')"
															id="btnKbAdd"
															name="btnKbAdd"
															[attr.data-testid]="'btnKbAdd'"
															ngbDropdownItem
															href="javascript:void(0)"
															>{{ "UI.k_conv_kb" | translate }}
														</a>
														</ng-container>
														
													</ng-container>
													<ng-container *appFeatureActive=infraonAIFeautreFlag>
														<ng-container *ngIf="moduleName == 'Incident' || moduleName == 'Problem'">
															<a
															*ngIf="
																(process_data_copy?.basic_info?.state?.id === 4 ||
																	process_data_copy?.basic_info?.state?.id === 5) &&
																!process_data_copy?.kb_id &&
																this.kb_permissions?.add
															"
															(click)="onShowAIKBSolution()"
															id="btnAiKbAdd"
															name="btnAiKbAdd"
															[attr.data-testid]="'btnKbAdd'"
															ngbDropdownItem
															href="javascript:void(0)"
															>{{"UI.k_infiy_conv_kb" | translate }}
														</a>
														</ng-container>
													</ng-container>
													
													<a
														*ngIf="process_data_copy?.request?.request_id && this.request_permissions?.view"
														(click)="onShowConvInciToReqView()"
														id="btnConvInciToReqView"
														name="btnConvInciToReqView"
														[attr.data-testid]="'btnConvInciToReqView'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_request" | translate }}
													</a>

													<a
														*ngIf="process_data_copy?.change?.change_id && this.change_permissions?.view"
														(click)="onShowInciToChnView()"
														id="btnConvInciToChnView"
														name="btnConvInciToChnView"
														[attr.data-testid]="'btnConvInciToChnView'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_change" | translate }}
													</a>

													<a
														*ngIf="process_data_copy?.problem?.problem_id && this.problem_permissions?.view"
														(click)="onShowInciToProbView()"
														id="btnProToProbView"
														name="btnProToProbView"
														[attr.data-testid]="'btnProToProbView'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_problem" | translate }}
													</a>

													<a
														*ngIf="
															(process_data_copy?.basic_info?.state?.id === 4 ||
																process_data_copy?.basic_info?.state?.id === 5) &&
															process_data_copy?.kb_id &&
															this.kb_permissions?.view
														"
														(click)="viewKB()"
														id="btnKbsolution"
														name="btnKbsolution"
														[attr.data-testid]="'btnKbsolution'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_kb_soln" | translate }}
													</a>
												</div>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
								<div class="flex-1">
									<div class="flex-auto-height">
										<div class="flex-1">
											<!-- <div *ngIf="selectedItemName === 'incident_details'" class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                          <div class="p-1 border-bottom full-height">
                            <div class="reg-font fw-500 mb-1">{{"UI.k_basic_details" | translate}}</div>
                            <div class="detail-grid"> 
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span> 
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_priority" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div
                                  class="fw-500 sm-font py-0 badge badge-light-{{
                                    process_data_copy?.basic_info?.priority?.color
                                  }}"                                                                   
                                  >
                                  <i
                                    class="fa-light mr-25 mt-25 {{
                                      this.process_data_copy?.basic_info?.priority?.icon
                                    }}"
                                  ></i>
                                  {{
                                    this.process_data_copy?.basic_info?.priority?.display_key
                                      | translate
                                  }}
                                </div>
                                </div> 
                                <div class="edit-value">
                                  <div class="form-group">                                         
                                    <app-select-badge
                                      [inputConfig]="{
                                        hideLabel: true,
                                        inputOptions: dropdown?.priority,
                                        id: 'priority',
                                        model: this.process_data_copy?.basic_info?.priority.id,
                                        isEditDisabled: disable_config
                                      }"
                                      (onValueChange)="statusBadgeChange($event)"
                                    ></app-select-badge>
                                  </div>
                                </div>
                              </div>
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>                                 
                                <div class="default-value">
                                  <span class="sm-font">{{ "UI.k_status" | translate }} </span>
                                  <div class="group-lists d-flex align-items-center justify-content-start">                                    
                                    <div
                                      class="custom-squre"
                                      [ngClass]="'bg-' + process_data_copy?.basic_info?.status?.color"
                                      [ngStyle]="{
                                        'background-color':
                                          process_data_copy?.basic_info?.status?.color
                                      }"
                                    ></div>
                                    <span>{{
                                        process_data_copy?.basic_info?.status?.name | translate
                                    }}</span>
                                    <div
                                      class="right-arrow custom-text"
                                      [ngStyle]="{
                                        'background-color':
                                          process_data_copy?.basic_info?.state?.background_color,
                                        color:
                                          process_data_copy?.basic_info?.state?.text_color ||
                                          process_data_copy?.basic_info?.state?.color_hex
                                      }"
                                    >
                                      {{ process_data_copy?.basic_info?.status?.state | translate }}
                                    </div>                             
                                  </div>
                                </div> 
                                <div class="edit-value">                                
                                  <app-status-picker
                                    [inputConfig]="{
                                      isTextMode: true,
                                      stateOptionsMap: dropdown?.state_id_map,
                                      isEditDisabled: disable_config
                                    }"
                                    [options]="dropdown.transition_status"                                  
                                    [value]="process_data_copy?.basic_info?.status?.id"
                                  ></app-status-picker>
                                </div>
                              </div>        
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ 'UI.k_urgency' | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <span
                                    class="{{
                                      process_data_copy?.basic_info?.urgency?.color ? 'badge' : ''
                                    }} badge-light-{{
                                      process_data_copy?.basic_info?.urgency?.color
                                    }} sm-font title-color my-50"
                                  >
                                    {{
                                      process_data_copy?.basic_info?.urgency?.name
                                        ? process_data_copy?.basic_info?.urgency?.name
                                        : "-"
                                    }}
                                  </span>
                                </div>   
                                <div class="edit-value">                                                                        
                                  <div class="form-group">                                                                    
                                    <app-select-badge 
                                    [inputConfig]="{
                                      hideLabel: true,
                                      inputOptions: dropdown?.urgency,id:'urgency',
                                      model: this.process_data?.basic_info?.urgency.id,
                                      isEditDisabled: disable_config
                                    }"
                                    (onValueChange)="statusBadgeChange($event)"
                                    ></app-select-badge>                                                                
                                  </div> 
                                </div>       
                              </div>  
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ 'UI.k_severity' | translate }}</span>
                                </div>
                                <div class="default-value">
                                  <span
                                    class="my-50 sm-font title-color {{
                                      process_data_copy?.basic_info?.severity?.color ? 'badge' : ''
                                    }} badge-light-{{ process_data_copy?.basic_info?.severity?.color }} "
                                  >
                                    {{
                                      process_data_copy?.basic_info?.severity?.name
                                        ? process_data_copy?.basic_info?.severity?.name
                                        : "-"
                                    }}
                                  </span>
                                </div>
                                <div class="edit-value">
                                  <div class="form-group">                                                           
                                    <app-select-badge [inputConfig]="{
                                      hideLabel: true,
                                      inputOptions: dropdown?.severity,
                                      id:'severity',
                                      model: this.process_data_copy?.basic_info?.severity.id,
                                      isEditDisabled: disable_config
                                    }"
                                    ></app-select-badge>                                                         
                                  </div>
                                </div>
                              </div> 
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span> 
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_impact" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div class="sm-font title-color">
                                    {{
                                      process_data_copy?.basic_info?.impact?.name
                                        ? process_data_copy?.basic_info?.impact?.name
                                        : "-"
                                    }}
                                  </div>
                                </div>
                                <div class="edit-value">
                                  <div class="form-group">                                  
                                    <ng-select
                                      [items]="dropdown.impact"
                                      [hideSelected]="true"
                                      bindLabel="name"
                                      bindValue="id"
                                      [(ngModel)]="process_data_copy.basic_info.impact.id"
                                      name="txtIncidentImpact"
                                      id="txtIncidentImpact"
                                      placeholder="{{ 'UI.k_select' | translate }} {{
                                        'UI.k_impact' | translate
                                      }}"
                                      [disabled]="disable_config"
                                    >
                                    </ng-select>
                                  </div> 
                                </div>
                              </div>
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_impact_service" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div class="sm-font">
                                    {{
                                      process_data_copy?.basic_info?.impact_service_tree_path
                                        ? process_data_copy?.basic_info?.impact_service_tree_path
                                        : "-"
                                    }}
                                  </div>
                                </div>
                                <div class="edit-value">
                                  <div class="form-group input-tree-dropdown">                                  
                                    <app-single-tree-dropdown class="w-100" [items]="this.treeInputData" [value]="11" [id]="incidentId"></app-single-tree-dropdown>
                                  </div>
                                </div>
                              </div>
                              
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_event_status" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div class="sm-font">
                                    {{
                                      process_data_copy?.basic_info?.event_status
                                        ? process_data_copy?.basic_info?.event_status
                                        : "-"
                                    }}
                                  </div>
                                </div> 
                                <div class="edit-value">
                                  <div class="form-group">                                 
                                    <ng-select
                                      [items]="dropdown.event_status"
                                      [hideSelected]="true"
                                      bindLabel="name"
                                      bindValue="id"
                                      [(ngModel)]="process_data_copy.basic_info.event_status"
                                      name="txtProbEventType"
                                      id="txtProbEventType"
                                      placeholder="{{ 'UI.k_select_event_status' | translate }}"
                                      [disabled]="disable_config"
                                    >
                                    </ng-select>
                                  </div> 
                                </div>
                              </div>
                              
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_callback_options" | translate }} </span>
                                </div>
                                <div class="default-value"></div>                                                           
                                <div class="edit-value">
                                  <i
                                    class="fa-light fa-{{
                                      callbackIconMap[process_data_copy?.basic_info?.callback]
                                    }} text-primary mr-25"
                                  ></i>
                                  <span class="sm-font fw-500 title-color">
                                    {{
                                      this.process_data_copy?.basic_info?.callback
                                        ? callbackNameMap[process_data_copy?.basic_info?.callback]
                                        : "-"
                                    }}
                                  </span>
                                </div>                                
                              </div>                                                                                                                                                                                                                                                            
                            </div>
                          </div>
                          <div class="p-1 border-bottom full-height">
                            <div class="reg-font fw-500 mb-1">{{ "UI.k_reportedby" | translate }}</div>
                            <div class="detail-grid">         
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>    
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_name" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <span class="sm-font">
                                    {{
                                      process_data_copy?.reporter_info?.name
                                        ? process_data_copy?.reporter_info?.name
                                        : "-"
                                    }}</span>
                                </div> 
                                <div class="edit-value">                                                                        
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      [(ngModel)]="process_data.reporter_info.name"
                                      #TDReqNameRef="ngModel"
                                      name="txtInciRequesterName"
                                      id="txtInciRequesterName"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_name' | translate }}"
                                      minlength="3"
                                      maxlength="100"
                                      pattern="^[^\s]+(\s+[^\s]+)*$"
                                      [class.error]="!TDReqNameRef.valid"
                                      [disabled]="disable_config"
                                    />
                                    <!-- pattern = "^[a-zA-Z]+[a-zA-Z0-9_@. -]*$" 
                                    <span
                                      *ngIf="TDReqNameRef.invalid"
                                      class="invalid-form"
                                    >
                                      <small
                                        class="form-text text-danger font-small-1"
                                        *ngIf="
                                          TDReqNameRef.errors.minlength ||
                                          TDReqNameRef.errors.maxlength
                                        "
                                      >
                                        {{ "UI.k_reporter_validation" | translate }}</small
                                      >
                                      <small
                                        class="form-text text-danger font-small-1"
                                        *ngIf="TDReqNameRef.errors.pattern"
                                        >{{
                                          "UI.k_leading_trailing_space_validation" | translate
                                        }}</small
                                      >
                                    </span>
                                  </div>    
                                </div>  
                              </div>  
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_email" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <span class="sm-font title-color">
                                    {{
                                      process_data_copy?.reporter_info?.email
                                        ? process_data_copy?.reporter_info?.email
                                        : "-"
                                    }}
                                  </span>
                                </div> 
                                <div class="edit-value">
                                  <div class="form-group">                                  
                                    <input
                                      type="text"
                                      [(ngModel)]="process_data.reporter_info.email"
                                      #TDReqMailRef="ngModel"
                                      name="txtInciRequesterEmail"
                                      id="txtInciRequesterEmail"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_email' | translate }}"
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
                                      placeholder="john.doe@email.com"
                                      aria-label="john.doe"
                                      [disabled]="disable_config"
                                    />
                                    <span
                                      *ngIf="TDReqMailRef.invalid"
                                      class="invalid-form"
                                    >
                                      <small
                                        class="form-text text-danger font-small-1"
                                        *ngIf="TDReqMailRef.errors.pattern"
                                        >{{ "UI.k_alert_email_invalid" | translate }}</small
                                      >
                                    </span>                              
                                  </div>
                                </div>
                              </div>                              
                                                                                                                                                                                                                                                                                        
                            </div>
                          </div>
                        </ng-scrollbar>
                      </div> -->
											<div
												*ngIf="selectedItemName === 'incident_details'"
												class="flex-auto-height"
											>
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="p-1 basic-details d-flex flex-column gap-50">
														<div class="reg-font fw-500">
															{{ "UI.k_basic_details" | translate }}
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font"
																	>{{ "UI.k_status" | translate }}
																</span>
															</div>
															<div class="details-value">
																<app-status-picker
																	#sliderStatusPickerComponent
																	[inputConfig]="{
																		isTextMode: true,
																		hideLabel: true,
																		stateOptionsMap: dropdown?.state_id_map,
																		isEditDisabled: disable_status_config || is_ssp_login
																	}"
																	[hideArrow]="true"
																	[options]="dropdown.transition_status"
																	[value]="process_data_copy?.basic_info?.status?.id"
																	(setStatus)="onStatusChange($event)"
																	[showLabel]="false"
																></app-status-picker>
															</div>
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font"
																	>{{ "UI.k_priority" | translate }}
																</span>
															</div>
															<div class="details-value">
																<app-select-badge
																	[inputConfig]="{
																		hideLabel: true,
																		inputOptions: dropdown?.priority,
																		id: 'priority',
																		model: this.process_data_copy?.basic_info
																			?.priority.id,
																		isEditDisabled: disable_config || is_ssp_login
																	}"
																	[hideArrow]="true"
																	[showLabel]="false"
																	(onValueChange)="statusBadgeChange($event)"
																></app-select-badge>
															</div>
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_urgency" | translate
																}}</span>
															</div>
															<div class="details-value">
																<app-select-badge
																	[inputConfig]="{
																		hideLabel: true,
																		inputOptions: dropdown?.urgency,
																		id: 'urgency',
																		model: this.process_data?.basic_info?.urgency
																			.id,
																		isEditDisabled: disable_config || is_ssp_login
																	}"
																	(onValueChange)="statusBadgeChange($event)"
																></app-select-badge>
															</div>
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_impact" | translate
																}}</span>
															</div>
															<div class="details-value">
																<ng-select
																	[items]="dropdown.impact"
																	[hideSelected]="true"
																	bindLabel="name"
																	bindValue="id"
																	[(ngModel)]="process_data.basic_info.impact.id"
																	name="txtIncidentImpact"
																	id="txtIncidentImpact"
																	placeholder="{{ 'UI.k_select' | translate }} {{
																		'UI.k_impact' | translate
																	}}"
																	(change)="onImpactChange($event)"
																	[disabled]="disable_config || is_ssp_login"
																>
																</ng-select>
															</div>
														</div>

														<div
															*appDnsLicenseHandler="{
																module_key: 'SERVICEDESK',
																keys: ['SERVICE_CATALOGUE']
															}"
															class="basic-details-row align-items-center"
														>
															<div class="details-label">
																<ng-container *ngIf="moduleName !== 'Request'">
																	<span class="sm-font">{{
																		"UI.k_impact_service" | translate
																	}}</span>
																</ng-container>
																<ng-container *ngIf="moduleName === 'Request'">
																	<span class="sm-font">{{
																		"UI.k_request_for" | translate
																	}}</span>
																</ng-container>
															</div>
															<div class="details-value">
																<div class="form-group input-tree-dropdown">
																	<ng-container *ngIf="service_config?.is_editable && !is_ssp_login">
																		<app-single-tree-dropdown
																			class=""
																			[items]="this.dropdown?.formatted_services"
																			[value]="
																				this.process_data?.basic_info
																					?.impact_service
																			"
																			[id]="processID"
																			(itemChange)="
																				this.serviceValueChange($event)
																			"
																		>
																		</app-single-tree-dropdown>
																	</ng-container>
																	<div
																		class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		*ngIf="!service_config?.is_editable || is_ssp_login"
																		(click)="
																			onImpactServiceClick(
																				$event,
																				'impact_service'
																			)
																		"
																	>
																		<div class="ng-select-container">
																			<div class="ng-value-container">
																				<ng-container
																					*ngIf="
																						this.process_data?.basic_info
																							?.impact_service_tree_path
																					"
																				>
																					<div class="">
																						{{
																							this.process_data
																								?.basic_info
																								?.impact_service_tree_path
																						}}
																					</div>
																				</ng-container>
																				<ng-container
																					*ngIf="
																						!this.process_data?.basic_info
																							?.impact_service_tree_path
																					"
																				>
																					<div class="placeholder-txt">
																						{{
																							"UI.k_select_option"
																								| translate
																						}}
																					</div>
																				</ng-container>

																				<div role="combobox" class="ng-input">
																					<input type="text" />
																				</div>
																			</div>
																			<span class="ng-arrow-wrapper"
																				><span class="ng-arrow"></span
																			></span>
																			<span
																				*ngIf="service_config?.is_loading"
																				class="fa fa-spinner fa-spin fa-2x"
																			>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															*appDnsLicenseHandler="{
																module_key: 'SERVICEDESK',
																keys: ['SERVICE_CATALOGUE']
															}"
															class="basic-details-row align-items-center"
														>
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_service_classification" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="form-group input-tree-dropdown">
																	<ng-container
																		*ngIf="classification_config?.is_editable && !is_ssp_login"
																	>
																		<app-single-tree-dropdown
																			class=""
																			[items]="
																				dropdown?.formatted_classification_data
																			"
																			[value]="
																				process_data.basic_info
																					.service_classification
																			"
																			[id]="'edit-service' + processID"
																			[treeInputConfig]="{
																				isParentAllowed: true
																			}"
																			(itemChange)="
																				this.classificationValueChange($event)
																			"
																		>
																		</app-single-tree-dropdown>
																	</ng-container>
																	<div
																		class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		*ngIf="!classification_config?.is_editable || is_ssp_login"
																		(click)="
																			onImpactServiceClick(
																				$event,
																				'service_classification'
																			)
																		"
																	>
																		<div class="ng-select-container">
																			<div class="ng-value-container">
																				<ng-container
																					*ngIf="
																						this.process_data?.basic_info
																							?.service_classification_path
																					"
																				>
																					<div class="">
																						{{
																							this.process_data
																								?.basic_info
																								?.service_classification_path
																						}}
																					</div>
																				</ng-container>
																				<ng-container
																					*ngIf="
																						!this.process_data?.basic_info
																							?.service_classification_path
																					"
																				>
																					<div class="placeholder-txt">
																						{{
																							"UI.k_select_option"
																								| translate
																						}}
																					</div>
																				</ng-container>

																				<div role="combobox" class="ng-input">
																					<input type="text" />
																				</div>
																			</div>
																			<span class="ng-arrow-wrapper"
																				><span class="ng-arrow"></span
																			></span>
																			<span
																				*ngIf="
																					classification_config?.is_loading
																				"
																				class="fa fa-spinner fa-spin fa-2x"
																			>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<ng-container
															*appDnsLicenseHandler="{
																module_key: 'MONITORING',
																keys: ['EVENT_MANAGEMENT']
															}"
														>
															<div
																*ngIf="process_data?.basic_info?.event"
																class="basic-details-row align-items-center"
															>
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_event_status" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<ng-select
																		[items]="dropdown.event_status"
																		[hideSelected]="true"
																		bindLabel="name"
																		bindValue="id"
																		[(ngModel)]="
																			process_data_copy.basic_info.event_status
																		"
																		name="txtProbEventType"
																		id="txtProbEventType"
																		placeholder="{{
																			'UI.k_select_event_status' | translate
																		}}"
																		[disabled]="disable_config || is_ssp_login"
																	>
																	</ng-select>
																</div>
															</div>
														</ng-container>
														<!-- <div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_callback_options" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="sm-font">
																	<i
																		class="fa-light fa-{{
																			callbackIconMap[
																				process_data_copy?.basic_info?.callback
																			]
																		}} text-primary mr-25"
																	></i>
																	<span class="sm-font fw-500 title-color">
																		{{
																			this.process_data_copy?.basic_info
																				?.callback
																				? callbackNameMap[
																						process_data_copy?.basic_info
																							?.callback
																				  ]
																				: "-"
																		}}
																	</span>
																</div>
															</div>
														</div> -->
														<ng-container *ngIf="moduleName === 'Problem'">
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_problem_source" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<div class="problem-source">
																		<ng-select
																		[items]="dropdown.problem_source"
																		[hideSelected]="true"
																		[clearable]="false"
																		bindLabel="name"
																		placeholder="{{ 'UI.k_select_windows' | translate }}"
																		[(ngModel)]="process_data_copy.basic_info.problem_source"
																		name="txtProblemSource"
																		[disabled]="disable_config || is_ssp_login"
																		id="txtProblemSource"
																		(change)="onProblemSourceChange($event)"
																	>
																	</ng-select>
																	</div>
																</div>
															</div>
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_known_error" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<div class="known-error common-sidebar-custom-switch px-75">
																		<div class="summary_pos_toggle custom-control custom-switch custom-switch-sm text-primary cursor-pointer ">
																			<input
																				type="checkbox"
																				[(ngModel)]="process_data_copy.is_known_error_enable"
																				[checked]="process_data_copy.is_known_error_enable"
																				class="custom-control-input"
																				id="customSwitchProblemKnownError"
																				name="customSwitchProblemKnownError"
																				(change)="knownErrorChange($event)"
																				[disabled] = "disable_config || is_ssp_login"
																			/>
																			<label class="custom-control-label" for="customSwitchProblemKnownError"> </label>
																		</div>
																	</div>
																</div>
															</div>
															<!-- <div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_symtom_of_fault" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<div class="symptoms-fault">
																		<div class="form-group p-0 card border quill-card">
																			<quill-editor
																				[(ngModel)]="process_data_copy.basic_info.fault_symptoms"
																				(onContentChanged)="getFaultSymptomsTextData($event)"
																				id="articleNote"
																				name="articleNote"
																				[minLength]="3"
																				[required]="true"
																				[trimOnValidation]="true"
																				[appQuillValidator]="qlSympFault"
																				#qlSympFault
																				[class.error]="
																					qlSympFault?.errors
																				"
																				placeholder="{{ 'UI.k_insert_text_here' | translate }}..."
																			>
																				<div quill-editor-toolbar class="border-0 border-bottom px-0 py-50">
																					<div class="border-bottom p-0">
																						<span class="ql-formats pb-50">
																							<button
																								id="qlBoldNoteEmail-Inc"
																								name="qlBold"
																								class="ql-bold"
																								title="{{ 'UI.k_bold' | translate }}"
																							></button>
																							<button
																								id="qlItalicNoteEmail-Inc"
																								name="qlItalic"
																								class="ql-italic"
																								title="{{ 'UI.k_italic' | translate }}"
																							></button>
																							<button
																								id="qlUnderlineNoteEmail-Inc"
																								name="qlUnderline"
																								class="ql-underline"
																								title="{{ 'UI.k_underline' | translate }}"
																							></button>
																						</span>
																						<span class="ql-formats">
																							<select
																								id="qlAlignNote"
																								class="ql-align"
																								title="{{ 'UI.k_alignment' | translate }}"
																							>
																								<option selected></option>
																								<option value="center"></option>
																								<option value="right"></option>
																								<option value="justify"></option>
																							</select>
																						</span>
																						<span class="ql-formats">
																							<button
																								id="btnqlListNote"
																								name="qlList"
																								class="ql-list"
																								value="ordered"
																								type="button"
																							></button>
																						</span>
																						<span class="ql-formats">
																							<button
																								id="btnqlLinkNoteEmail-Inc"
																								class="ql-link"
																								value="ordered"
																								type="button"
																							></button>
																							<button
																								id="btnqlImageNoteEmail-Inc"
																								class="ql-image"
																								value="ordered"
																								type="button"
																							></button>
																							<button
																								id="btnqlVideoNoteEmail-Inc"
																								class="ql-video"
																								value="ordered"
																								type="button"
																							></button>
																						</span>
																						<span class="ql-formats">
																							<button
																								id="btn-ql-formula-incid-Descr"
																								class="ql-formula"
																								value="ordered"
																								type="button"
																							></button>
																							<button
																								id="btn-ql-code-block-serviceDescr"
																								class="ql-code-block"
																								value="ordered"
																								type="button"
																							></button>
																						</span>
																						<span class="ql-formats">
																							<button
																								id="btnqlCleanNoteEmail-Inc"
																								class="ql-clean"
																								value="ordered"
																								type="button"
																							></button>
																						</span>
																					</div>
																				</div>
																			</quill-editor>
																			<div
																				class="error"
																				*ngIf="qlSympFault?.errors"
																			>
																				<small
																					*ngIf="qlSympFault?.errors?.requiredError?.empty"
																				>
																					{{ "UI.k_field_required" | translate }}
																				</small>
																				<small *ngIf="qlSympFault?.errors?.minLengthError">
																					{{ "Error.err_min_3_character" | translate }}
																				</small>
																			</div>
																		</div>
																	</div>
																</div>
															</div> -->
														</ng-container>
														<ng-container *ngIf="moduleName === 'Change'">
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_change_type" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<div class="problem-source">
																		<ng-select
																		[items]="dropdown.change_type"
																		[hideSelected]="true"
																		[clearable]="false"
																		bindLabel="name"
																		[(ngModel)]="process_data.basic_info.change_type"
																		name="SelChangeType"
																		[disabled]="true"
																		id="SelChangeType"
																	>
																	</ng-select>
																	</div>
																</div>
															</div>
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_due_date" | translate
																	}}</span>
																</div>
																<form #dueDateForm="ngForm">
																	<div class="value-block input-tree-dropdown">
																		<ng-container *ngIf="disable_config || is_ssp_login">
																			<span class="title-color sm-font fw-500">{{process_data.due_date}}</span>
																		</ng-container>
																		<ng-container *ngIf="!disable_config && !is_ssp_login">
																			<ng2-flatpickr
																			#changeDuedate
																			[config]="dueDateTimeOptions"
																			name="changeDueDate_{{processID}}"
																			[setDate]="process_data.due_date"
																			id="changeDueDate_{{processID}}"
																			[disabled]="disable_config || is_ssp_login"
																			[(ngModel)]="process_data.due_date"
																			class="process-date-picker"
																			placeholder="{{ 'UI.k_due_date' | translate }}"
																		></ng2-flatpickr>
																		<span *ngIf="startEndDateInvalid" class="col-12 invalid-form">
																			<small class="form-text text-danger"
																				[innerHtml]="startEndDateToaster">
																			</small>
																		</span>
																		</ng-container>
																	</div>
																</form>
															</div>
														</ng-container>
														<ng-container *ngIf="moduleName === 'Release'">
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_risk" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<div class="problem-source">
																		<app-select-badge [inputConfig]="{
																			hideLabel: true,
																			inputOptions: dropdown.risk,
																			id: 'risk',
																			model: process_data.basic_info.risk.id,
																			isEditDisabled: disable_config || is_ssp_login
																		}" (onValueChange)="statusBadgeChange($event)"></app-select-badge>
																	</div>
																</div>
															</div>
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_due_date" | translate
																	}}</span>
																</div>
																<form #dueDateForm="ngForm">
																	<div class="value-block input-tree-dropdown">
																		<ng-container *ngIf="disable_config || is_ssp_login">
																			<span class="title-color sm-font fw-500">{{process_data.due_date}}</span>
																		</ng-container>
																		<ng-container *ngIf="!disable_config && !is_ssp_login">
																			<ng2-flatpickr
																			#relDuedate
																			[config]="dueDateTimeOptions"
																			name="relDueDate_{{processID}}"
																			[setDate]="process_data.due_date"
																			id="relDueDate_{{processID}}"
																			[disabled]="disable_config || is_ssp_login"
																			[(ngModel)]="process_data.due_date"
																			class="process-date-picker"
																			placeholder="{{ 'UI.k_due_date' | translate }}"
																		></ng2-flatpickr>
																		<span *ngIf="startEndDateInvalid" class="col-12 invalid-form">
																			<small class="form-text text-danger"
																				[innerHtml]="startEndDateToaster">
																			</small>
																		</span>
																		</ng-container>
																	</div>
																</form>
															</div>
														</ng-container>
														<ng-container *ngIf="moduleName === 'Release'">
															<div class="basic-details-row align-items-start">
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_version" | translate
																	}}</span>
																</div>
																<div class="details-value input-tree-dropdown">
																	<ng-container *ngIf="disable_config || is_ssp_login">
																		<span class="sm-font px-1 title-color">{{process_data.basic_info.version}}</span>
																	</ng-container>
																	<ng-container *ngIf="!disable_config && !is_ssp_login">
																		<input type="text" class="form-control" id="txtRelVersion"
																		name="txtRelVersion" placeholder="{{ 'UI.k_version' | translate}}"
																		[(ngModel)]="process_data.basic_info.version" #TDVersion="ngModel"
																		maxlength="40"
																		#releaseVersion
																		(keyup)="updateVersion($event)"
																		[appWhiteSpaceCheck]="process_data.basic_info.version"
																		[class.error]="
																			!TDVersion.valid
																		" [class.error]="!TDVersion.valid" />
																	<div *ngIf="TDVersion.invalid"
																		class="invalid-form">
																		<small class="form-text text-danger xs-font"
																			*ngIf="TDVersion.errors.maxlength">{{
																			"UI.k_title_name_validation" | translate }}
																		</small>
																	</div>
																	</ng-container>
																</div>
															</div>
														</ng-container>
														<div class="basic-details-row align-items-start">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_attachment" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="upload-file-list">
																	<ng-container
																		*ngFor="
																			let item of filteredAttachmentByCategory
																				| keyvalue
																		"
																	>
																		<div
																			*ngFor="
																				let file of item.value;
																				let i = index
																			"
																			class="single-file"
																		>
																			<div
																				class="border rounded bg-body px-75 py-25 cursor-pointer"
																				ngbTooltip="{{ file.file_name }}"
																			>
																				<div
																					class="d-flex gap-50 align-items-center justify-content-between"
																				>
																					<div class="d-flex gap-50">
																						<i
																							class="fa-light sm-font"
																							[ngClass]="
																								file.file_type === '.txt'
																									? 'text-primary fa-file-lines'
																									: file.file_type ===
																									  '.pdf'
																									? 'text-danger fa-file-pdf'
																									: file.file_type ===
																											'.xls' ||
																									  file.file_type ===
																											'.xlsx'
																									? 'text-success fa-file-excel'
																									: file.file_type ===
																											'.doc' ||
																									  file.file_type ===
																											'.docx'
																									? 'text-info fa-file-word'
																									: 'text-primary fa-file'
																							"
																						></i>
																						<div
																							class="sm-font d-flex gap-50 title-color"
																						>
																							<span class="updaload-file-name">{{
																								file.file_name
																							}}</span>
																							<span class=""
																								>{{
																									file.file_size_with_unit
																								}}
																								MB</span
																							>
																						</div>
																					</div>
																					<div class="sm-font cursor-pointer" (click)="downloadAttachment(file.file_path,file.zip_file_name)">
																						<i
																							class="fa-light fa-download"
																						></i>
																					</div>
																				</div>
																			</div>
																		</div>
																	</ng-container>
																</div>
															</div>
														</div>
														<!-- <div class="reg-font fw-500 mb-1 mt-2">{{"UI.k_custom_fileds" | translate}}</div>                                
                            <div class="row align-items-start mb-1">
                              <div class="col-lg-2 col-md-3 mt-50">
                                <span class="sm-font">Field name</span>
                              </div>
                              <div class="col-lg-6 col-md-9">                              
                                <div class="sm-font">                                  
                                  <div class="form-group">                                  
                                    <input
                                      type="text"
                                      [(ngModel)]="process_data_copy.reporter_info.name"                                   
                                      id="txtReporterName"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_name' | translate }}"
                                      name="txtReporterName"                                                                                                           
                                    />                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row align-items-start mb-1">
                              <div class="col-lg-2 col-md-3 mt-50">
                                <span class="sm-font">Field name</span>
                              </div>
                              <div class="col-lg-6 col-md-9">                              
                                <div class="sm-font">                                  
                                  <div class="form-group">                                  
                                    <input
                                      type="text"
                                      [(ngModel)]="process_data_copy.reporter_info.email"                                   
                                      id="txtReporterEmail"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_email' | translate }}"
                                      name="txtReporterEmail"                                                                                                           
                                    />                                  
                                  </div>
                                </div>
                              </div>
                            </div> -->
														<!-- <div class="reg-font fw-500 mb-1 mt-2">{{"UI.k_resolution" | translate}} {{"UI.k_details" | translate}}</div>
                            <div class="row">
                              <div class="col-lg-12 col-md-12">
                                <div class="mb-50">
                                  <span class="sm-font">Description</span>
                                </div>
                                <div class="sm-font mb-50">
                                  <textarea
                                    type="text"
                                    rows="3"
                                    [(ngModel)]="resolutionDetails"                                   
                                    id="txtResolutionDetails"
                                    class="form-control"
                                    placeholder="{{'UI.k_resolution' | translate}} {{'UI.k_details' | translate}}"
                                    name="txtResolutionDetails"                                                                                                           
                                  ></textarea>                                         
                                </div>
                              </div>
                            </div>
                            <div class="row align-items-start mb-1">
                              <div class="col-lg-2 col-md-3">
                                <span class="sm-font">{{ "UI.k_resolution_date" | translate }}</span>
                              </div>
                              <div class="sm-font">
                                {{ process_data?.inci_resolution_time | date }}
                              </div>
                            </div> -->
													</div>
												</ng-scrollbar>
											</div>
											<div
												*ngIf="selectedItemName === 'communication_tab'"
												class="flex-auto-height"
											>
												<app-dns-communication
													class="dns-communication-container"
													[ccAddress]="dropdown.cc_address"
													[toAddress]="dropdown.to_address"
													[users]="dropdown.users"
													[close_type]="dropdown.close_type"
													[requesters]="dropdown.requesters"
													[basicProfile]="process_data?.basic_info"
													[displayId]="process_data?.display_id"
													[refId]="processID"
													[isRefDataLoad]="process_data ? true : false"
													[permissions]="permissions"
													[internelNotes]="internelNotes"
													[lav_module]="lav_module"
													[moduleType]="communicationModuleType"
													[submitActions]="submitActions"
													[refData]="process_data"
													[dateTimeOptions]="dateTimeOptions"
													[agreedDateTimeOptions]="agreedDateTimeOptions"
													[filtered_status]="dropdown.filtered_status"
													[ref_data_copy]="process_data_copy"
													[custom_field_config]="custom_field_config"
													[custom_field_data]="custom_field_data"
													[disable_config]="disable_config"
													[disableCommentSubmit]="disableCommentSubmit"
													[currentUser]="currentUser"
													[isReadUnread]="true"
													[isSeenBy]="true"
													[imapInputData]="imapInputData"
													[signatureData]="signatureInputData"
													[is_from_disabled]="is_from_disabled"
													[imap_list]="imap_list"
													(addRichTextData)="setRichTextData($event)"
													(editRefData)="editProcessData($event)"
													(setClosureNoteData)="setClosureNoteData($event)"
													(setApprovalData)="setApprovalData($event)"
													(setReviewData) = "setReviewData($event)"
													(handleEditorData)="handleEditorData($event)"
													(onClosureStatusChange)="onStatusChange($event)"
													(getRefMailConversations)="getRefMailConversations($event)"
													(messageObjectData)="markAsReadUnread($event)"
													(chooseExistingTicket)="openChooseExisting($event)"
													[showMoreActionBtn]="true"
													[stateKeyIdMap] = "stateKeyIdMap"
													[transition_status] = "dropdown.transition_status"
													class="full-height set-footer"
												></app-dns-communication>
											</div>
											<div *ngIf="selectedItemName === 'task' && moduleName === 'Change'" class="flex-auto-height">
												<div class="main-sec flex-auto-height">
													<div class="action-bar flex-initial">
														<div class="inner-section d-flex align-items-center justify-content-between px-1 py-50 border-bottom">
															<div class="left-section">
																<span
																	class="mb-0 fw-600 title-color text-break page-title"
																>
																	{{ "UI.k_task_involved" | translate }}
															</span>
																<!-- <app-search></app-search> -->
															</div>
															<div class="right-section">
																<button
																	*ngIf="
																		permissions?.edit &&
																		!(
																			process_data?.is_exit &&
																			process_data?.basic_info?.status
																				?.state_id == 5
																		) &&
																		!disable_config &&
																		!is_ssp_login
																	"
																	class="btn btn-primary pull-right ml-25"
																	rippleEffect
																	id="btnAddTask"
																	name="btnAddTask"
																	tooltipClass="fadeInAnimation"
																	placement="left"
																	(click)="
																		toggleTaskSideBar('create-task-sidebar')
																	"
																	[attr.data-testid]="'btnAddTask'"
																>
																	<i class="fa-light fa-plus"></i>
																	<span class="d-none d-sm-inline-block">
																		{{ "UI.k_new" | translate }}
																		{{ "UI.k_task" | translate }}
																	</span>
																</button>
															</div>
														</div>
													</div>
													<div class="action-section full-height flex-1">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div
																*ngIf="taskData?.length > 0"
																class="full-height"
															>
																<table class="w-100">
																	<tr
																		*ngFor="let item of taskData; let i = index"
																		class="border-bottom"
																	>
																		<td class="px-1 py-2">
																			<div
																				*ngIf="permissions?.edit"
																				class="custom-control custom-checkbox"
																			>
																				<input
																					type="checkbox"
																					class="custom-control-input"
																					id="cb_{{ item.id }}"
																					[(ngModel)]="item.isChecked"
																					(change)="toggleEditTask(item)"
																				/>
																				<label
																					class="custom-control-label"
																					for="cb_{{ item.id }}"
																				></label>
																			</div>
																		</td>
																		<td class="px-1 py-2">
																			<p class="text-color mb-0 fw-300 reg-font">
																				{{ item?.name }}
																			</p>
																		</td>
																		<td class="px-1 py-2">
																			<div
																				class="badge badge-light-{{
																					item?.status?.state_color
																				}}"
																			>
																				<span class="reg-font">
																					{{
																						item?.status?.state | translate
																					}}
																				</span>
																			</div>
																		</td>
																		<td class="px-1 py-2">
																			<div
																				class="badge badge-light-{{
																					item?.priority?.color
																				}}"
																			>
																				<i
																					class="fa-light {{
																						item?.priority?.icon
																					}} mr-25"
																					style="
																						vertical-align: middle !important;
																						line-height: 0 !important;
																						height: auto !important;
																					"
																					*ngIf="item?.priority?.icon"
																				></i>
																				<span class="reg-font">
																					{{ item?.priority?.name }}
																				</span>
																			</div>
																		</td>
																		<td class="px-1 py-2">
																			<p class="text-muted-color mb-0 reg-font">
																				{{ item?.due_date }}
																			</p>
																		</td>
																		<td class="px-1 py-2">
																			<div
																				*ngIf="item?.assignee"
																				class="media d-flex justify-content-center"
																			>
																				<a
																					href="javascript:void(0);"
																					class="d-flex justify-content-center"
																				>
																					<div
																						class="avatar avatar-76-80 rounded-circle"
																						id="divAssigneeTask{{ i }}"
																						*ngIf="
																							dropdown?.user_profile_map[
																								item?.assignee
																							].profile_image
																						"
																					>
																						<span class="avatar-content">
																							<img
																								[src]="
																									dropdown
																										?.user_profile_map[
																										item?.assignee
																									].profile_image
																								"
																								class="rounded-circle"
																								alt="profile image"
																								width="100%"
																							/>
																						</span>
																					</div>
																					<div
																						*ngIf="
																							!dropdown?.user_profile_map[
																								item?.assignee
																							].profile_image &&
																							item?.assignee_profile
																								?.full_name
																						"
																						class="avatar avatar-76-80 rounded-circle"
																						[ngStyle]="{
																							'background-color':
																								dropdown
																									?.user_profile_map[
																									item?.assignee
																								].avatar_color
																						}"
																					>
																						<span
																							class="avatar-content reg-font"
																							[ngStyle]="{
																								'background-color':
																									dropdown
																										?.user_profile_map[
																										item?.assignee
																									].avatar_color
																							}"
																						>
																							{{
																								item?.assignee_profile
																									?.full_name
																									| initials
																									| slice: 0:2
																							}}</span
																						>
																					</div>
																					<div
																						*ngIf="
																							!dropdown?.user_profile_map[
																								item?.assignee
																							].profile_image &&
																							!item?.assignee_profile
																								?.full_name
																						"
																						[ngStyle]="{
																							'background-color': ''
																						}"
																						class="avatar avatar-76-80 rounded-circle mr-50"
																					>
																						<span class="avatar-content">
																							<i
																								class="fa-light fa-user fa-1x user-icon-clr"
																							></i>
																						</span>
																					</div>
																				</a>
																			</div>
																		</td>
																		<td class="px-1 py-2 task-action d-flex" *ngIf="permissions?.edit &&
																			!(
																				process_data?.is_exit &&
																				process_data?.basic_info?.status
																					?.state_id == 5
																			) &&
																			!disable_config && !is_ssp_login">
																			<div (click)="toggleEditTask(item)">
																				<a class="mr-10">
																					<span tooltipclass="fadeInAnimation" 
																					placement="left" 
																					ngbTooltip="{{ 'UI.k_edit' | translate }}"
																					container="body"
																					class="mr-half" id="btnTaskEdit">
																						<i class="fa-lg fa-light fa-pen-to-square text-primary">

																						</i>
																					</span>
																				</a>
																			</div>
																			<div class="task-action" *ngIf="isChangeManagerLogin()" (click)="deleteTask(item)">
																				<a class="mr-10">
																					<span tooltipclass="fadeInAnimation" ngbTooltip="{{ 'UI.k_delete' | translate }}"
																					container="body" placement="left" class="mr-half" id="btnTaskDelete">
																						<i class="fa-lg fa-light fa-trash-can mr-50 text-danger">
																						</i>
																					</span>
																				</a>
																			</div>
																		</td>
																	</tr>
																</table>
															</div>
															<ng-container
																*ngIf="
																	(taskData?.length == 0 || taskData == undefined) &&
																	!is_task_loading
																"
															>
																<div
																	class="d-flex flex-column align-items-center"
																	style="padding: 10rem"
																>
																	<div
																		class="avatar bg-secondary rounded-lg mb-25"
																		id="BtnincIcons"
																	>
																		<div class="avatar-content">
																			<span
																				><i
																					class="fa-light fa-book-open fa-lg"
																				></i
																			></span>
																		</div>
																	</div>
																	<span class="sm-font text-muted">{{
																		"UI.k_no_records_yet" | translate
																	}}</span>
																</div>
															</ng-container>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'task' && moduleName === 'Release'" class="flex-auto-height">
												<div class="task-main-block">
													<div class="main-sec">
														<div class="action-bar border-bottom  position-relative">
															<div class="inner-section d-flex gap-1 align-items-center justify-content-between p-50">
																<div class="left-section">
																	<span
																		class="mb-0 fw-600 title-color text-break page-title"
																	>
																		{{ "UI.k_task_involved_release" | translate }}
																</span>
																</div>
																<div class="right-section d-flex align-items-center">
																	<div class="action-search"  [ngClass]="isExpandSearchWidth ? 'expandedSearch position-absolute' : ''">
																		<ng-container
																		*ngIf="
																		taskData?.length > 0 || taskFilterData
																		"
																	>
																		<app-search
																			[showDataTable]="showDataTable"
																			[columnOptions]="taskColumnOptions"
																			[conditionOptions]="taskConditionOptions"
																			[showRightsection]="showRightsection"
																			(getfilterData)="
																			getTaskFilterSearch($event)
																			"
																			[savedData]="savedTaskData"
																			[Options]="dropdown"
																			[module]="'task'"
																			(isExpandSearchWidth)="getSearchWidth($event)"
																			(genericFilter)="
																			genericTaskSearchFilter($event)
																			"
																		></app-search>
																		</ng-container>
																	</div>
																	<div ngbDropdown class="d-inline-block ml-1" *ngIf="
																			permissions?.edit &&
																			!(
																				process_data?.is_exit &&
																				process_data?.basic_info?.status
																					?.state_id == 5
																			) &&
																			!disable_config
																			&& !is_ssp_login
																		">
																		<button
																			type="button"
																			class="btn btn-primary"
																			id="btnTaskDropdown"
																			name="btnTaskDropdown"
																			ngbDropdownToggle
																			[attr.data-testid]="'btnTaskDropdown'"
																		>
																			<i class="fa fa-plus"></i>
																			{{ "UI.k_create_new" | translate }}
																		</button>
																		<div
																			ngbDropdownMenu
																			aria-labelledby="taskdropdown"
																			style="width: 100%"
																		>
																			<ng-container
																				*ngFor="let taskName of taskDropDown"
																			>
																				<button
																					ngbDropdownItem
																					style="width: 100%"
																					(click)="taskHandler(taskName)"
																				>
																					{{ taskName.name }}
																				</button>
																			</ng-container>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="action-section">
															<ng-container *ngIf="!is_task_loading && taskData?.length > 0">
																<ng-container
																	*ngFor="let taskItem of taskData; let i = index"
																>
																	<div class="task-list">
																		<div class="task-left-side d-flex gap-50">
																			<div
																				class="f-color sm-font m-0"
																				(click)="taskEditHandler(taskItem)"
																			>
																				{{ taskItem?.display_id }}
																			</div>
																			<div class="sm-font"
																				>
																					{{
																						taskItem?.name?.length > 100
																							? (taskItem?.name | slice: 0:100) +
																							"..."
																							: taskItem?.name
																					}}
																			</div>
																		</div>
																		<div class="task-right-side d-flex gap-50">
																			<div
																				class="pbr m-0 d-flex align-items-center gap-25"
																				style="background-color: {{
																					taskItem?.type?.color
																				}}"
																			>
																				<i
																					class="fa-light"
																					class="{{ taskItem?.type?.class }}"
																				></i>
																				{{ taskItem?.type?.name }}
																			</div>
																			<div
																				class="pbr  m-0 d-flex align-items-center gap-25"
																				[ngStyle]="{
																					'background-color': taskItem?.status?.background_color,
																					color: taskItem?.status?.color
																				}"
																			>
																				{{ taskItem?.status?.state | translate }}
																			</div>
																			<div
																				class="pbr m-0 d-flex align-items-center gap-25 p-all-edit badge badge-light-{{
																					taskItem?.priority?.color
																				}}"
																				style="background-color: {{
																					taskItem?.priority?.background_color
																				}}"
																			>
																				<i
																					class="fa-light"
																					class="{{ taskItem?.priority?.icon }}"
																				></i>
																				{{ taskItem?.priority?.name }}
																			</div>
																			<div class="pbr m-0 text-nowrap">
																				{{taskItem?.due_date}}
																			</div>
																			<div
																				class="avatar m-0 avatar-sm rounded-circle"
																				id="divAssigneeTask{{ i }}"
																				*ngIf="dropdown?.user_profile_map?.[taskItem?.assignee]?.profile_image"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{ taskItem?.assignee_profile?.full_name }}"
																				placement="left"
																				>
																				<span class="avatar-content">
																					<img
																						[src]="dropdown?.user_profile_map?.[taskItem?.assignee]?.profile_image"
																						class="rounded-circle"
																						alt="profile image"
																						width="100%"
																					/>
																				</span>
																			</div>
																			<div
																				*ngIf="!dropdown?.user_profile_map?.[taskItem?.assignee]?.profile_image && taskItem?.assignee_profile?.full_name"
																				class="avatar m-0 avatar-sm rounded-circle"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{ taskItem?.assignee_profile?.full_name }}"
																				placement="left"
																				[ngStyle]="{ 'background-color': dropdown?.user_profile_map[taskItem?.assignee].avatar_color }">
																				<span
																					class="avatar-content reg-font"
																					[ngStyle]="{
																						'background-color':
																							dropdown?.user_profile_map[
																								taskItem?.assignee
																							].avatar_color
																					}"
																				>
																					{{
																						taskItem?.assignee_profile
																							?.full_name
																							| initials
																							| slice: 0:2
																					}}</span
																				>
																			</div>
																			<div
																				*ngIf="!dropdown?.user_profile_map?.[taskItem?.assignee]?.profile_image && !taskItem?.assignee_profile?.full_name"
																				[ngStyle]="{ 'background-color': '' }"
																				class="avatar avatar-sm avatar-76-80 rounded-circle mr-50"
																			>
																				<span class="avatar-content">
																					<i
																						class="fa-light fa-user fa-1x user-icon-clr"
																					></i>
																				</span>
																			</div>
																			<div class="task-action d-flex m-0 gap-25" *ngIf="permissions?.edit &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id == 5
																				) &&
																				!disable_config
																				&& !is_ssp_login">
																				<div class="m-0" (click)="taskEditHandler(taskItem)">
																					<a class="mr-0">
																						<span tooltipclass="fadeInAnimation" 
																						placement="left" 
																						ngbTooltip="{{ 'UI.k_edit' | translate }}"
																						container="body"
																						class="mr-0 sm-font" id="btnTaskEdit">
																							<i class="fa-lg fa-light fa-pen-to-square text-primary">

																							</i>
																						</span>
																					</a>
																				</div>
																				<div class="task-action m-0" *ngIf="isReleaseManagerLogin()" (click)="deleteTask(taskItem)">
																					<a class="mr-0">
																						<span tooltipclass="fadeInAnimation" ngbTooltip="{{ 'UI.k_delete' | translate }}"
																						container="body" placement="left" class="mr-0 sm-font" id="btnTaskDelete">
																							<i class="fa-lg fa-light fa-trash-can mr-0 text-danger">
																							</i>
																						</span>
																					</a>
																				</div>
																			</div>
																			
																		</div>
																	</div>
																</ng-container>
															</ng-container>
															
															<ng-container *ngIf="is_task_loading">
																<div
																	class="d-flex flex-column align-items-center"
																	style="padding: 10rem"
																>
																	<div
																		class="spinner-border text-primary"
																		aria-hidden="true"
																	></div>
																</div>
															</ng-container>
															<ng-container
																*ngIf="
																	(taskData?.length == 0 || taskData == undefined) &&
																	!is_task_loading && !taskFilterData
																"
															>
																<div
																	class="d-flex flex-column align-items-center"
																	style="padding: 10rem"
																>
																	<div
																		class="avatar bg-secondary rounded-lg mb-25"
																		id="BtnincIcons"
																	>
																		<div class="avatar-content">
																			<span
																				><i
																					class="fa-light fa-book-open fa-lg"
																				></i
																			></span>
																		</div>
																	</div>
																	<span class="sm-font text-muted">{{
																		"UI.k_no_records_yet" | translate
																	}}</span>
																</div>
															</ng-container>
															<div
																*ngIf="
																	taskData?.length == 0 &&
																	showTaskData &&
																	taskFilterData &&
																	!is_task_loading
																"
																style="padding: 10rem"
															>
																<app-dns-empty-grid-data
																	[noFilterData]="true"
																></app-dns-empty-grid-data>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'sla'" class="flex-auto-height">
												<div class="flex-1">
													<ng-scrollbar
														class="custom-scrollbar section-scrollbar max-full-width"
													>
														<app-dns-sla-info-card
															[matricsData]="matricsData"
														></app-dns-sla-info-card>
													</ng-scrollbar>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'attachment'">
												<div class="flex-1">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="card mb-0 square border-0">
																<div class="card-body pl-0 pt-1 pr-0 pb-1">
																	<div class="d-flex w-100 mb-2 attachment-dropdown justify-content-between">
																		<div class="form-group mb-0">
																			<ng-select
																				[items]="
																					dropdown.attach_filter_category_list
																				"
																				[hideSelected]="true"
																				bindLabel="name"
																				bindValue="id"
																				(change)="
																					selectAttachmentCategory($event)
																				"
																				id="txtAttachCategory"
																				name="txtAttachCategory"
																				class="form-control"
																				placeholder="{{
																					'UI.k_select_dropdown' | translate
																				}}"
																				[(ngModel)]="selected_attachment_id"
																				[clearable]="false"
																			>
																			</ng-select>
																		</div>
																		<button
																		*ngIf="
																			(permissions?.add || permissions?.edit) &&
																			process_data_copy?.basic_info?.state?.id !== 5 &&
																			process_data_copy?.is_exit !== true &&
																			disable_config === false &&
																			is_ssp_login === false
																		"
																		id="btnAddFiles"
																		name="btnAddFiles"
																		[attr.data-testid]="'btnAddFiles'"
																		class="btn btn-primary pull-right ml-half"
																		tooltipClass="fadeInAnimation"
																		id="btnAttachmentIncident"
																		(click)="attachmentSidebar()"
																		placement="bottom"
																		ngbTooltip="{{ 'UI.k_add_attachment' | translate }}"
																		>
																			<i class="fa-light fa-circle-plus"></i>{{ "UI.k_add" | translate }}
																		</button>
																	</div>
																	<div
																		class="all-attachment-list incident-sidebar-attachments-list"
																	>
																		<ng-container
																			*ngIf="
																				isObjectEmpty(
																					filteredAttachmentByCategory
																				)
																			"
																		>
																			<div class="no-attechments">
																				<div
																					class="d-flex justify-content-center mt-1"
																				>
																					<img
																						class="rounded"
																						src="assets/images/incident/files_empty_state.svg"
																						alt="nodata-bg"
																						width="200"
																						height="106"
																					/>
																				</div>
																				<div
																					class="d-flex justify-content-center mt-1"
																				>
																					{{
																						"UI.k_no_attachment" | translate
																					}}
																				</div>
																			</div>
																		</ng-container>
																		<ng-container
																			*ngFor="
																				let item of filteredAttachmentByCategory
																					| keyvalue
																			"
																		>
																			<ng-container
																				*ngIf="isObjectEmpty(item.value)"
																			>
																				<div class="no-attechments">
																					<div
																						class="d-flex justify-content-center mt-1"
																					>
																						<img
																							class="rounded"
																							src="assets/images/incident/files_empty_state.svg"
																							alt="nodata-bg"
																							width="200"
																							height="106"
																						/>
																					</div>
																					<div
																						class="d-flex justify-content-center mt-1"
																					>
																						{{
																							"UI.k_no_attachment"
																								| translate
																						}}
																					</div>
																				</div>
																			</ng-container>
																			<ng-container
																				*ngFor="
																					let file of item.value;
																					let i = index
																				"
																			>
																				<div
																					class="category-attachment-list bg-body border"
																				>
																					<span class="sm-font text-muted"
																						>{{ item.key }} {{ i + 1 }} ({{
																							item.value.length || 0
																						}})</span
																					>
																					<div
																						class="d-flex justify-content-between align-items-baseline"
																					>
																						<div
																							id="attachment-{{
																								file.id
																							}}"
																							class="left-info-sec align-items-center d-flex gap-50"
																						>
																							<div class="text-center">
																								<div
																									class="avatar"
																									[ngClass]="
																										file.file_type ===
																										'.txt'
																											? 'bg-light-primary'
																											: file.file_type ===
																											  '.pdf'
																											? 'bg-light-danger'
																											: file.file_type ===
																													'.xls' ||
																											  file.file_type ===
																													'.xlsx'
																											? 'bg-light-success'
																											: file.file_type ===
																													'.doc' ||
																											  file.file_type ===
																													'.docx'
																											? 'bg-light-info'
																											: 'bg-light-primary'
																									"
																								>
																									<div
																										class="avatar-content"
																										id="feather_size"
																									>
																										<i
																											class="{{
																												file.file_icon
																											}} fa-light line-height-1 full-height w-100 d-flex align-items-center justify-content-center lg-font avatar-icon"
																											aria-hidden="true"
																										></i>
																									</div>
																								</div>
																							</div>
																							<div
																								class="media-body d-flex flex-column justify-content-between"
																							>
																								<span
																									class="pl-0 sm-font d-flex overflow-wrap file_position title-color mb-0"
																								>
																									<span
																										class="fw-500 mr-50 file-name truncate-txt"
																										tooltipClass="fadeInAnimation"
																										ngbTooltip="{{
																											file.file_name
																										}}"
																										>{{
																											file.file_name
																										}}</span
																									><span
																										class="file-version"
																										>v{{
																											file.version
																										}}</span
																									>
																								</span>
																								<span
																									class="xs-font line-height-1"
																									>{{
																										file.file_size_with_unit
																									}}
																									MB
																									<b
																										class="fw-600 xl-font line-height-1"
																										>.</b
																									>
																									{{
																										file.attachment_date
																									}}</span
																								>
																							</div>
																						</div>
																						<div
																							class="right-btn-sec d-flex"
																						>
																							<button 
																							type="button" 
																							class="btn btn-sm btn-flat-primary btn-icon" 
																							*ngIf="
																							file?.description
																							"
																							ngbTooltip="{{
																								file?.description
																							}}"
																							id="btnInfoAttachment" 
																							name="btnInfoAttachment"
																							[attr.data-testid]="'btnInfoAttachment'"
																							rippleEffect>
																							<i class="fa-light fa-circle-info"></i>
																						    </button>
																							<!-- <span
																								*ngIf="
																									file?.description
																								"
																								class="pull-right"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									file?.description
																								}}"
																								placement="bottom"
																							>
																								<i
																									class="fa-light fa-info-circle mr-half avatar-icon font-small-3 text-primary"
																								></i>
																							</span> -->
																							<button type="button" 
																							id="btnDownloadAttachment" 
																							name="btnDownloadAttachment"
																							[attr.data-testid]="'btnDownloadAttachment'"
																							(click)="
																							downloadAttachment(
																								file.file_path,
																								file.zip_file_name
																							)
																							"
																							class="btn btn-sm btn-flat-primary btn-icon" 
																							ngbTooltip="{{
																								'UI.k_download'
																									| translate
																							}}" 
																							rippleEffect>
																							<i
																							class="fa-light fa-download avatar-icon cursor-pointer reg-font"
																							></i>
																							</button>
																							<!-- <span
																								class=""
																								id="btnDownloadAttachment"
																								(click)="
																									downloadAttachment(
																										file.file_path,
																										file.zip_file_name
																									)
																								"
																								tooltipClass="fadeInAnimation"
																								placement="left"
																								ngbTooltip="{{
																									'UI.k_download'
																										| translate
																								}}"
																							>
																								<i
																									class="fa-light fa-download avatar-icon cursor-pointer reg-font text-primary"
																								></i>
																							</span> -->
																							<button type="button"
																							*ngIf="
																									permissions?.delete &&
																									process_data_copy?.is_exit !== true &&
																									disable_config === false &&
																									is_ssp_login === false
																								"
																							class="btn btn-sm btn-flat-danger btn-icon" 
																							id="btnDeleteAttachment"
																							name="btnDeleteAttachment"
																							[attr.data-testid]="'btnDeleteAttachment'"
																							(click)="deleteAttachment(file)"
																							ngbTooltip="{{ 'UI.k_delete' | translate }}"
																							 rippleEffect>
																							 <i
																							 class="fa-light fa-trash-can avatar-icon reg-font"
																						 ></i>
																							  </button>
																							<!-- <span
																								*ngIf="
																									permissions?.delete &&
																									process_data_copy?.is_exit !== true &&
																									disable_config === false
																								"
																								class="ml-50"
																								id="btnDeleteAttachment"
																								(click)="deleteAttachment(file)"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{ 'UI.k_delete' | translate }}"
																							>
																								<i
																									class="fa-light fa-trash-can cursor-pointer avatar-icon reg-font text-danger"
																								></i>
																							</span> -->
																						</div>
																					</div>
																				</div>
																			</ng-container>
																			<!-- </div> -->
																		</ng-container>
																	</div>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'knowledge_base'">
												<div class="flex-1">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="card square" *ngIf="kb_permissions?.view">
																<div class="card-body">
																	<span
																		*ngIf="showKB"
																		class="reg-font title-color fw-500"
																		>{{ "UI.k_knowledgebase" | translate }}</span
																	>
																	<ng-container *ngIf="!disable_config && permissions?.edit && !is_ssp_login">
																		<span
																		*ngIf="showSearch"
																		id="btnkbSeach"
																		name="kbSearch"
																		(click)="ShowAllKb('attach-knowledge-base')"
																		class="cursor-pointer pull-right"
																		><i class="fa-light fa-magnifying-glass"></i
																	></span>
																	</ng-container>


																	<!-- (click)="showKB=false; showSearch=false; showSearchInput=true;" -->
																	<ng-container *ngIf="filteredKBs?.length === 0">
																		<div
																			class="d-flex flex-column py-2 align-items-center"
																		>
																			<div
																				class="avatar bg-secondary rounded-lg mb-25"
																				id="BtnincIcons"
																			>
																				<div
																					class="avatar-content"
																					style="width: 28px; height: 28px"
																				>
																					<span
																						><i
																							class="fa-light fa-book-open fa-lg"
																						></i
																					></span>
																				</div>
																			</div>
																			<span class="sm-font text-muted">{{
																				"UI.k_no_records_yet" | translate
																			}}</span>
																		</div>
																	</ng-container>
																	<ng-container *ngIf="filteredKBs?.length > 0">
																		<div class="kb-list py-1">
																			<ng-container
																				*ngFor="let kb of filteredKBs"
																			>
																				<div
																					id="btn{{ kb.kb_id }}"
																					class="card p-1 border single-kb mb-0 shadow-none"
																				>
																					<div
																						class="d-flex flex-column gap-1"
																					>
																						<div
																							class="d-flex gap-2 top-kb-sec"
																						>
																							<a
																								class="cursor-pointer primary-color fw-600"
																								id="btnRedirectToKB"
																								(click)="
																									redirectToKb(kb.id)
																								"
																								routerLink="/kb/view/{{
																									kb.kb_id
																								}}"
																								ngbTooltip="{{
																									'UI.k_view'
																										| translate
																								}}"
																								target="_blank"
																							>
																								#{{ kb.display_id }}
																							</a>
																							<div
																								class="flex-wrap kb-user d-flex align-items-center"
																							>
																								<div
																									class="avatar avatar-xs mr-50"
																								>
																									<img
																										*ngIf="
																											kb.avatar
																										"
																										src="{{
																											kb.avatar
																										}}"
																										alt="Avatar"
																										width="16"
																										height="16"
																									/>
																									<div
																										*ngIf="
																											!kb.avatar
																										"
																										class="avatar-content"
																										[ngStyle]="{
																											'background-color':
																												kb.avatar_color
																										}"
																									>
																										{{
																											kb.created_by
																												| initials
																												| slice
																													: O
																													: 2
																										}}
																									</div>
																								</div>
																								<span
																									class="title-color fw-500"
																									>{{
																										kb.created_by
																									}}</span
																								>
																							</div>
																						</div>
																						<div
																							class="d-grid gap-1 kb-info"
																						>
																							<div class="img-sec">
																								<img
																									class="card-img-top img-fluid"
																									[ngClass]="{
																										'img-cover':
																											kb.image
																									}"
																									[src]="
																										kb.image
																											? kb.image +
																											  '?'
																											: imageMap[
																													kb
																														.kbtype
																											  ]
																									"
																									alt="KB Default"
																								/>
																							</div>
																							<div class="info-sec">
																								<div
																									class="fw-500 title-color mb-25 summary-ellipse"
																									*ngIf="
																										kb.title
																											?.length >
																										30
																									"
																									ngbTooltip="{{
																										kb.title
																											| slice
																												: 0
																												: 100
																									}}"
																									placement="top"
																								>
																									{{
																										kb.title.slice(
																											0,
																											60
																										)
																									}}
																									...
																								</div>
																								<div
																									class="fw-500 body-color mb-25 summary-ellipse"
																									*ngIf="
																										kb.title
																											?.length <=
																										30
																									"
																								>
																									{{ kb.title }}
																								</div>
																								<div
																									class="summary-ellipse"
																									*ngIf="
																										kb.content
																											?.length >
																										200
																									"
																									tooltip="kb.content"
																									ngbTooltip="{{
																										kb.content
																											| striphtml
																											| slice
																												: 0
																												: 250
																									}}"
																									placement="top"
																									[innerHtml]="
																										kb.content.slice(
																											0,
																											200
																										) + '...'
																									"
																								></div>
																								<div
																									class="summary-ellipse"
																									*ngIf="
																										kb.content
																											?.length <=
																										200
																									"
																									[innerHtml]="
																										kb.content
																									"
																								></div>
																								<ng-container
																									*ngIf="
																										kb.keywords
																											.length > 0
																									"
																								>
																									<div
																										class="keyword-list"
																									>
																										<ng-container
																											*ngFor="
																												let kw of kb.keywords
																											"
																										>
																											<span
																												class="sm-font border"
																												>{{
																													kw
																												}}</span
																											>
																										</ng-container>
																									</div>
																								</ng-container>
																							</div>
																						</div>
																					</div>
																					<div>
																						<!-- <div
																							class="d-flex flex-wrap justify-content-between mb-50 align-items-center"
																						>
																							<div
																								class="text-primary fw-500"
																							>
																								#{{ kb.display_id }}
																							</div>
																							<span
																								class="d-flex align-items-center"
																							>
																								<a
																									class="cursor-pointer mr-1 body-color"
																									id="btnRedirectToKB"
																									(click)="
																										redirectToKb(
																											kb.id
																										)
																									"
																									routerLink="/kb/view/{{
																										kb.kb_id
																									}}"
																									ngbTooltip="{{
																										'UI.k_view'
																											| translate
																									}}"
																									target="_blank"
																								>
																									<i
																										class="fa-light fa-circle-info"
																									></i>
																								</a>
																								<button
																									class="btn btn-sm btn-flat-primary active"
																									id="btnAttachToIncident"
																									name="btnAttachToIncident"
																									[attr.data-testid]="
																										'btnAttachToIncident'
																									"
																									(click)="
																										DettachToIncident(
																											kb
																										)
																									"
																								>
																									<i
																										class="fa-solid fa-thumbtack"
																									></i>
																									{{
																										"UI.k_pinned"
																											| translate
																									}}
																								</button>
																							</span>
																						</div>
																						<div
																							class="fw-500 title-color mb-25 summary-ellipse"
																							*ngIf="
																								kb.title?.length > 30
																							"
																							ngbTooltip="{{
																								kb.title | slice: 0:100
																							}}"
																							placement="top"
																						>
																							{{
																								kb.title.slice(0, 60)
																							}}
																							...
																						</div>
																						<div
																							class="fw-500 body-color mb-25 summary-ellipse"
																							*ngIf="
																								kb.title?.length <= 30
																							"
																						>
																							{{ kb.title }}
																						</div>
																						<div
																							class="mb-25 summary-ellipse"
																							*ngIf="
																								kb.content?.length > 200
																							"
																							tooltip="kb.content"
																							ngbTooltip="{{
																								kb.content
																									| striphtml
																									| slice: 0:150
																							}}"
																							placement="bottom"
																							[innerHtml]="
																								kb.content.slice(
																									0,
																									100
																								) + '...'
																							"
																						></div>
																						<div
																							class="mb-25 summary-ellipse"
																							*ngIf="
																								kb.content?.length <=
																								200
																							"
																							[innerHtml]="kb.content"
																						></div>
																						<div
																							class="d-flex align-items-center mb-75"
																						>
																							<div
																								class="flex-wrap kb-user mr-50 pr-50"
																							>
																								<div
																									class="avatar avatar-xs mr-50"
																								>
																									<img
																										*ngIf="
																											kb.avatar
																										"
																										src="{{
																											kb.avatar
																										}}"
																										alt="Avatar"
																										width="16"
																										height="16"
																									/>
																									<div
																										*ngIf="
																											!kb.avatar
																										"
																										class="avatar-content"
																										[ngStyle]="{
																											'background-color':
																												kb.avatar_color
																										}"
																									>
																										{{
																											kb.created_by
																												| initials
																												| slice
																													: O
																													: 2
																										}}
																									</div>
																								</div>
																								<span class="pt-25">{{
																									kb.created_by
																								}}</span>
																							</div>
																							<div>
																								<span
																									><i
																										class="fa-light fa-star"
																										style="
																											color: #ff9f43;
																											padding-top: 0.35rem;
																										"
																									></i></span
																								>&nbsp;
																								<span class="pt-25">{{
																									kb.rating
																								}}</span>
																							</div>
																						</div>
																						<div class="">
																							{{
																								"UI.k_last_updated_on"
																									| translate
																							}}
																							{{
																								kb.last_update_time
																									| date: "d MMM, y"
																							}}
																						</div> -->
																					</div>
																				</div>
																			</ng-container>
																		</div>
																	</ng-container>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'interaction'" class="flex-auto-height">
												<!-- <div class="p-1 border-bottom flex-initial">
                          <div class="d-flex justify-content-between align-items-center mb-75">
                              <div class="fw-500 text-dark" for="basic-request">
                                  {{ "UI.k_requester_details" | translate }}
                              </div>
                          </div>
                          <div class="d-flex align-items-center mb-1">
                              <div class="avatar mr-half">
                                  <div class="avatar" *ngIf="process_data?.requester_profile">
                                      <img
                                          [src]="process_data?.requester_profile"
                                          alt="Avatar"
                                          width="32"
                                          height="32"
                                      />
                                  </div>
                                  <div
                                      *ngIf="!process_data?.requester_profile"
                                      id="divInitial"
                                      class="avatar-content"
                                      [ngStyle]="{
                                          'background-color': process_data?.requester?.avatar_color
                                              ? process_data?.requester?.avatar_color
                                              : '#f59518'
                                      }"
                                  >
                                      {{ process_data?.requester?.full_name | initials | slice: 0:2 }}
                                  </div>
                              </div>
                              <div>
                                  <div class="fw-500 text-dark mb-0 text-break" id="divFullname">
                                      {{ process_data?.requester?.full_name }}
                                  </div>
      
                                  <span class="text-break" id="divEmail">{{ process_data?.requester?.email }}</span>
                              </div>
                          </div>
                          <div class="d-flex justify-content-start align-items-center">
                              <div *ngIf="process_data?.requester?.phone_ext">
                                  <span class="mr-50 xs-font">
                                      <i class="fa-light fa-phone"></i>
                                  </span>
                                  <span class="sm-font" id="spPhone">{{
                                      process_data?.requester?.phone_ext
                                  }}</span>
                              </div>
                          </div>
                        </div> -->
												<div class="flex-1 mt-2">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="px-1">
																<div
																	*ngIf="requester_history?.length === 0"
																	class="my-5"
																>
																	<div
																		class="d-flex align-items-center justify-content-center"
																	>
																		<div
																			class="avatar bg-secondary rounded-lg"
																			id="BtnincIcons"
																		>
																			<div
																				class="avatar-content"
																				style="width: 28px; height: 28px"
																			>
																				<span
																					><i
																						class="fa-light fa-book-open"
																					></i
																				></span>
																			</div>
																		</div>
																	</div>
																	<div
																		class="d-flex align-items-center justify-content-center"
																	>
																		<small class="ml-75 text-muted py-auto">{{
																			"UI.k_no_interaction" | translate
																		}}</small>
																	</div>
																</div>
																<div
																	*ngFor="
																		let history of requester_history;
																		let i = index
																	"
																	class="text-break single-interaction"
																>
																	<div
																		class="mb-50 sm-font history-time body-color fw-500"
																	>
																		{{
																			history?.creation_time
																				| date: "d MMM y, h:mm:ss a"
																		}}
																	</div>
																	<div class="requester-state-status">
																		<div class="group-lists">
																			<div
																				class="custom-squre"
																				style="{{ history?.basic_info?.status?.text_color ? 'background-color: ' + history?.basic_info?.status?.text_color : 'background-color: ' + history?.basic_info?.status?.color
																			}}"
																			></div>
																			<span>{{
																				history?.basic_info?.status?.name
																			}}</span>
																			<div
																				class="right-arrow custom-text"
																				style="{{ history?.basic_info?.status?.text_color ? 'background-color: ' + history?.basic_info?.status?.text_color + '1f' : 'background-color: ' + history?.basic_info?.status?.color + '1f'
																			}}"
																			>
																				<span 
																				style="{{ history?.basic_info?.status?.text_color ? 'color: ' + history?.basic_info?.status?.text_color + '!important' : 'color: ' + history?.basic_info?.status?.color + '!important'
																				}}">{{ history?.basic_info?.state?.name }}</span>
																			</div>
																			<!-- <div
                                                      class="right-arrow custom-icon"
                                                      *ngIf="this.inputConfig && !inputConfig?.isTextMode"
                                                      [ngStyle]="{
                                                          'background-color': item.background_color,
                                                          color: item.text_color
                                                      }"
                                                  >
                                                      <i [class]="item.icon"></i>
                                                  </div> -->
																		</div>
																	</div>
																	<div
																		class="mb-50 fw-500 sm-font title-color incident-title-truncate"
																		placement="bottom"
																		ngbTooltip="{{ history?.basic_info.summary }}"
																	>
																		{{ history?.basic_info.summary }}
																	</div>
																	<div
																		class="d-flex justify-content-between align-items-center mb-50"
																	>
																		<div
																			class="d-flex justify-content-start align-items-center interaction-detail"
																		>
																			<div
																				class="sm-font text-primary mr-50 fw-500 cursor-pointer"
																				(click)="
																					moveToEdit(history.incident_id)
																				"
																			>
																				{{ history?.display_id }}
																			</div>
																			<div
																				class="badge badge-light-primary mr-50"
																			>
																				<i
																					class="fa-light fa-globe xs-font"
																				></i>
																			</div>
																			<div
																				*ngIf="history?.is_parent_incident"
																				class="badge badge-light-primary mr-50"
																			>
																				<i
																					class="fa-light fa-share-nodes xs-font"
																				></i>
																			</div>
																		</div>
																		<div
																			class="badge badge-light-{{
																				history?.basic_info?.priority?.color
																			}} badge-padding mr-50 sm-font"
																			*ngIf="history?.basic_info?.priority?.name"
																		>
																			<i
																				class="fa-light mr-25 mt-25 {{
																					history?.basic_info?.priority?.icon
																				}}"
																			></i
																			>{{
																				history?.basic_info?.priority
																					?.display_key | translate
																			}}
																		</div>
																		<!-- <div
                                                  class="badge badge-light-success badge-padding mr-50 sm-font"
                                              >
                                                  {{ history?.basic_info.state.name }}
                                              </div> -->
																	</div>
																	<div
																		class="d-flex mt-50 mb-75 align-items-center"
																		*ngIf="
																			history?.current_assignee_profile?.full_name
																		"
																	>
																		<div
																			*ngIf="history"
																			class="avatar avatar-xs mr-25"
																		>
																			<img
																				*ngIf="
																					history?.current_assignee_profile
																						?.profile_image
																				"
																				[src]="
																					history?.current_assignee_profile
																						?.profile_image
																				"
																				width="16"
																				height="16"
																			/>
																			<div
																				*ngIf="
																					!history?.current_assignee_profile
																						?.profile_image
																				"
																				class="avatar-content"
																				[ngStyle]="{
																					'background-color': history
																						?.current_assignee_profile
																						?.avatar_color
																						? history
																								?.current_assignee_profile
																								?.avatar_color
																						: '#f59518'
																				}"
																			>
																				{{
																					history?.current_assignee_profile
																						?.full_name
																						| initials
																						| slice: O:2
																				}}
																			</div>
																		</div>
																		<span class="sm-font line-height-1"
																			>&nbsp;&nbsp;{{
																				history?.current_assignee_profile
																					?.full_name
																			}}</span
																		>
																	</div>
																	<div
																		*ngFor="
																			let attachment of history?.attached_files
																		"
																		class="d-flex justify-content-between align-items-center bg-body attachments-detail mt-50 mb-50"
																	>
																		<div>
																			<div
																				class="sm-font d-flex justify-content-start align-items-center"
																			>
																				<i
																					class="fa-light fa-file-lines text-warning mr-25"
																				></i>
																				<span class="title-color ellipse">
																					{{
																						attachment.file_name
																					}}&nbsp;&nbsp;v{{
																						attachment.version
																					}}
																				</span>
																			</div>
																		</div>
																		<div class="download-file">
																			<div
																				(click)="
																					downloadAttachment(
																						attachment.file_path,
																						attachment.zip_file_name
																					)
																				"
																				class="cursor-pointer title-color"
																			>
																				<i class="fa-light fa-download"></i>
																			</div>
																		</div>
																		<!-- <span  class="xs-font ml-75">{{atttachment.file_name}}</span>	 -->
																	</div>
																</div>
																<div
																	class="d-flex justify-content-center pt-50 pb-1"
																	*ngIf="
																		displayAllInteraction &&
																		requester_history_dup.length - 2 > 0
																	"
																>
																	<button
																		type="button"
																		class="btn btn-outline-primary btn-sm"
																		id="btnShow"
																		name="btnShow"
																		[attr.data-testid]="'btnShow'"
																		(click)="ShowAllInteraction()"
																		rippleEffect
																	>
																		{{ "UI.k_all_interaction" | translate }}
																	</button>
																</div>
																<div
																	class="d-flex justify-content-center pt-50 pb-1"
																	*ngIf="!displayAllInteraction"
																>
																	<button
																		type="button"
																		class="btn btn-outline-primary btn-sm"
																		id="btnHide"
																		name="btnHide"
																		[attr.data-testid]="'btnHide'"
																		(click)="HideAllInteraction()"
																		rippleEffect
																	>
																		{{ "UI.k_hide_interaction" | translate }}
																	</button>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div
												*ngIf="selectedItemName === 'resent_activity'"
												class="flex-auto-height"
											>
												<div class="activities flex-1 flex-auto-height">
													<div
														class="p-1 border-bottom d-flex justify-content-between align-items-center flex-initial"
													>
														<div class="col-4 px-0 input-group input-group-merge">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon-search2"
																	><i class="fa-light fa-magnifying-glass"></i
																></span>
															</div>

															<input
																type="text"
																class="form-control form-control-sm"
																id="config-search"
																placeholder="{{
																	'UI.k_search_configuration' | translate
																}}"
																aria-label="Search..."
																aria-describedby="config-search"
																(keyup)="searchConfig($event)"
															/>
														</div>
														<div class="pull-right align-items-center d-flex p-0 m-0">
															<div class="align-items-center d-flex">
																<div
																	class="btn-group btn-group-toggle mr-1"
																	data-toggle="buttons"
																>
																	<label
																		class="btn btn-icon btn-outline-primary view-btn list-view-btn"
																		[class.active]="gridViewRef !== true"
																		ngbTooltip="{{
																			'UI.k_timeline_view' | translate
																		}}"
																		placement="bottom"
																	>
																		<input
																			type="radio"
																			name="radio_options"
																			id="historyListView"
																			(click)="listView()"
																		/>
																		<i class="fa-light fa-list"></i>
																	</label>
																	<label
																		class="btn btn-icon btn-outline-primary view-btn grid-view-btn"
																		[class.active]="gridViewRef === true"
																		ngbTooltip="{{ 'UI.k_grid_view' | translate }}"
																		placement="bottom"
																	>
																		<input
																			type="radio"
																			name="radio_options"
																			id="historyGridView"
																			(click)="gridView()"
																		/>
																		<i class="fa-light fa-grid"></i>
																	</label>
																</div>
															</div>

															<!-- <button
                                id="BHistory"
                                name="viewKBHistory"
                                class="btn btn-sm btn-outline-primary float-right"
                                rippleEffect
                                tooltipClass="fadeInAnimation"
                              >
                                <span class="d-none d-sm-inline-block sm-font"
                                  ><i class="fa-light fa-download sm-font"></i>
                                  {{ 'UI.k_export' | translate }}
                                </span>
                              </button> -->
														</div>
													</div>
													<div class="flex-1">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<ng-container *ngIf="!gridViewRef">
																<div class="inner-history-table flex-1 py-1">
																	<div class="flex-grow-1">
																		<app-history-table
																			[listview]="historyDataset"
																		></app-history-table>
																	</div>
																</div>
															</ng-container>
															<ng-container *ngIf="gridViewRef">
																<div class="inner-history-table flex-1 py-1">
																	<app-history-table [gridview]="historyDataset">
																	</app-history-table>
																</div>
															</ng-container>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'relation'" class="flex-auto-height">
												<div class="flex-1">
													<ng-scrollbar
														class="custom-scrollbar section-scrollbar max-full-width"
													>
														<div class="relations">
															<div class="px-1 pt-1 pb-2 d-flex">
																<div
																	id="divGetIncident"
																	[attr.data-testid]="'divGetIncident'"
																	[ngClass]="
																		relationModuleIDSel ===
																		infinity_module_ids?.INCIDENT
																			? 'badge badge-light-primary d-flex'
																			: 'badge badge-light-secondary d-flex'
																	"
																>
																	<span
																		(click)="
																			relationFilter(
																				infinity_module_ids?.INCIDENT
																			)
																		"
																		class="xs-font text-decoration-underline cursor-pointer"
																		>{{ "UI.k_incident" | translate }}
																	</span>
																	<ng-container
																		*ngIf="
																			permissions?.edit &&
																			!disable_config &&
																			!(
																				process_data?.is_exit &&
																				process_data?.basic_info?.status
																					?.state_id === 5
																			)
																			&& !is_ssp_login
																		"
																	>
																		<span class="xs-font mx-50">|</span>
																		<span
																			class="pt-25 cursor-pointer"
																			tooltipClass="fadeInAnimation"
																			ngbTooltip="{{
																				'UI.k_add_association_relation'
																					| translate
																			}}"
																			placement="right"
																			(click)="
																				addRelations(infinity_modules?.INCIDENT)
																			"
																		>
																			<i class="fa-solid fa-circle-plus"></i
																		></span>
																	</ng-container>
																</div>
																<ng-container *ngIf="problem_permissions?.view">
																	<div
																		id="divGetProblem"
																		[attr.data-testid]="'divGetProblem'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.PROBLEM
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.PROBLEM
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_problem" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id === 5
																				)
																				&& !is_ssp_login
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.PROBLEM
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>

																<ng-container *ngIf="change_permissions?.view">
																	<div
																		id="divgetChange"
																		[attr.data-testid]="'divgetChange'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.CHANGE
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.CHANGE
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_change" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id === 5
																				) && !is_ssp_login
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.CHANGE
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>
																<ng-container *ngIf="request_permissions?.view">
																	<div
																		id="divgetRequest"
																		[attr.data-testid]="'divgetRequest'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.REQUEST_PROCESS
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.REQUEST_PROCESS
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_request" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id === 5
																				) && !is_ssp_login
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.REQUEST_PROCESS
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>
																<ng-container *ngIf="release_permissions?.view">
																	<div
																		id="divgetRelease"
																		[attr.data-testid]="'divgetRelease'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.RELEASE
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.RELEASE
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_release" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id === 5
																				) && !is_ssp_login
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.RELEASE
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>
															</div>

															<ng-container *ngIf="incidentRelations?.length > 0">
																<!-- parent child relation and incident association -->
																<div class="summary-card-list">
																	<ng-container *ngFor="let req of incidentRelations">
																		<div class="summary_card_info p-1 border">
																			<div
																				class="summary-card-head d-flex justify-content-between"
																			>
																				<div
																					class="left-sec d-flex gap-50 border-0"
																				>
																					<ng-container					
																					>
																						<div
																							class="reg-font primary-color"
																							id="quick_icons"
																						>
																							<i
																								tooltipClass="fadeInAnimation"
																								[ngbTooltip]="getRelationMap(req, 'source_name')"
																								placement="left"
																								class="fa-light fa-{{
																									sourceIconMap[
																									getRelationMap(req, 'source_id')
																									]
																								}}"
																							></i>
																						</div>
																					</ng-container>
																					<a
																						[routerLink]="getRelationMap(req, 'link')"
																						target="_blank"
																					>
																						<span
																							class="pull-left sm-font body-color"
																							><b></b
																							>{{getRelationMap(req, 'display_id')}}</span
																						>
																					</a>
																				</div>
																				<div class="right-sec">
																					<div
																						class="pull-right status_tag d-flex gap-25"
																					>
																						<div
																							class="fw-500 p-25 badge d-flex gap-25 badge-light-{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									processID
																										? req?.child_ref_id
																										: req.parent_ref_id
																								]?.priority_color
																							}}"
																						>
																							<i
																								class="fa-light"
																								[ngClass]="{
																									'fa-triangle-exclamation':
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										1,
																									'fa-angles-up':
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										2,
																									'fa-angle-up':
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										3,
																									'fa-hyphen':
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										1
																								}"
																							></i>
																							<span class="comment">{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									processID
																										? req?.child_ref_id
																										: req.parent_ref_id
																								]?.priority_name
																									| translate
																							}}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div class="summary-card-body">
																				<div
																					class="my-50 d-flex justify-content-start align-items-center"
																				>
																					<span
																						class="comment-sec pull-left ellipse fw-600 title-color"
																						>{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								processID
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.summary | truncate
																						}}</span
																					>
																				</div>
																			</div>
																			<div class="summary-card-footer">
																				<div
																					class="my-50 d-flex justify-content-between align-items-center"
																				>
																					<span
																						*ngIf="
																							!incidentMapping[
																								req?.parent_ref_id ===
																								processID
																									? req?.child_ref_id
																									: req.parent_ref_id
																							]?.asset_id
																						"
																						class="comment ellipse"
																					>
																						<span class="sm-font">
																							{{
																								"UI.k_service"
																									| translate
																							}}:
																						</span>
																						<span
																							class="sm-font fw-500 title-color"
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									processID
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.impact_service_name
																							}}</span
																						>
																					</span>
																					<ng-container
																						*ngIf="
																							incidentMapping[
																								req?.parent_ref_id ===
																								processID
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.asset_id &&
																							incidentMapping[
																								req?.parent_ref_id ==
																								processID
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.cmdb_id
																						"
																					>
																						<span class="comment"
																							><span class="sm-font"
																								>{{
																									"UI.k_asset_id"
																										| translate
																								}}
																								:</span
																							>
																							<span
																								*ngIf="
																									asset_permissions?.view &&
																									!request_data?.is_asset_disabled
																								"
																							>
																								<a
																									href="/it-asset/asset/{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]?.cmdb_id
																									}}"
																									target="_blank"
																									class="sm-font"
																									title="{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]?.asset_id
																									}}"
																								>
																									<span
																										class="reg-font fw-500 title-color"
																									>
																										{{
																											incidentMapping[
																												req?.parent_ref_id ===
																												processID
																													? req?.child_ref_id
																													: req?.parent_ref_id
																											]?.asset_id
																												| slice
																													: 0
																													: 19
																										}}{{
																											incidentMapping[
																												req?.parent_ref_id ===
																												processID
																													? req.child_ref_id
																													: req.parent_ref_id
																											]?.asset_id
																												?.length >
																											19
																												? ".."
																												: ""
																										}}
																									</span>
																								</a>
																							</span>
																							<span
																								*ngIf="
																									!asset_permissions?.view ||
																									request_data?.is_asset_disabled
																								"
																								class="sm-font"
																								title="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										processID
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]?.asset_id
																								}}"
																							>
																								<span
																									class="reg-font fw-500 title-color"
																								>
																									{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]?.asset_id
																											| slice
																												: 0
																												: 19
																									}}{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											processID
																												? req.child_ref_id
																												: req.parent_ref_id
																										]?.asset_id
																											?.length >
																										19
																											? ".."
																											: ""
																									}}
																								</span>
																							</span>
																						</span>
																					</ng-container>
																					<div class="">
																						<div
																							*ngIf="
																								req?.relation_type ===
																									2 &&
																								req?.parent_ref_id !==
																									processID
																							"
																							class="fw-500 badge badge-light-success p-25"
																						>
																							<span class="comment">{{
																								"UI.k_parent"
																									| translate
																							}}</span>
																						</div>
																						<div
																							*ngIf="
																								req?.relation_type ===
																									2 &&
																								req?.parent_ref_id ===
																									processID
																							"
																							class="badge badge-light-danger fw-500 p-25"
																						>
																							<span class="comment">{{
																								"UI.k_child" | translate
																							}}</span>
																						</div>
																						<div
																							class="fw-500 badge badge-light-success p-25"
																							*ngIf="
																								req?.relation_type !== 2
																							"
																						>
																						<ng-container
																							*ngIf="
																								checkInterModuleAssociation(
																									req,
																									incident_module_id
																								)
																							"
																						>
																							<span class="comment">{{
																								"UI.k_ticket"
																									| translate
																							}}</span>
																							&nbsp;
																						</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										request_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_request"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										problem_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_problem"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										change_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_change"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										release_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_release"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<span class="comment">{{
																								"UI.k_associated"
																									| translate
																							}}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</ng-container>
																</div>
															</ng-container>
															<ng-container
																*ngIf="
																	incidentRelations?.length === 0 ||
																	incidentRelations === undefined
																"
															>
																<div
																	class="d-flex flex-column align-items-center"
																	style="padding: 10rem"
																>
																	<div
																		class="avatar bg-secondary rounded-lg mb-25"
																		id="BtnincIcons"
																	>
																		<div class="avatar-content">
																			<span
																				><i
																					class="fa-light fa-book-open fa-lg"
																				></i
																			></span>
																		</div>
																	</div>
																	<span class="sm-font text-muted">{{
																		"Message.msg_no_relation" | translate
																	}}</span>
																</div>
															</ng-container>
														</div>
													</ng-scrollbar>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'analysis_and_solutions'">
												<div class="flex-1">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="p-1">
																<div class="full-width pb-1">
																	<div class="card mb-0 pl-0 pr-0 border rounded">
																		<div class="p-1 card-body border-bottom d-flex align-items-center justify-content-between">
																			<div id="divAnalysisTitle" >
																				<span class="fw-500 lg-font title-color">{{ "UI.k_analysis" | translate }}</span>
																			</div>
	
																			<div id="divAnalysisButtons" class="d-flex align-items-center justify-content-between">
																				<!-- <button type="button" (click)="onDeleteAttachment()" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																					id="btnOnDelete" rippleEffect>
																					<i class="fa-light fa-trash-can cursor-pointer avatar-icon reg-font"></i>
																				</button> -->
	
																				<!-- <button type="button" (click)="toggleAnalysisSideBar('app-problem-analysis-sidebar')" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																					id="btnOnEdit" rippleEffect>
																					<i class="fa-light fa-edit cursor-pointer avatar-icon reg-font"></i>
																				</button> -->
																				
																				<button type="button" (click)="toggleAnalysisSideBar('app-problem-analysis-sidebar')" 
																					class="btn btn-sm btn-icon btn-flat-primary mr-25"
																					id="btnAnalysisAdd"
																					name="btnAnalysisAdd"
																					[attr.data-testid]="'btnAnalysisAdd'"
																					tooltipClass="fadeInAnimation"
																					*ngIf="!disable_config && !(process_data?.basic_info?.state?.id == 5 &&
																					process_data?.is_exit) && !is_ssp_login"
																					ngbTooltip="{{ 'UI.k_add' | translate }}"
																					rippleEffect
																				>
																					<i  class="fa-light fa-plus cursor-pointer avatar-icon reg-font"></i>
																				</button>
	
																				<button type="button" class="btn btn-sm btn-outline-primary ml-auto" id="btnView" name="btnView" [attr.data-testid]="'btnView'" (click)="toggleAnalysisDraftSideBar('app-view-draft-sidebar')">
																					{{"UI.k_view" | translate}} {{"UI.k_draft" | translate}} 
																				</button>
																			</div>
																		</div>
																		<ng-container  *ngFor="let root_cause of process_data?.root_cause">
																			<div class="p-1 card-body border-bottom">
																				<div class="d-flex align-items-center justify-content-between">
																					<div id="divAnalysisHeadline" class="d-flex align-items-center justify-content-start mb-50">
																						<span class="mb-0 lg-font title-color fw-500"> {{root_cause?.analysis_technique?.display_key | translate}}  </span>
																						<div class="badge badge-light-success ml-50"> 
																							<span class="sm-font">
																								{{ 'UI.k_root_cause' | translate }}
																							</span>
																						</div>
																					</div>
																					<div id="divAnalysisButtons" class="d-flex align-items-center justify-content-end">			
																						<button type="button" (click)="toggleAnalysisSideBar('app-problem-analysis-sidebar', root_cause?.problem_root_cause_id)" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																							id="btnAnalysisEdit"
																							name="btnAnalysisEdit"
																							[attr.data-testid]="'btnAnalysisEdit'"
																							ngbTooltip="{{ 'UI.k_edit' | translate }}"
																							*ngIf="!disable_config && !is_ssp_login"
																							tooltipClass="fadeInAnimation"
																							rippleEffect
																						>
																							<i class="fa-light fa-edit cursor-pointer avatar-icon reg-font"></i>
																						</button>
																					</div>
																				</div>
																				<div id="divAnalysisContent" *ngIf="root_cause?.root_cause_details" class="pb-50">
																					{{root_cause?.root_cause_details | translate}}
																				</div>
	
																				<div class="px-0 py-50 card-bg d-flex communication-badges"  id="divAnalysisCardChips">
																					<div id="btnAnalyisisName" *ngIf="root_cause?.submitter_profile" class="pull-left badge badge-light-secondary">
																						<span class="sm-font"> {{root_cause?.submitter_profile?.name}}</span>
																					</div>
																					<div id="btnIncidentEmail" *ngIf="root_cause?.analysis_type?.display_key" class="pull-left ml-50 badge badge-light-secondary">
																						<span class="sm-font"> {{root_cause?.analysis_type?.display_key | translate}}</span>
																					</div>
																					<div id="btnAnalysisDepartment" *ngIf="root_cause?.category" class="pull-left ml-50 badge badge-light-secondary">
																						<span class="sm-font">  {{root_cause?.category}}</span>
																					</div>
																					<div id="btnAnalysisDate" *ngIf="root_cause?.formatted_submission_date" class="pull-left ml-50 badge badge-light-secondary">
																						<span class="sm-font">  {{root_cause?.formatted_submission_date}} </span>
																					</div>
																				</div>
																			</div>
																		</ng-container>
																		<ng-container *ngIf="process_data?.root_cause?.length == 0">
																			<div class="d-flex flex-column py-2 align-items-center">
																				<div class="avatar bg-secondary rounded-lg mb-25" id="BtnincIcons">
																					<div class="avatar-content" style="width: 28px; height: 28px">
																						<span><i class="fa-light fa-book-open fa-lg"></i></span>
																					</div>
																				</div>
																				<span class="sm-font text-muted">{{
																					"UI.k_no_records_yet" | translate
																				}}</span>
																			</div>
																		</ng-container>
																	</div>
																</div>
	
																<div class="full-width pb-1">
																	<div class="card mb-0 pl-0 pr-0 border rounded">
																		<div class="p-1 card-body border-bottom d-flex align-items-center justify-content-between">
																			<div id="divSolutionTitle" >
																				<span class="fw-500 lg-font title-color">{{ "UI.k_solution" | translate }} / {{ "UI.k_workaround" | translate }}</span>
																			</div>
	
																			<div id="divSolutionsButtons" class="d-flex align-items-center justify-content-between">
																				<!-- <button type="button" (click)="deleteAttachment()" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																					id="btnOnDelete" rippleEffect>
																					<i class="fa-light fa-trash-can cursor-pointer avatar-icon reg-font"></i>
																				</button> -->
	
																				<!-- <button type="button" (click)="toggleAnalysisSideBar('app-problem-solution-sidebar')" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																					id="btnOnEdit" rippleEffect>
																					<i class="fa-light fa-edit cursor-pointer avatar-icon reg-font"></i>
																				</button> -->
																				
																				<button type="button" (click)="toggleSolutionSideBar('app-problem-solution-sidebar')" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																					id="btnSolutionAdd"
																					name="btnSolutionAdd" 
																					[attr.data-testid]="'btnSolutionAdd'"
																					tooltipClass="fadeInAnimation"
																					*ngIf="!disable_config && !(process_data?.basic_info?.state?.id == 5 &&
																					process_data?.is_exit) && !is_ssp_login"
																					ngbTooltip="{{ 'UI.k_add' | translate }}"
																					rippleEffect
																				>
																					<i  class="fa-light fa-plus cursor-pointer avatar-icon reg-font"></i>
																				</button>
	
																				<!-- <button type="button" class="btn btn-outline-primary ml-auto" (click)="toggleSolutionSideBar('app-problem-solution-sidebar')">
																					{{ "UI.k_view" | translate }} {{ "UI.k_workaround" | translate }}
																				</button> -->
																			</div>
																		</div>
																		<ng-container  *ngFor="let solution of process_data?.root_cause_solution">
																			<div class="p-1 card-body border-bottom">
																				<div id="divSolutionsContent" class="">
																					<!-- <div id="divSolutionImage" class="mr-1">
																						<img src="assets/images/problem-management/static-solution-image.png">
																					</div> -->
																					<div class="" id="divSolutionContent" class="ml-50">
																						<div class="d-flex align-items-center justify-content-between">
																							<div id="divSolutionContentSubTitle" class="d-flex mb-50 align-items-center justify-content-start">
																								<span class="mb-0 lg-font title-color fw-500" id="divSolutionContentTitle mb-0" >
																									{{ solution?.solution | translate }}
																								</span>
	
																								<div class="badge badge-light-success" *ngIf="solution?.root_cause_data?.actual"> 
																									<span class="sm-font">
																										{{ "UI.k_root_cause" | translate }}
																									</span>
																								</div>
																							</div>
																							<div class="d-flex align-items-center justify-content-end">
																								<button type="button" 
																									(click)="toggleSolutionSideBar('app-problem-solution-sidebar', solution?.problem_soln_id, 'solution')" 
																									class="btn btn-sm btn-icon btn-flat-primary mr-25"
																									id="btnSolutionEdit" 
																									name="btnSolutionEdit"
																									[attr.data-testid]="'btnSolutionEdit'"
																									*ngIf="!disable_config && !is_ssp_login"
																									ngbTooltip="{{ 'UI.k_edit' | translate }}" 
																									rippleEffect
																								>
																									<i class="fa-light fa-edit cursor-pointer avatar-icon reg-font"></i>
																								</button>
																							</div>
																						</div>
																						<div id="divSolutionContent">
																							{{ solution?.root_cause_data?.analysis_technique?.name }}
																						</div>
	
																						<div id="divSolutionChips" class="my-50">
																							<button type="button" class="btn-outline-secondary round px-2 mr-1" rippleEffect>{{ "UI.k_solution" | translate }}</button>
																							<button type="button" class="btn-outline-secondary round px-2 mr-1" rippleEffect>{{ solution?.formatted_creation_time }}</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</ng-container>
																		<ng-container  *ngFor="let solution of process_data?.root_cause_workaround">
																			<div class="p-1 card-body border-bottom">
																				<div id="divSolutionsContent" class="">
																					<!-- <div id="divSolutionImage" class="mr-1">
																						<img src="assets/images/problem-management/static-solution-image.png">
																					</div> -->
																					<div class="" id="divSolutionContent" class="ml-50">
																						<div class="d-flex align-items-center justify-content-between">
																							<div id="divSolutionContentSubTitle" class="d-flex mb-50 align-items-center justify-content-start">
																								<h4 class="fw-500 xl-font" id="divSolutionContentTitle" >
																									{{ solution?.workaround | translate }}
																								</h4>
	
																								<div class="badge badge-light-success" *ngIf="solution?.root_cause_data?.actual"> 
																									<span class="sm-font">
																										{{ "UI.k_root_cause" | translate }}
																									</span>
																								</div>
																							</div>
																							<div class="d-flex align-items-center justify-content-end">
																								<button type="button" (click)="toggleSolutionSideBar('app-problem-solution-sidebar', solution?.problem_workaround_id, 'workaround')" class="btn btn-sm btn-icon btn-flat-primary mr-25"
																									id="btnOnEdit" 
																									[attr.data-testid]="'btnOnEdit'"
																									ngbTooltip="{{ 'UI.k_edit' | translate }}"
																									*ngIf="!disable_config && !is_ssp_login"
																									rippleEffect
																								>
																									<i class="fa-light fa-edit cursor-pointer avatar-icon reg-font"></i>
																								</button>
																							</div>
																						</div>
																						<div id="divSolutionContent">
																							{{ solution?.root_cause_data?.analysis_technique?.name }}
																						</div>
	
																						<div id="divSolutionChips" class="my-50">
																							<button type="button" class="btn-outline-secondary round px-2 mr-1" rippleEffect>{{ "UI.k_workaround" | translate }}</button>
																							<button type="button" class="btn-outline-secondary round px-2 mr-1" rippleEffect>{{solution?.formatted_creation_time}}</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</ng-container>
																		<ng-container *ngIf="process_data?.root_cause_solution?.length == 0 && process_data?.root_cause_workaround?.length == 0">
																			<div class="d-flex flex-column py-2 align-items-center">
																				<div class="avatar bg-secondary rounded-lg mb-25" id="BtnincIcons">
																					<div class="avatar-content" style="width: 28px; height: 28px">
																						<span><i class="fa-light fa-book-open fa-lg"></i></span>
																					</div>
																				</div>
																				<span class="sm-font text-muted">{{
																					"UI.k_no_records_yet" | translate
																				}}</span>
																			</div>
																		</ng-container>
	
																		<!-- <div class="p-1 card-body border-bottom d-flex align-items-center justify-content-between">
																			<div class="d-flex align-items-center my-25">
																				<div id="divSolutionIdentifier" class="fw-500 reg-font primary-color ml-half pr-2 border-right">
																					ARTICLE00004
																				</div>
	
																				<div id="divSolutionProfile" class="pr-2 pl-1 border-right">
																					<div class="d-flex justify-content-start align-items-center">
																						<div class="avatar bg-success avatar-xs">
																							<ng-container *ngIf="!incident_data?.requester_profile">
																								<span class="avatar-content" [ngStyle]="{
																									'background-color': stringToColor(
																										incident_data?.requester?.full_name
																									)
																								}">{{
																									this.incident_data?.requester?.full_name
																									| initials
																									| slice: O:2
																									}}</span>
																							</ng-container>
																							<ng-container *ngIf="incident_data?.requester_profile">
																								<img class="avatar-content" class=""
																									[src]="incident_data?.requester_profile" alt="" />
																							</ng-container>
																						</div>
																						<span class="ml-25 xs-font text-muted-color text-break">{{
																							incident_data?.requester?.full_name.length > 10
																							? (incident_data?.requester?.full_name | slice: 0:10) + "..."
																							: incident_data?.requester?.full_name
																							}}</span>
																					</div>
																				</div>
	
																				<div id="divSolutionReviews" class="pr-2 pl-1 pr-1">
																					<span class="custom-star rating rating-sm custom-full ng-star-inserted"><span class="custom-half" style="width: 100%;">★</span>★ </span>
																					<b>
																						4.5 stars
																					</b>  from 
																					
																					<b>
																						324
																					</b> ratings
																				</div>
																			</div>
																			<div class="d-flex align-items-center my-25">
																				<div class="mr-1 cursor-pointer">
																					<span *ngIf="solutionObj?.user_liked" (click)="userLike(false, solutionObj.solution_id, i)"
																						tooltipClass="fadeInAnimation" placement="top"
																						ngbTooltip="{{ 'UI.k_like' | translate }}">
																						<i class="fa-solid fa-thumbs-up text-primary"></i>
																					</span>
																					<span *ngIf="!solutionObj?.user_liked" id="btnKbLike"
																						(click)="userLike(true, solutionObj.solution_id, i)" tooltipClass="fadeInAnimation"
																						placement="top" ngbTooltip="{{ 'UI.k_like' | translate }}">
																						<i class="fa-light fa-thumbs-up"></i>
																					</span>
																					<span class="text-body">
																						{{ solutionObj.likes || 0 }}
																					</span>
																				</div>
																				<div class="mr-1 cursor-pointer">
																					<span (click)="toggleViewSidebar(solutionObj)" id="btnKbView"
																						tooltipClass="fadeInAnimation" placement="top"
																						ngbTooltip="{{ 'UI.k_views' | translate }}">
																						<i class="fa-light fa-eye"></i>
																					</span>
																					<span class="text-body" id="btnKbviewCount">
																						{{ solutionObj.views || 0 }}
																					</span>
																				</div>
																				<div class="mr-1" tooltipClass="fadeInAnimation"
																					placement="top" ngbTooltip="{{ 'UI.k_comments' | translate }}">
																					<i class="fa-light fa-message"></i>
																					<span class="text-body" id="btnKbCommentCount">
																						{{ solutionObj.comments || 0 }}
																					</span>
																				</div>
																				<div class="mr-1 cursor-pointer">
																					<a tooltipClass="fadeInAnimation" placement="top"
																						ngbTooltip="{{ 'UI.k_bookmarked' | translate }}"
																						class="justify-content-center ml-1" id="btnKbBookmark"
																						(click)="userBookmark(false, solutionObj.solution_id, i)" class="font-weight-bold"
																						*ngIf="solutionObj?.is_bookmarked">
																						<i class="fa-solid fa-bookmark text-primary"></i>
																					</a>
																					<a tooltipClass="fadeInAnimation" class="justify-content-center ml-1"
																						placement="top" ngbTooltip="{{ 'UI.k_bookmark' | translate }}"
																						id="btnKbUserBookmark" (click)="userBookmark(true, solutionObj.solution_id, i)"
																						class="font-weight-bold" *ngIf="!solutionObj?.is_bookmarked">
																						<i class="fa-light fa-bookmark"></i>
																					</a>
																				</div>
																				<div class="sm-font my-25">
																					Yesterday
																				</div>
																			</div>
																		</div> -->
																	</div>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'planning'">
												<div class="flex-1">
													<form #planningForm="ngForm" id="orderform" class="full-height">
														<div class="flex-auto-height">
															<ng-container *ngIf="moduleName=='Change'">
																<div class="flex-1">
																	<ng-scrollbar class="custom-scrollbar section-scrollbar">
																		<div class="px-1 pt-1">
																			<div class="d-flex wrap">
																				<div class="p-0">
																					<h5>{{ "UI.k_planning" | translate }}</h5>
																				</div>
																			</div>
																			<div class="row">
																				<div class="col-sm-6">
																					<div
																						class="form-group custom-date-picker custom-input-group"
																					>
																						<label
																							for="dtDueDate"
																							class="form-label"
																						>
																							{{
																								"UI.k_planned_start_date"
																									| translate
																							}}</label
																						>
																						<div class="input-group">
																							<ng2-flatpickr
																								#planStartDate
																								[config]="plnStartDateOptions"
																								name="plnStartDate"
																								[setDate]="
																								process_data.basic_info
																										.plan_start_date
																								"
																								id="plnStartDate "
																								[disabled]="disable_config || is_ssp_login"
																								[(ngModel)]="
																								process_data.basic_info
																										.plan_start_date
																								"
																								placeholder="{{
																									'UI.k_planned_start_date'
																										| translate
																								}}"
																							></ng2-flatpickr>
																							<div class="input-group-append">
																								<button
																									class="btn btn-outline-secondary feather icon-calendar"
																									(click)="
																										planStartDate.flatpickr.toggle()
																									"
																									type="button"
																									rippleEffect
																									id="btnflatpickercalendar"
																									name="btnflatpickercalendar"
																									[attr.data-testid]="'btnflatpickercalendar'"
																								></button>
																							</div>
																						</div>
																					</div>
																				</div>
																				<div class="col-sm-6">
																					<div
																						class="form-group custom-date-picker custom-input-group"
																					>
																						<label
																							for="dtDueDate"
																							class="form-label"
																						>
																							{{
																								"UI.k_planned_end_date"
																									| translate
																							}}</label
																						>
																						<div class="input-group">
																							<ng2-flatpickr
																								#planEndDate
																								[config]="plnEndDateOptions"
																								name="plnEndDate"
																								[setDate]="
																								process_data.basic_info
																										.plan_end_date
																								"
																								id="plnEndDate "
																								[disabled]="disable_config || is_ssp_login"
																								[(ngModel)]="
																								process_data.basic_info
																										.plan_end_date
																								"
																								placeholder="{{
																									'UI.k_planned_end_date'
																										| translate
																								}}"
																							></ng2-flatpickr>
																							<div class="input-group-append">
																								<button
																									class="btn btn-outline-secondary feather icon-calendar"
																									(click)="
																										planEndDate.flatpickr.toggle()
																									"
																									type="button"
																									rippleEffect
																								></button>
																							</div>
																						</div>
																					</div>
																				</div>
																				<span
																					*ngIf="startEndDateInvalid"
																					class="col-12 invalid-form"
																				>
																					<small
																						class="form-text text-danger"
																						[innerHtml]="startEndDateToaster"
																					>
																					</small>
																				</span>
																			</div>
																			<div class="form-group">
																				<label for="qlEditPlanning" class="form-label">
																					{{ "UI.k_reason_for_change" | translate }}
																					*</label
																				>
																				<div class="p-0 card border quill-card">
																					<quill-editor
																						[(ngModel)]="
																						process_data.basic_info.planning
																						"
																						id="qlEditPlanning"
																						name="qlEditPlanning"
																						[minLength]="3"
																						[required]="true"
																						#qlEditPlanning
																						[trimOnValidation]="true"
																						[appQuillValidator]="qlEditPlanning"
																						[appQuillForm]="planningForm"
																						[class.error]="
																							qlEditPlanning?.errors &&
																							planningForm.submitted
																						"
																						placeholder="{{
																							'UI.k_add_content' | translate
																						}}"
																					>
																					</quill-editor>
																					<div
																						class="error"
																						*ngIf="
																							planningForm.submitted &&
																							qlEditPlanning?.errors
																						"
																					>
																						<small
																							*ngIf="
																								qlEditPlanning?.errors
																									?.requiredError?.empty
																							"
																						>
																							{{
																								"UI.k_field_required"
																									| translate
																							}}
																						</small>
																						<small
																							*ngIf="
																								qlEditPlanning?.errors
																									?.minLengthError
																							"
																						>
																							{{
																								"Error.err_min_3_character"
																									| translate
																							}}
																						</small>
																					</div>
																				</div>
																			</div>
																			<div class="form-group">
																				<label
																					for="feedbackViewPermissionTo"
																					class="form-label"
																				>
																					{{ "UI.k_rollout" | translate }}
																					{{ "UI.k_plan" | translate }}</label
																				>
																				<div class="p-0 card border quill-card">
																					<quill-editor
																						[(ngModel)]="
																						process_data.basic_info.rollout
																						"
																						id="qlRollOut"
																						name="qlRollBack"
																						#qlRollBack
																						[trimOnValidation]="true"
																						[appQuillValidator]="qlRollBack"
																						[appQuillForm]="planningForm"
																						placeholder="{{
																							'UI.k_add_content' | translate
																						}}"
																					>
																					</quill-editor>
																				</div>
																			</div>
																			<div class="form-group">
																				<label
																					for="feedbackViewPermissionTo"
																					class="form-label"
																				>
																					{{ "UI.k_rollback" | translate }}
																					{{ "UI.k_plan" | translate }}</label
																				>
																				<div class="p-0 card border quill-card">
																					<quill-editor
																						[(ngModel)]="
																						process_data.basic_info.rollback
																						"
																						id="qlRollOut"
																						name="qlRollOut"
																						#qlRollOut
																						[trimOnValidation]="true"
																						[appQuillValidator]="qlRollOut"
																						[appQuillForm]="planningForm"
																						placeholder="{{
																							'UI.k_add_content' | translate
																						}}"
																					>
																					</quill-editor>
																				</div>
																			</div>
																		</div>
																		<ng-container *ngIf="showImpact">
																			<div class="px-1 pb-1">
																				<div class="card border border-lg">
																					<div
																						class="card-hearder p-1 border-bottom d-flex justify-content-between align-items-center"
																					>
																						<span class="title-color">{{ "UI.k_impact" | translate }}</span>
																						<a
																							*ngIf="
																								checkApproveCloseState() &&
																								permissions?.edit
																							"
																							(click)="openImpactSidebar('')"
																							class="text-primary fw-400"
																							>{{ "UI.k_add" | translate }}</a
																						>
																					</div>
																					<div class="p-1">
																						<ng-container
																						*ngFor="
																							let item of impactData;
																							let i = index
																						"
																					>
																						<div class="impact-card">
																							<div
																								class="card border border-lg bg-body p-1"
																							>
																								<div
																									class="d-flex align-items-center justify-content-between mb-50"
																								>
																									<div class="profile d-flex gap-50 align-items-center">
																										<div
																											class="media align-items-center gap-50"
																										>
																											<div
																												class="avatar avatar-sm"
																												*ngIf="
																													item
																														?.creator_profile
																														?.profile_image
																												"
																											>
																												<div
																													class="avatar-content"
																												>
																													<img
																														[src]="
																															item
																																?.creator_profile
																																?.profile_image
																														"
																														alt="avatar"
																														width="100%"
																														height="100%"
																													/>
																												</div>
																											</div>
																											<div
																												*ngIf="
																													!item
																														?.creator_profile
																														?.profile_image
																												"
																												class="avatar avatar-sm"
																												[ngStyle]="{
																													'background-color':
																														item
																															?.creator_profile
																															?.avatar_color
																															? item
																																	?.creator_profile
																																	?.avatar_color
																															: '#f59518'
																												}"
																											>
																												<div
																													class="avatar-content"
																												>
																													{{
																														item
																															?.creator_profile
																															?.full_name
																															| initials
																															| slice
																																: 0
																																: 2
																													}}
																												</div>
																											</div>
																											<div
																												class="media-body"
																											>
																												<span
																													class="mb-0 fw-500 title-color"
																												>
																													{{
																														item
																															?.creator_profile
																															?.full_name
																													}}
																												</span>
																											</div>
																										</div>
																										<div
																											class="badge badge-light-primary"
																										>
																											{{ item?.impact?.name }}
																										</div>
																									</div>
																									<div
																										class="action"
																										*ngIf="
																											checkApproveCloseState() &&
																											permissions?.edit
																										"
																									>
																										<div
																											class="d-flex align-items-center"
																										>
																											<button
																												ngbTooltip="{{
																													'UI.k_edit'
																														| translate
																												}}"
																												placement="top"
																												type="button"
																												rippleeffect=""
																												class="btn btn-sm btn-icon btn-flat-primary waves-effect"
																												(click)="
																													openImpactSidebar(
																														item
																													)
																												"
																											>
																												<i
																													class="fa-light fa-pen-to-square"
																												></i>
																											</button>
																											<button
																												ngbTooltip="{{
																													'UI.k_delete'
																														| translate
																												}}"
																												(click)="
																													deleteImpact(
																														item
																													)
																												"
																												placement="top"
																												type="button"
																												rippleeffect=""
																												class="btn btn-sm btn-icon btn-flat-primary waves-effect text-danger"
																											>
																												<i
																													class="fa-light fa-trash-can"
																												></i>
																											</button>
																										</div>
																									</div>
																								</div>
																								<div
																									class="description"
																								>
																									{{ item?.description }}
																								</div>
																							</div>
																						</div>
																					</ng-container>
																					<ng-container *ngIf="impactData == 0">
																						<div
																							class="d-flex flex-column align-items-center p-1"
																						>
																							<div
																								class="avatar bg-secondary rounded-lg mb-25"
																								id="BtnincIcons"
																							>
																								<div class="avatar-content">
																									<span
																										><i
																											class="fa-light fa-book-open fa-lg"
																										></i
																									></span>
																								</div>
																							</div>
																							<span class="sm-font text-muted">{{
																								"UI.k_no_records_yet" | translate
																							}}</span>
																						</div>
																					</ng-container>
																					</div>
																				</div>
																			</div>
																		</ng-container>
																	</ng-scrollbar>
																</div>
																<div class="flex-initial border-top p-1">
																	<div
																		class="d-flex bd-highlight justify-content-between align-items-center pull-right"
																	>
																		<button
																			*ngIf="
																				!disable_config &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id == 5
																				) &&
																				permissions?.edit
																				&& !is_ssp_login
																			"
																			(click)="saveChangeData(planningForm, false, false, true)"
																			class="btn btn-sm btn-primary pull-right"
																			id="btnSaveChangeData"
																			name="btnSaveChangeData"
																			[attr.data-testid]="'btnSaveChangeData'"
																		>
																			{{ "UI.k_save" | translate }}
																		</button>
																	</div>
																</div>
															</ng-container>
															<ng-container *ngIf="moduleName=='Release'">
																<div class="flex-1">
																	<ng-scrollbar class="custom-scrollbar section-scrollbar grid-full-height" #scrollable>
																		<div class="px-1 pt-1">
																			<div class="d-flex wrap">
																				<div class="p-0">
																					<h5>{{ "UI.k_planning" | translate }}</h5>
																				</div>
																			</div>
																			<div class="row">
																				<div class="col-sm-6">
																					<div
																						class="form-group custom-date-picker custom-input-group"
																					>
																						<label
																							for="dtDueDate"
																							class="form-label"
																						>
																							{{
																								"UI.k_planned_start_date"
																									| translate
																							}}</label
																						>
																						<div class="input-group">
																							<ng2-flatpickr
																								#planStartDate
																								[config]="plnStartDateOptions"
																								name="plnStartDate"
																								[setDate]="
																									process_data.basic_info
																										.plan_start_date
																								"
																								id="plnStartDate "
																								[disabled]="disable_config || is_ssp_login"
																								[(ngModel)]="
																									process_data.basic_info
																										.plan_start_date
																								"
																								placeholder="{{
																									'UI.k_planned_start_date'
																										| translate
																								}}"
																							></ng2-flatpickr>
																							<div class="input-group-append">
																								<button
																									class="btn btn-outline-secondary feather icon-calendar"
																									(click)="
																										planStartDate.flatpickr.toggle()
																									"
																									type="button"
																									rippleEffect
																								></button>
																							</div>
																						</div>
																					</div>
																				</div>
																				<div class="col-sm-6">
																					<div
																						class="form-group custom-date-picker custom-input-group"
																					>
																						<label
																							for="dtDueDate"
																							class="form-label"
																						>
																							{{
																								"UI.k_planned_end_date"
																									| translate
																							}}</label
																						>
																						<div class="input-group">
																							<ng2-flatpickr
																								#planEndDate
																								[config]="plnEndDateOptions"
																								name="plnEndDate"
																								[setDate]="
																									process_data.basic_info
																										.plan_end_date
																								"
																								id="plnEndDate "
																								[disabled]="disable_config || is_ssp_login"
																								[(ngModel)]="
																									process_data.basic_info
																										.plan_end_date
																								"
																								placeholder="{{
																									'UI.k_planned_end_date'
																										| translate
																								}}"
																							></ng2-flatpickr>
																							<div class="input-group-append">
																								<button
																									class="btn btn-outline-secondary feather icon-calendar"
																									(click)="
																										planEndDate.flatpickr.toggle()
																									"
																									type="button"
																									rippleEffect
																								></button>
																							</div>
																						</div>
																					</div>
																				</div>
																				<span
																					*ngIf="startEndDateInvalid"
																					class="col-12 invalid-form"
																				>
																					<small
																						class="form-text text-danger"
																						[innerHtml]="startEndDateToaster"
																					>
																					</small>
																				</span>
																			</div>
																			<div class="form-group">
																				<label for="qlBuildTest" class="form-label">
																					{{
																						"UI.k_build_plan" | translate
																					}}&nbsp;*</label
																				>
																				<div class="p-0 card border quill-card">
																					<quill-editor
																						[(ngModel)]="
																							process_data.basic_info.build_plan
																						"
																						id="qlBuildTest"
																						name="qlBuildTest"
																						#qlBuildTest
																						[trimOnValidation]="true"
																						[appQuillValidator]="qlBuildTest"
																						[appQuillForm]="planningForm"
																						[minLength]="3"
																						[required]="true"
																						[class.error]="
																							qlBuildTest?.errors && planningForm.submitted
																							"
																						placeholder="{{
																							'UI.k_add_content' | translate
																						}}"
																					>
																						<div
																							quill-editor-toolbar
																							class="border-0 border-bottom px-0"
																							style="padding: 0px"
																						>
																							<div
																								class="border-bottom editor-item-flex"
																							>
																								<span
																									class="ql-formats"
																									style="
																										margin: 0;
																										border-right: 1px solid
																											#e5e6f0;
																									"
																								>
																									<select class="ql-header">
																										<option value="1">
																											Heading 1
																										</option>
																										<option value="2">
																											Heading 2
																										</option>
																										<option value="3">
																											Heading 3
																										</option>
																										<option value="">
																											Normal
																										</option>
																									</select>
																								</span>
																								<span
																									class="ql-formats"
																									style="
																										margin: 0;
																										border-right: 1px solid
																											#e5e6f0;
																									"
																								>
																									<button
																										id="qlBoldNoteEmail-Inc"
																										name="qlBold"
																										class="ql-bold"
																										title="{{
																											'UI.k_bold'
																												| translate
																										}}"
																									></button>
																								</span>
																								<span
																									class="ql-formats"
																									style="
																										margin: 0;
																										border-right: 1px solid
																											#e5e6f0;
																									"
																								>
																									<button
																										class="ql-align"
																										value=""
																									></button>
																									<button
																										class="ql-align"
																										value="center"
																									></button>
																									<button
																										class="ql-align"
																										value="right"
																									></button>
																									<button
																										class="ql-align"
																										value="justify"
																									></button>
																								</span>
																							</div>
																						</div>
																					</quill-editor>
																					<div
																						class="error"
																						*ngIf="
																							planningForm.submitted &&
																							qlBuildTest?.errors
																						"
																					>
																						<small
																							*ngIf="
																								qlBuildTest?.errors
																									?.requiredError?.empty
																							"
																						>
																							{{
																								"UI.k_field_required"
																									| translate
																							}}
																						</small>
																						<small
																							*ngIf="
																								qlBuildTest?.errors
																									?.minLengthError
																							"
																						>
																							{{
																								"Error.err_min_3_character"
																									| translate
																							}}
																						</small>
																					</div>
																				</div>
																			</div>
																			<div class="form-group">
																				<label for="qlTestPlan" class="form-label">
																					{{
																						"UI.k_test_plan" | translate
																					}}&nbsp;*</label
																				>
																				<div class="p-0 card border quill-card">
																					<quill-editor
																						[(ngModel)]="
																							process_data.basic_info.test_plan
																						"
																						id="qlTestPlan"
																						name="qlTestPlan"
																						#qlTestPlan
																						[trimOnValidation]="true"
																						[appQuillValidator]="qlTestPlan"
																						[appQuillForm]="planningForm"
																						placeholder="{{
																							'UI.k_add_content' | translate
																						}}"
																						[class.error]="
																							qlTestPlan?.errors && planningForm.submitted
																							"
																						[minLength]="3"
																						[required]="true"
																					>
																						<div
																							quill-editor-toolbar
																							class="border-0 border-bottom px-0"
																							style="padding: 0px"
																						>
																							<div
																								class="border-bottom editor-item-flex"
																							>
																								<span
																									class="ql-formats"
																									style="
																										margin: 0;
																										border-right: 1px solid
																											#e5e6f0;
																									"
																								>
																									<select class="ql-header">
																										<option value="1">
																											Heading 1
																										</option>
																										<option value="2">
																											Heading 2
																										</option>
																										<option value="3">
																											Heading 3
																										</option>
																										<option value="">
																											Normal
																										</option>
																									</select>
																								</span>
																								<span
																									class="ql-formats"
																									style="
																										margin: 0;
																										border-right: 1px solid
																											#e5e6f0;
																									"
																								>
																									<button
																										id="qlBoldNoteEmail-Inc"
																										name="qlBold"
																										class="ql-bold"
																										title="{{
																											'UI.k_bold'
																												| translate
																										}}"
																									></button>
																								</span>
																								<span
																									class="ql-formats"
																									style="
																										margin: 0;
																										border-right: 1px solid
																											#e5e6f0;
																									"
																								>
																									<button
																										class="ql-align"
																										value=""
																									></button>
																									<button
																										class="ql-align"
																										value="center"
																									></button>
																									<button
																										class="ql-align"
																										value="right"
																									></button>
																									<button
																										class="ql-align"
																										value="justify"
																									></button>
																								</span>
																							</div>
																						</div>
																					</quill-editor>
																					<div
																						class="error"
																						*ngIf="
																							planningForm.submitted &&
																							qlTestPlan?.errors
																						"
																					>
																						<small
																							*ngIf="
																								qlTestPlan?.errors
																									?.requiredError?.empty
																							"
																						>
																							{{
																								"UI.k_field_required"
																									| translate
																							}}
																						</small>
																						<small
																							*ngIf="
																								qlTestPlan?.errors
																									?.minLengthError
																							"
																						>
																							{{
																								"Error.err_min_3_character"
																									| translate
																							}}
																						</small>
																					</div>
																				</div>
																			</div>
																		</div>
																	</ng-scrollbar>
																</div>
																<div class="flex-initial border-top p-1">
																	<div
																		class="d-flex bd-highlight justify-content-between align-items-center pull-right"
																	>
																		<button
																			*ngIf="
																				!disable_config &&
																				!(
																					process_data?.is_exit &&
																					process_data?.basic_info?.status
																						?.state_id == 5
																				) &&
																				permissions?.edit
																				&& !is_ssp_login
																			"
																			(click)="saveReleaseData(planningForm)"
																			class="btn btn-sm btn-primary pull-right mb-1"
																			id="btnDeploy"
																			name="btnDeploy"
																			[attr.data-testid]="'btnDeploy'"
																		>
																			<b>{{ "UI.k_deploy" | translate }}</b>
																		</button>
																	</div>
																</div>																
															</ng-container>
														</div>
													</form>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'risk'">
												<div class="flex-1">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar grid-full-height">
															<div class="main-sec flex-auto-height">
																<div class="action-bar flex-initial px-1 py-50 position-relative">
																	<div class="inner-section d-flex align-items-center justify-content-between">
																		<div class="left-section">
																			<span
																				class="mb-0 fw-600 title-color text-break page-title"
																			>
																				{{ "UI.k_risk_involved" | translate }}
																		</span>
																		</div>
																		<div class="right-section d-flex align-items-center gap-50">
																			<div class="action-search"  [ngClass]="isExpandSearchWidth ? 'expandedSearch position-absolute' : ''">
																				<ng-container
																					*ngIf="
																						riskchangeData?.length > 0 || filterData
																					"
																				>
																					<app-search
																						[showDataTable]="showDataTable"
																						[columnOptions]="columnOptions"
																						[conditionOptions]="conditionOptions"
																						[showRightsection]="showRightsection"
																						(getfilterData)="
																							getFilterSearch($event)
																						"
																						[savedData]="savedData"
																						[Options]="dropdown"
																						[module]="'risk'"
																						(isExpandSearchWidth)="getSearchWidth($event)"
																						(genericFilter)="
																							genericSearchFilter($event)
																						"
																					></app-search>
																				</ng-container>
																			</div>
																			<button
																				*ngIf="
																					permissions?.edit &&
																					!(
																						process_data?.is_exit &&
																						process_data?.basic_info?.status
																							?.state_id == 5
																					) &&
																					!disable_config
																					&& !is_ssp_login
																				"
																				class="btn btn-primary pull-right"
																				rippleEffect
																				id="btnAddRisk"
																				name="btnAddRisk"
																				tooltipClass="fadeInAnimation"
																				placement="left"
																				(click)="toggleRiskSideBar('risk-sidebar')"
																				[attr.data-testid]="'btnAddRisk'"
																			>
																				<i class="fa-light fa-plus"></i>
																				<span class="d-none d-sm-inline-block">
																					{{ "UI.k_new_risk" | translate }}
																				</span>
																			</button>
																		</div>
																	</div>
																</div>
																 <div class="action-section flex-1">
																	<ng-container *ngIf="riskchangeData?.length > 0">
																		<div class="table-view d-grid full-height">
																			<dns-server-grid
																				[rows]="riskchangeData"
																				[columns]="risk_columns"
																				[options]="dropdown"
																				(page)="setRiskPage($event)"
																				[count]="riskCount"
																				(sort)="onRiskSort($event)"
																				(setlimit)="setRiskLimit($event)"
																				[limit]="riskLimit"
																				class="bootstrap core-bootstrap "
																				[permissions]="permissions"
																				[scrollbarH]="true"
																				[offset]="riskPageNumber"
																			>
																			</dns-server-grid>
																		</div>
																	</ng-container>
																	<ng-container
																	*ngIf="
																		(riskchangeData?.length == 0 || riskchangeData == undefined) &&
																		!is_risk_loading &&
																		!filterData
																	"
																>
																	<div
																		class="d-flex flex-column align-items-center"
																		style="padding: 10rem"
																	>
																		<div
																			class="avatar bg-secondary rounded-lg mb-25"
																			id="BtnincIcons"
																		>
																			<div class="avatar-content">
																				<span><i class="fa-light fa-book-open fa-lg"></i></span>
																			</div>
																		</div>
																		<span class="sm-font text-muted">{{
																			"UI.k_no_records_yet" | translate
																		}}</span>
																	</div>
																</ng-container>
																<ng-container *ngIf="is_risk_loading">
																	<div
																		class="d-flex flex-column align-items-center"
																	>
																		<div
																			class="spinner-border text-primary"
																			aria-hidden="true"
																		></div>
																	</div>
																</ng-container>
																<div
																	*ngIf="
																		riskchangeData?.length == 0 &&
																		showData &&
																		filterData &&
																		!is_risk_loading
																	"
																	style="padding: 10rem"
																>
																	<app-dns-empty-grid-data
																		[noFilterData]="true"
																	></app-dns-empty-grid-data>
																</div>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									class="modal-content-footer flex-initial"
									*ngIf="selectedItemName !== 'communication_tab' && permissions?.edit && process_data_copy?.basic_info?.status?.state_id !== 5"
								>
									<ng-container *ngIf="process_data_copy?.basic_info?.status?.state_id === 4 || moduleName !== 'Change'">
										<div class="d-flex justify-content-end border-top px-1 py-1">
											<!-- <div ngbDropdown [placement]="'top-right'" class="btn-group mr-1 pr-1 border-right">
												<button
													ngbDropdownToggle
													type="button"
													class="btn btn-flat-primary btn-sm"
													rippleEffect
												>
													{{ "UI.k_more_action" | translate }}
												</button>
												<div ngbDropdownMenu>
													<a ngbDropdownItem href="javascript:void(0)">{{
														"UI.k_convert_in_to_problem" | translate
													}}</a>
													<a ngbDropdownItem href="javascript:void(0)">{{
														"UI.k_convert_in_to_request" | translate
													}}</a>
												</div>
											</div> -->
											<span
											class="txt-primary fw-500"
											*ngIf="
												!process_data_copy?.approval_data?.can_approve && !process_data_copy?.approval_data?.is_approved && 
												process_data_copy?.basic_info?.state?.id === 6 && (process_data_copy?.is_submitter?.is_submitter || process_data_copy?.approval_data?.is_submitter)
												&& !is_ssp_login
											"
											>{{ "UI.k_waiting_for_approval" | translate }}</span
											>
											<span
												class="txt-primary fw-500"
												*ngIf="
													!process_data_copy?.approval_data?.can_review &&
													process_data_copy?.is_review_submitter?.is_review_submitter &&
													([7].includes(process_data_copy?.basic_info?.state?.id) ||
													process_data_copy?.basic_info?.status?.is_pir) 
													&& !is_ssp_login
												"
												>{{ "UI.k_waiting_for_review" | translate }}</span
											>
											<button
											type="button"
											(click)="onApproval(process_data_copy)"
											class="btn btn-primary btn-sm"
											*ngIf="
											process_data_copy?.approval_data?.can_approve && process_data_copy?.basic_info?.state?.id === 6 && moduleName !== 'work-space' && !is_ssp_login
											"
												rippleEffect
											>
												<span class="align-middle">{{ "UI.k_approve" | translate }}</span>
											</button>
											<button
												type="button"
												(click)="onResolve($event, 'resolve')"
												class="btn btn-primary btn-sm"
												*ngIf="process_data_copy?.basic_info?.status?.state_id < 4 && moduleName !== 'Change' && moduleName !== 'Release' && !is_ssp_login"
												rippleEffect
											>
												<span class="align-middle">{{ "UI.k_resolve" | translate }}</span>
											</button>
											<button
												type="button"
												(click)="onResolve($event, 'close')"
												class="btn btn-primary btn-sm"
												*ngIf="process_data_copy?.basic_info?.status?.state_id === 4 && moduleName !== 'Change' && moduleName !== 'Release' && !is_ssp_login"
												rippleEffect
											>
												<span class="align-middle">{{ "UI.k_complete" | translate }}</span>
											</button>
											<ng-container *ngIf="process_data_copy?.basic_info?.status?.state_id === 4 && (moduleName === 'Incident' || panelType === 'Request') && is_ssp_login">
												<button
													type="button"
													(click)="toggleSSPCloseTicketSidebar('close')"
													class="btn btn-primary btn-sm mr-1"
													rippleEffect
												>
													<span class="align-middle">{{ "UI.k_works_for_me" | translate }}</span>
												</button>
												<button
													type="button"
													(click)="toggleSSPCloseTicketSidebar('reopen')"
													class="btn btn-danger btn-sm"
													rippleEffect
												>
													<span class="align-middle">{{ "UI.k_reopen" | translate }}</span>
												</button>
											</ng-container>

										</div>
									</ng-container>
								</div>
							</div>
							<div class="flex-auto-height border-left bg-body">
								<ng-container *ngIf="moduleName === 'Incident' || moduleName === 'Request' || moduleName === 'Problem'">
									<div class="flex-initial border-bottom pt-1 stage-representation">
										<!-- <app-stage-representation
						[inputConfig]="stageInputData"
						class="progress-time"
					  ></app-stage-representation> -->
										<app-dns-stage-representation
											class="progress-time"
											[inputConfig]="copyOfstageInputData"
											[disableConfig]="disable_config || !permissions?.edit || is_ssp_login"
											(onActionCallback)="actionCallback($event)"
										></app-dns-stage-representation>
									</div>
								</ng-container>
								<div class="flex-1">
									<ng-scrollbar class="custom-scrollbar section-scrollbar">
										<div class="p-1 border-bottom">
											<div class="d-flex mb-50 align-items-center justify-content-between">
												<div class="d-flex gap-1 align-items-center">
													<span class="title-color fw-500">
														{{ "UI.k_requester" | translate }}
													</span>
													<!-- <button
													type="button"
													id="btnEditRequester"
													name="btnEditRequester"
													[attr.data-testid]="'btnEditRequester'"
													(click)="editRequester(process_data?.requester)"
													class="btn btn-sm btn-icon btn-flat-primary"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													rippleEffect
													>
														<i class="fa-light fa-pen-to-square"></i>
													</button> -->
													<button class="btn btn-sm btn-icon btn-flat-primary waves-effect ng-star-inserted"
													*ngIf="
														!process_data?.requester?.is_deleted &&
														!(
															process_data?.is_exit
														) &&
														permissions?.edit &&
														process_data?.basic_info?.status?.state_id == 1 &&
														disable_config == false && moduleName === 'Incident'
													"
														>
														<a
															(click)="changeRequester(true)"
															placement="top"
															container="body"
															ngbTooltip="{{ 'UI.K_change_requester' | translate }}"
															><i class="fw-500 lg-font fa-light fa-arrows-rotate primary-color"></i
														></a>
															</button>
												</div>
											</div>
											<div class="d-flex justify-content-start align-items-center sidebar-panel-card" 
											[ngClass]="disable_config || !permissions?.edit || is_ssp_login? 'no-event' : 'cursor-pointer'"
											(click)="editRequester(process_data?.requester)">
												<div class="avatar mr-50" *ngIf="process_data?.requester_profile" >
													<img
														alt="avatar img"
														height="32"
														width="32"
														[src]="process_data?.requester_profile"
													/>
												</div>
												<div *ngIf="!process_data?.requester_profile" class="avatar mr-50">
													<span
														class="avatar-content"
														[ngStyle]="{
															'background-color':
																process_data?.requester?.avatar_color !== undefined
																	? process_data?.requester?.avatar_color
																	: '#f59518'
														}"
														>{{
															this.process_data?.requester?.full_name
																| initials
																| slice: O:2
														}}</span
													>
												</div>
												<div class="profile-user-info">
													<div class="mb-0 sm-font title-color fw-500 line-height-1">
														{{
															process_data?.requester?.full_name?.length > 15
																? (process_data?.requester?.full_name | slice: 0:15) +
																  "..."
																: process_data?.requester?.full_name
														}}
													</div>
													<small class="text-muted sm-font line-height-1">{{
														process_data?.requester?.email
													}}</small>
												</div>
											</div>
											<div class="mt-75">
												<div>
													<span class="xs-font">{{ "UI.k_contact_number" | translate }}</span>
												</div>
												<div class="d-flex gap-1 align-items-center">
													<span
														*ngIf="this.process_data?.requester?.contact_number"
														class="title-color sm-font fw-500"
													>
														{{ this.process_data?.requester?.contact_number }}
													</span>
													<span *ngIf="!this.process_data?.requester?.phone_number">-</span>
													<ng-container *ngIf="moduleName == 'Incident'">
														<app-infraon-call
															[requester]="process_data?.requester"
															[requester_profile]="process_data?.requester_profile"
														>
														</app-infraon-call>
													</ng-container>
												</div>
											</div>
										</div>
										<div class="p-1 border-bottom">
											<div class="d-flex mb-50 align-items-center justify-content-between">
												<div class="d-flex gap-1 align-items-center">
													<span class="title-color fw-500">{{ "UI.k_assignee" | translate }}</span>
													<ng-container 
														*ngIf="!disable_config && permissions?.edit 
															&& !extra_info.selected_assignee_info?.group_id
															&& !is_ssp_login
														"
													>
														<button
														type="button"
														id="btnCurrentAssignee"
														name="btnCurrentAssignee"
														[attr.data-testid]="'btnCurrentAssignee'"
														(click)="openModalAD(CurrentAssigneeComp)"
														class="btn btn-sm btn-icon btn-flat-primary"
														tooltipClass="fadeInAnimation"
														ngbTooltip="{{ 'UI.k_add' | translate }}"
														rippleEffect
														>
															<i class="fw-500 lg-font fa-light fa-plus"></i>
														</button>
													</ng-container>
												</div>
												<button
												*ngIf="
													process_data_copy?.current_assignment_info?.level &&
													process_data_copy?.current_assignment_info?.assignee &&
													process_data_copy?.is_escalation_configured
												"
												type="button"
												id="btnExpectedAssingee"
												name="btnExpectedAssingee"
												[attr.data-testid]="'btnExpectedAssingee'"
												(click)="expectedAssingee('expected-assingee-sidebar')"
												class="btn btn-sm btn-icon btn-flat-primary cursor-pointer"
												tooltipClass="fadeInAnimation"
												placement="left"
												ngbTooltip="{{ 'UI.k_team_escalation' | translate }}"
												rippleEffect
											>
												<i class="fa-light fa-users"></i>
											</button>
											</div>
											<div
												class="d-flex justify-content-start align-items-center sidebar-panel-card"
												*ngIf="extra_info.selected_assignee_info?.full_name" 
												[ngClass]="disable_config || !permissions?.edit || is_ssp_login? 'no-event' : 'cursor-pointer'" 
												(click)="openModalAD(CurrentAssigneeComp)"
											>
												<div
													class="avatar mr-50"
													*ngIf="extra_info.selected_assignee_info?.profile_image"
												>
													<img
														alt="avatar img"
														height="32"
														width="32"
														[src]="extra_info.selected_assignee_info?.profile_image"
													/>
												</div>
												<div
													*ngIf="!extra_info.selected_assignee_info?.profile_image"
													class="avatar mr-50"
													[ngStyle]="{
														'background-color': process_data?.requester_avatar_color
													}"
												>
													<span
														class="avatar-content"
														[ngStyle]="{
															'background-color':
																extra_info.selected_assignee_info?.profile
																	?.avatar_color !== undefined
																	? extra_info.selected_assignee_info?.profile
																			?.avatar_color
																	: '#f59518'
														}"
													>
														{{
															this.extra_info.selected_assignee_info?.full_name
																| initials
																| slice: O:2
														}}</span
													>
												</div>
												<div class="profile-user-info">
													<div
														class="mb-0 sm-font title-color fw-500 line-height-1"
														*ngIf="
															this.extra_info.selected_assignee_info?.full_name?.length <
															20
														"
													>
														{{ extra_info.selected_assignee_info?.full_name }}
													</div>
													<div
														class="mb-0 sm-font title-color fw-500 line-height-1"
														*ngIf="
															this.extra_info.selected_assignee_info?.full_name?.length >
															20
														"
													>
														{{
															(this.extra_info.selected_assignee_info?.full_name
																| slice: 0:18) + "..."
														}}
													</div>
													<span class="text-muted sm-font line-height-1">{{
														extra_info.selected_assignee_info?.email
													}}</span>
												</div>
											</div>
											<div class="d-flex mt-1 align-items-baseline justify-content-between">
												<div class="mr-50">
													<span class="xs-font">{{ "UI.k_group" | translate }}</span>
													<div>
														<span
															*ngIf="this.extra_info?.group_name"
															class="sm-font title-color fw-500"
														>
															{{ this.extra_info?.group_name }}
														</span>
														<span *ngIf="!this.extra_info?.group_name">-</span>
													</div>
												</div>
												<div class="mr-50">
													<div>
														<span class="xs-font">{{ "UI.k_level" | translate }}</span>
													</div>
													<div>
														<span
															*ngIf="this.extra_info?.level"
															class="sm-font title-color fw-500"
														>
															{{ this.extra_info?.level }}
														</span>
														<span *ngIf="!this.extra_info?.level">-</span>
													</div>
												</div>
												<div class="">
													<div>
														<span class="xs-font">{{ "UI.k_expertise" | translate }}</span>
													</div>
													<div>
														<span
															*ngIf="this.extra_info?.expertise"
															class="sm-font title-color fw-500"
														>
															{{ this.extra_info?.expertise }}
														</span>
														<span *ngIf="!this.extra_info?.expertise">-</span>
													</div>
												</div>
											</div>
										</div>
										<ng-container
											*ngIf="process_data?.sla_profiles && process_data.sla_profiles.length > 0"
										>
											<div class="p-1 border-bottom">
												<div class="mb-50 fw-500 title-color">
													{{ "UI.k_sla" | translate }} {{ "UI.k_details" | translate }}
												</div>
												<div class="sla-info-container">
													<div
														class="sla-info-item"
														[ngClass]="isLast === true ? '' : 'border-bottom pb-1'"
														*ngFor="let item of process_data?.sla_profiles; last as isLast"
													>
														<div class="sla-info-header">
															<label class="sm-font fw-500 mb-50 title-color">{{
																item?.profile
															}}</label>
														</div>
														<div class="d-flex">
															<div class="w-50 inc-edit-prog">
																<app-dns-progress-timer-component
																	[metricData]="item?.metrices[0]"
																	[remaining_time]="item?.metrices[0]?.remaining_time"
																	[elapsed_percentage]="
																		item?.metrices[0]?.elapsed_percentage
																	"
																	[startTime]="item?.metrices[0]?.start_time"
																	[expectedEndTime]="
																		item?.metrices[0]?.expected_end_time
																	"
																	[endTime]="item?.metrices[0]?.end_time"
																	[isBreached]="item?.metrices[0]?.is_breached"
																	[timerClass]="'incident-edit'"
																></app-dns-progress-timer-component>
															</div>
															<div class="sla-info-item-divider border-right"></div>
															<div class="w-50 matric-slider-progress">
																<label class="mat-sl-label">{{
																	item?.metrices[0]?.metric_name
																}}</label>
																<div class="progress_bar_time">
																	<label>{{
																		item?.metrices[0]?.remaining_time
																	}}</label>
																	<label class="percent"
																		>{{
																			item?.metrices[0]?.elapsed_percentage
																		}}%</label
																	>
																</div>
																<div class="progress-wrapper">
																	<ngb-progressbar
																		height=".25rem"
																		class="progress_bar"
																		[type]="
																			getColorName(
																				item?.metrices[0]?.elapsed_percentage
																			)
																		"
																		[value]="item?.metrices[0]?.elapsed_percentage"
																		id="ProgressResponseTime"
																	></ngb-progressbar>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</ng-container>
										<ng-container *appDnsLicenseHandler="{ module_key: 'ASSET', keys: ['ASSET_MANAGEMENT'] }">
											<div
												class="card bg-body m-1"
												*ngIf="process_data?.impacted_asset?.length < 1 && 
														!(
															process_data?.is_exit &&
															process_data?.basic_info?.status?.state_id == 5
														) &&
														permissions?.edit &&
														!disable_config &&
														module_id === 10 && 
														!is_ssp_login
													"
											>
												<div class="d-flex justify-content-between align-items-center mb-50">
													<span class="sm-font fw-500 title-color">{{
														"UI.k_impacted_asset" | translate
													}}</span>
													<div class="d-flex align-items-center">
														<button
															type="button"
															id="btnAddAsset"
															name="btnAddAsset"
															[attr.data-testid]="'btnAddAsset'"
															(click)="addAsset($event)"
															class="btn btn-xs btn-flat-primary waves-effect"
															rippleEffect
														>
															<i class="fa-light fa-plus"></i>
															{{ "UI.k_add_asset" | translate }}
														</button>
													</div>
												</div>
											</div>	
										</ng-container>
										<div *ngIf="process_data?.impacted_asset?.length > 0" class="p-1">
											<div class="d-flex mb-50 gap-1 align-items-center">
												<span class="title-color fw-500">
													{{ "UI.k_impacted_asset" | translate }}
												</span>
												<div class="d-flex align-items-center">
													<button
														type="button"
														id="btnAssetSwitch"
														name="btnAssetSwitch"
														[attr.data-testid]="'btnAssetSwitch'"
														(click)="addAsset($event)"
														[ngbTooltip]="'UI.k_change_asset' | translate"
														class="btn btn-sm btn-icon btn-flat-primary waves-effect"
														*ngIf="module_id === 10 && !is_ssp_login"
														rippleEffect
													>
													<i class="fa-light fa-arrow-right-arrow-left"></i>
													</button>
												</div>
											</div>
											<div class="d-flex justify-content-start align-items-center cursor-pointer" (click)="info_action()">
												<ng-container *ngIf="
												process_data?.impacted_asset[0]?.device_type &&
													getImageSrc(process_data?.impacted_asset[0]?.device_type, 'device_type') !== ''
												">
												<div class="avatar asset-avatar bg-light-primary mr-50">
													<span
														container="body"
														class="avatar-content"
														placement="left"
														ngbTooltip="{{ 'UI.k_device_type' | translate }}-{{
															process_data?.impacted_asset[0]?.device_type
														}}"
													>
														<img
															class="thumbnail-image"
															[src]="getImageSrc(process_data?.impacted_asset[0]?.device_type, 'device_type')"
														/>
													</span>
												</div>
												</ng-container>
												<div class="profile-user-info">
													<div class="mb-0 fw-500 sm-font title-color">{{ process_data?.impacted_asset[0]?.ci_name}} </div>
													<small *ngIf="process_data?.impacted_asset[0]?.ip_address?.length > 0" class="text-muted sm-font line-height-1">{{ process_data?.impacted_asset[0]?.ip_address[0] }}</small>
												</div>
											</div>
											<div class="d-flex mt-1 align-items-baseline justify-content-between">
												<ng-container
													*ngFor="
														let cmdb_field of process_data?.impacted_asset[0]
															?.customized_search_fields;
														let cmdbIndx = index
													"
												>
													<div class="mr-50">
														<span class="xs-font">{{ cmdb_field?.label | translate }}</span>
														<div>
															<span class="sm-font title-color fw-500">
																{{cmdb_field?.custom_field_id ? process_data?.impacted_asset[0]?.[cmdb_field?.custom_field_id] :process_data?.impacted_asset[0]?.[cmdb_field?.key] }}
															</span>
															<span
																class="sm-font title-color fw-500"
																*ngIf="
																	!process_data?.impacted_asset[0]?.[cmdb_field?.custom_field_id ? cmdb_field?.custom_field_id : cmdb_field?.key]
																"
																>-</span
															>
														</div>
													</div>
												</ng-container>
												<!-- <div class="mr-50">
													<span class="xs-font">{{ "UI.k_asset_id" | translate }}</span>
													<div>
														<span class="sm-font title-color"> 12343132 </span>
													</div>
												</div>
												<div class="mr-50">
													<div>
														<span class="xs-font">{{ "UI.k_make" | translate }}</span>
													</div>
													<div>
														<span class="sm-font title-color">
															<img
																src="assets/images/asset/brand/dell.png"
																alt="icon"
																height="14"
																width="14"
																class="mr-25"
															/>
															Dell
														</span>
													</div>
												</div>
												<div class="">
													<div>
														<span class="xs-font">{{ "UI.k_os_short" | translate }}</span>
													</div>
													<div>
														<span class="sm-font title-color">
															<img
																src="assets/images/asset/os/windows.png"
																alt="icon"
																height="14"
																width="14"
																class="mr-25"
															/>
															windows
														</span>
													</div>
												</div> -->
											</div>
										</div>
									</ng-scrollbar>
								</div>
							</div>
						</div>
						<!-- <div class="modal-content-footer flex-initial" *ngIf="selectedItemName !== 'communication_tab'">
							<div class="d-flex justify-content-end border-top px-1 py-1">
								<div ngbDropdown [placement]="'top-right'" class="btn-group mr-1 pr-1 border-right">
									<button
										ngbDropdownToggle
										type="button"
										class="btn btn-flat-primary btn-sm"
										rippleEffect
									>
										{{ "UI.k_more_action" | translate }}
									</button>
									<div ngbDropdownMenu>
										<a ngbDropdownItem href="javascript:void(0)">{{
											"UI.k_convert_in_to_problem" | translate
										}}</a>
										<a ngbDropdownItem href="javascript:void(0)">{{
											"UI.k_convert_in_to_request" | translate
										}}</a>
									</div>
								</div>
								<button
									type="button"
									(click)="incidentDetailSidebar('incident-view-sidebar')"
									class="btn btn-primary btn-sm"
									rippleEffect
								>
									<span class="align-middle">{{ "UI.k_resolve" | translate }}</span>
								</button>
							</div>
						</div> -->
					</div>
					<div *ngIf="!dataLoaded" class="d-flex justify-content-center align-items-center height-400">
						<div class="spinner-border text-primary" aria-hidden="true"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Add Requester Modal -->
	<div class="modal-size-sm d-inline-block">
		<ng-template #modalReq let-modal>
			<form #addIncidentForm="ngForm" class="flex-auto-height">
				<div class="modal-header border-bottom flex-initial">
					<div class="text-center w-100">
						<h5 class="fw-600 mb-0" id="myModalLabel1">
							{{ "UI.k_add_search_change_requester" | translate }}
						</h5>
					</div>
				</div>
				<div class="modal-body body-height-80 p-0 flex-1 flex-auto-height" tabindex="0" ngbAutofocus *ngIf="!isShownRequester">
					<div class="search-input-sec flex-initial">
						<div class="py-1 px-2">
							<div class="input-group input-group-merge">
								<div class="input-group-prepend">
									<span class="input-group-text text-muted-color" id="basic-addon-search2"
										><i class="fa-light fa-magnifying-glass"></i
									></span>
								</div>
								<input
									type="text"
									[(ngModel)]="requesterSearchStr"
									name="txtInciRequesterSearch"
									id="txtInciRequesterSearch"
									class="form-control"
									[attr.data-testid]="'txtInciRequesterSearch'"
									placeholder="{{ 'UI.k_search_requester' | translate }}"
									minlength="3"
									maxlength="100"
									(ngModelChange)="searchRequester(requesterSearchStr, 'change')"
								/>
							</div>
							<div *ngIf="searchFalse">
								<div class="text-center sm-font mt-50">
									{{ "UI.k_search_validation" | translate }}
								</div>

								<div class="no-data">
									<img
										class="rounded"
										src="assets/images/empty-requester.svg"
										alt="search-bg"
										width="146"
										height="86"
									/>

									<div class="text-center">
										{{ "UI.k_search_here" | translate }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="search-list-sec flex-1">
						<div
							*ngIf="requesterList.length == 0 && searcRequestLen > 2"
							class="no-data d-flex flex-column align-items-center"
						>
							<div class="font-small-4 fw-500">
								{{ "UI.k_requester_found" | translate }}
							</div>
							<button
								type="button"
								class="btn btn-primary mt-1"
								rippleEffect
								id="btnAddNewReq"
								name="btnAddNewReq"
								[attr.data-testid]="'btnAddNewReq'"
								*ngIf="requester_permissions?.add"
								(click)="addRequester('add-requester-profile')"
							>
								<span><i class="fa-light fa-plus"></i></span>
								<span> {{ "UI.k_add_new_requester" | translate }}</span>
							</button>
						</div>

						<div *ngIf="requesterSearchStr && requesterList" class="comment requester-lists">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<ng-container *ngFor="let requester of requesterList; let i = index">
									<div
										class="cursor-pointer single-requester"
										id="divrequester_{{ i }}"
										name="divrequester_{{ i }}"
										attr.data-testid="divrequester_{{ i }}"
										(click)="shownRequester(requester, addIncidentForm)"
									>
										<div class="align-items-center p-2">
											<div class="media">
												<div class="avatar" id="divImg">
													<div class="avatar-content" *ngIf="requester?.profile_image">
														<img
															[src]="requester?.profile_image"
															alt="Avatar"
															width="40"
															height="40"
														/>
													</div>
													<div
														*ngIf="!requester?.profile_image"
														class="avatar-content"
														[ngStyle]="{
															'background-color': requester?.avatar_color
																? requester?.avatar_color
																: '#f59518'
														}"
													>
														{{ requester?.full_name | initials | slice: O:2 }}
													</div>
												</div>
												<div class="media-body">
													<div class="d-flex justify-content-between w-100">
														<div class="mb-0" id="divspCard" name="divspCard" [attr.data-testid]="'divspCard'">
															<div class="title-color">
																{{ requester?.full_name }}
															</div>
															<div class="truncated sm-font">
																<span class="">{{ requester?.email }}</span>
															</div>
														</div>
														<div
															*ngIf="
																requester?.phone_number?.internationalNumber &&
																!requester?.impacted_asset?.customized_search_fields
															"
															class="mb-0"
															id="divspCard"
															name="divspCard"
															[attr.data-testid]="'divspCard'"
															class="d-flex align-items-center sm-font"
														>
															<span class="mr-1">
																<i class="fa-light fa-phone"></i>
															</span>
															<span class="truncated">{{
																requester?.phone_number?.internationalNumber
															}}</span>
														</div>
														<div>
															<div
															*ngFor="let cmdb_field of requester?.impacted_asset?.customized_search_fields; let cmdbIndx = index"
														>
															<div
																class="mb-0"
																id="divCmdbAddfield-{{cmdbIndx}}"
																name="divCmdbAddfield-{{cmdbIndx}}"
																attr.data-testid="divCmdbAddfield-{{cmdbIndx}}"
																class="d-flex align-items-center sm-font"
															>
																<span class="xs-font truncated">
																	{{cmdb_field?.custom_field_id ? requester?.impacted_asset?.[cmdb_field?.custom_field_id] :requester?.impacted_asset?.[cmdb_field?.key] }}
																</span>
															</div>

														</div>
														</div>
														<div class="">
															<div
																*ngIf="requester?.is_login_active"
																class="d-flex align-items-center justify-content-end d-block"
															>
																<span class="badge badge-light-success">
																	{{ "UI.k_active" | translate }}
																</span>
															</div>

															<div
																*ngIf="!requester?.is_login_active"
																class="d-flex align-items-center justify-content-end d-block"
															>
																<span class="badge badge-light-danger">{{
																	"UI.k_inactive" | translate
																}}</span>
															</div>

															<div
																*ngIf="
																	requester?.phone_number?.internationalNumber &&
																	(requester?.impacted_asset?.[requester?.impacted_asset?.customized_search_fields?.[0]?.key])
																"
																class="mb-0 d-block"
																id="divspCard"
																name="divspCard"
																[attr.data-testid]="'divspCard'"
																class="d-flex align-items-center sm-font"
															>
																<span class="mr-1">
																	<i class="fa-light fa-phone"></i>
																</span>
																<span class="">{{
																	requester?.phone_number?.internationalNumber
																}}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-scrollbar>
						</div>
					</div>
					<ng-container *ngIf="!(requesterList.length == 0 && searcRequestLen > 2)">
						<div class="add-requester-btn flex-initial p-2">
							<div *ngIf="!SrequesterSearchStr" class="d-flex justify-content-center" style="top: 90%">
								<button
									type="button"
									class="btn btn-outline-primary"
									rippleEffect
									id="btnAddNewReq"
									name="btnAddNewReq"
									[attr.data-testid]="'btnAddNewReq'"
									*ngIf="requester_permissions?.add"
									(click)="addRequester('add-requester-profile')"
								>
									<span><i class="fa-light fa-plus"></i></span>
									<span> {{ "UI.k_add_new_requester" | translate }}</span>
								</button>
							</div>
						</div>
					</ng-container>
				</div>
				<ng-container *ngIf="isShownRequester">
					<div class="flex-1">
					  <div class="flex-auto-height">
						<div class="py-1 px-2 flex-1">
						  <div class="modal-body-inner flex-1">
							<div *ngIf="isShownRequester">
							  <div class="d-flex justify-content-between align-items-center mb-75">
								<div class="fw-500 text-dark" for="basic-request">
								  {{ "UI.k_requester_details" | translate }}
								</div>
								<button
								  type="button"
								  id="btnChangeReq"
								  name="btnChangeReq"
								  [attr.data-testid]="'btnChangeReq'"
								  (click)="changeRequester(false)"
								  class="btn btn-icon btn-flat-primary"
								  ngbTooltip="{{ 'UI.K_change_requester' | translate }}"
								  placement="left"
								  rippleEffect
								>
								  <i class="fa-solid fa-arrows-retweet"></i>
								</button>
							  </div>
							  <div class="d-flex align-items-center mb-1">
								<div class="avatar mr-half">
								  <div class="avatar" *ngIf="requester_temp?.profile_image">
									<img
									  [src]="requester_temp?.profile_image"
									  alt="Avatar"
									  width="32"
									  height="32"
									/>
								  </div>
								  <div
									*ngIf="!requester_temp?.profile_image"
									id="divInitial"
									name="divInitial"
									[attr.data-testid]="'divInitial'"
									class="avatar-content"
									[ngStyle]="{ 'background-color': requester_temp?.avatar_color ? requester_temp?.avatar_color : '#f59518' }"
								  >
									{{ requester_temp?.full_name | initials | slice: 0:2 }}
								  </div>
								</div>
								<div>
								  <div
									class="fw-500 text-dark mb-0 text-break"
									id="divFullname"
									name="divFullname"
									[attr.data-testid]="'divFullname'"
								  >
									{{ requester_temp?.full_name }}
								  </div>
								  <span
									class="text-break"
									id="spEmail"
									name="spEmail"
									[attr.data-testid]="'spEmail'"
								  >
									{{ requester_temp?.email }}
								  </span>
								</div>
							  </div>
							</div>
							<div class="mt-2" *ngIf="!setImpactService">
								<ngb-alert [type]="'primary'" [dismissible]="false">
									<div class="alert-body">
										<strong>
											{{ "UI.k_note" | translate }}:
											</strong
										>
										{{ "UI.k_changing_requester_impacted_service_note" | translate }}
									</div>
								</ngb-alert>
							</div>
						  </div>
						</div>
						<div class="stepper-btn d-flex justify-content-end border-top flex-initial p-1">
						  <button
							(click)="cancelRequester()"
							class="btn btn-flat-primary btn-prev"
							rippleEffect
							id="btnPrsnPrev"
							name="btnPrsnPrev"
							[attr.data-testid]="'btnPrsnPrev'"
						  >
							<i class="fa-light fa-arrow-left align-middle mr-50 mr-0"></i>
							<span class="align-middle d-sm-inline-block d-none">
							  {{ "UI.k_cancel" | translate }}
							</span>
						  </button>
						  <button
							class="btn btn-sm btn-primary btn-next ml-1"
							id="btnServiceChangeSubmit"
							rippleEffect
							type="submit"
							(click)="onSubmitRequester()"
							[attr.data-testid]="'btnServiceChangeSubmit'"
						  >
							<span class="align-middle d-sm-inline-block d-none">
							  {{ "UI.k_submit" | translate }}
							</span>
						  </button>
						</div>
					  </div>
					</div>
				  </ng-container>
				  
			</form>
			<!-- Keeping The Bellow in False block Becasue Requester Service change is not required currently -->
			<div class="slideout-content default-stepping" *ngIf="false">
				<div class="modal-dialog m-0 w-100">
					<form #UserAddForm="ngForm" class="modal-content add-new-user pt-0 pb-0">
						<div class="modal-body p-0 flex-1 flex-auto-height" tabindex="0" ngbAutofocus>
							<section class="horizontal-wizard" style="height: 100%">
								<div id="stepper1" class="bs-stepper horizontal-wizard-example">
									<div class="bs-stepper-header">
										<div id="divAccntTab" name="divAccntTab" class="step" data-target="#requester-details">
											<button class="step-trigger" id="btnAccountDetail" name="accountDetail">
												<span class="bs-stepper-box">1</span>
												<span class="bs-stepper-label">
													<span class="bs-stepper-title">{{ "UI.k_requester" | translate }} </span>
													<span class="bs-stepper-subtitle">{{
														"UI.k_add_req_info" | translate
														}}</span>
												</span>
											</button>
										</div>
										<div class="line">
											<i class="fa-light fa-chevron-right font-medium-2"></i>
										</div>
										<div id="divPsnlInfo" name="divPsnlInfo" class="step" data-target="#service-details">
											<button class="step-trigger" id="btnWorkDetail" name="workDetail">
												<span class="bs-stepper-box">2</span>
												<span class="bs-stepper-label">
													<span class="bs-stepper-title">{{ "UI.k_work" | translate }} </span>
													<span class="bs-stepper-subtitle">{{
														"UI.k_add_work_details" | translate
														}}</span>
												</span>
											</button>
										</div>
									</div>
									<div class="bs-stepper-content">
										<div class="bs-stepper-inner">
											<div id="requester-details" class="content">
												<div class="modal-body p-0 flex-1 flex-auto-height" tabindex="0" ngbAutofocus>
													<form #changeRequesterForm="ngForm" class="flex-auto-height">
														<div class="flex-1 flex-auto-height" *ngIf="!isShownRequester">
															<!-- <div class="modal-header border-bottom flex-initial">
																<div class="text-center w-100">
																	<h5 class="fw-600 mb-0" id="myModalLabel1">
																		{{ "UI.k_add_search_change_requester" | translate }}
																	</h5>
																</div>
															</div> -->
															<div class="flex-1 flex-auto-height">
																<div class="search-input-sec flex-initial">
																	<div class="py-1 px-2">
																		<div class="input-group input-group-merge">
																			<div class="input-group-prepend">
																				<span class="input-group-text text-muted-color" id="basic-addon-search2"
																					><i class="fa-light fa-magnifying-glass"></i
																				></span>
																			</div>
																			<input
																				type="text"
																				[(ngModel)]="requesterSearchStr"
																				name="txtInciRequesterSearch"
																				id="txtInciRequesterSearch"
																				class="form-control"
																				[attr.data-testid]="'txtInciRequesterSearch'"
																				placeholder="{{ 'UI.k_search_requester' | translate }}"
																				minlength="3"
																				maxlength="100"
																				(ngModelChange)="searchRequester(requesterSearchStr, 'change')"
																			/>
																		</div>
																		<div *ngIf="searchFalse">
																			<div class="text-center sm-font mt-50">
																				{{ "UI.k_search_validation" | translate }}
																			</div>
											
																			<div class="d-flex flex-column align-items-center justify-content-center my-3">
																				<img
																					class="rounded"
																					src="assets/images/empty-requester.svg"
																					alt="search-bg"
																					width="146"
																					height="86"
																				/>
											
																				<div class="text-center">
																					{{ "UI.k_search_here" | translate }}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="search-list-sec flex-1">
																	<div
																		*ngIf="requesterList.length == 0 && searcRequestLen > 2"
																		class="no-data d-flex flex-column align-items-center"
																	>
																		<div class="font-small-4 fw-500">
																			{{ "UI.k_requester_found" | translate }}
																		</div>
																		<button
																			type="button"
																			class="btn btn-primary mt-1"
																			rippleEffect
																			id="btnAddNewReq"
																			name="btnAddNewReq"
																			[attr.data-testid]="'btnAddNewReq'"
																			*ngIf="requester_permissions?.add"
																			(click)="addRequester('add-requester-profile')"
																		>
																			<span><i class="fa-light fa-plus"></i></span>
																			<span> {{ "UI.k_add_new_requester" | translate }}</span>
																		</button>
																	</div>
											
																	<div *ngIf="requesterSearchStr && requesterList" class="comment requester-lists">
																		<ng-scrollbar class="custom-scrollbar section-scrollbar">
																			<ng-container *ngFor="let requester of requesterList; let i = index">
																				<div
																					class="cursor-pointer single-requester"
																					id="divrequester_{{ i }}"
																					name="divrequester_{{ i }}"
																					attr.data-testid="divrequester_{{ i }}"
																					(click)="shownRequester(requester, changeRequesterForm)"
																				>
																					<div class="align-items-center p-2">
																						<div class="media">
																							<div class="avatar" id="divImg">
																								<div class="avatar-content" *ngIf="requester?.profile_image">
																									<img
																										[src]="requester?.profile_image"
																										alt="Avatar"
																										width="40"
																										height="40"
																									/>
																								</div>
																								<div
																									*ngIf="!requester?.profile_image"
																									class="avatar-content"
																									[ngStyle]="{
																										'background-color': requester?.avatar_color
																											? requester?.avatar_color
																											: '#f59518'
																									}"
																								>
																									{{ requester?.full_name | initials | slice: O:2 }}
																								</div>
																							</div>
																							<div class="media-body">
																								<div class="d-flex justify-content-between w-100">
																									<div class="mb-0" id="divspCard" name="divspCard" [attr.data-testid]="'divspCard'">
																										<div class="title-color">
																											{{ requester?.full_name }}
																										</div>
																										<div class="truncated sm-font">
																											<span class="">{{ requester?.email }}</span>
																										</div>
																									</div>
																									<div
																										*ngIf="
																											requester?.phone_number?.internationalNumber &&
																											!requester?.impacted_asset?.customized_search_fields
																										"
																										class="mb-0"
																										id="divspCard"
																										name="divspCard"
																										[attr.data-testid]="'divspCard'"
																										class="d-flex align-items-center sm-font"
																									>
																										<span class="mr-1">
																											<i class="fa-light fa-phone"></i>
																										</span>
																										<span class="truncated">{{
																											requester?.phone_number?.internationalNumber
																										}}</span>
																									</div>
																									<div>
																										<div
																										*ngFor="let cmdb_field of requester?.impacted_asset?.customized_search_fields; let cmdbIndx = index"
																									>
																										<div
																											class="mb-0"
																											id="divCmdbAddfield-{{cmdbIndx}}"
																											name="divCmdbAddfield-{{cmdbIndx}}"
																											attr.data-testid="divCmdbAddfield-{{cmdbIndx}}"
																											class="d-flex align-items-center sm-font"
																										>
																											<span class="xs-font truncated">
																												{{cmdb_field?.custom_field_id ? requester?.impacted_asset?.[cmdb_field?.custom_field_id] :requester?.impacted_asset?.[cmdb_field?.key] }}
																											</span>
																										</div>
											
																									</div>
																									</div>
																									<div class="">
																										<div
																											*ngIf="requester?.is_login_active"
																											class="d-flex align-items-center justify-content-end d-block"
																										>
																											<span class="badge badge-light-success">
																												{{ "UI.k_active" | translate }}
																											</span>
																										</div>
											
																										<div
																											*ngIf="!requester?.is_login_active"
																											class="d-flex align-items-center justify-content-end d-block"
																										>
																											<span class="badge badge-light-danger">{{
																												"UI.k_inactive" | translate
																											}}</span>
																										</div>
											
																										<div
																											*ngIf="
																												requester?.phone_number?.internationalNumber &&
																												(requester?.impacted_asset?.[requester?.impacted_asset?.customized_search_fields?.[0]?.key])
																											"
																											class="mb-0 d-block"
																											id="divspCard"
																											name="divspCard"
																											[attr.data-testid]="'divspCard'"
																											class="d-flex align-items-center sm-font"
																										>
																											<span class="mr-1">
																												<i class="fa-light fa-phone"></i>
																											</span>
																											<span class="">{{
																												requester?.phone_number?.internationalNumber
																											}}</span>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</ng-container>
																		</ng-scrollbar>
																	</div>
																</div>
															</div>

															<ng-container *ngIf="!(requesterList.length == 0 && searcRequestLen > 2)">
																<div class="add-requester-btn flex-initial p-2 flex-initial">
																	<div *ngIf="!SrequesterSearchStr" class="d-flex justify-content-center" style="top: 90%">
																		<button
																			type="button"
																			class="btn btn-outline-primary"
																			rippleEffect
																			id="btnAddNewReq"
																			name="btnAddNewReq"
																			[attr.data-testid]="'btnAddNewReq'"
																			*ngIf="requester_permissions?.add"
																			(click)="addRequester('add-requester-profile')"
																		>
																			<span><i class="fa-light fa-plus"></i></span>
																			<span> {{ "UI.k_add_new_requester" | translate }}</span>
																		</button>
																	</div>
																</div>
															</ng-container>
														</div>
														<div class="flex-1 flex-auto-height" *ngIf="isShownRequester">
															<div class="flex-1 flex-auto-height">
																<!-- <div class="search-input-sec flex-initial"> -->
																<div class="py-1 px-2">
																	<div *ngIf="isShownRequester">
																		<div class="d-flex justify-content-between align-items-center mb-75">
																			<div class="fw-500 text-dark" for="basic-request">
																				{{ "UI.k_requester_details" | translate }}
																			</div>
																			<button
																				type="button"
																				id="btnChangeReq"
																				name="btnChangeReq"
																				[attr.data-testid]="'btnChangeReq'"
																				(click)="changeRequester(false)"
																				class="btn btn-icon btn-flat-primary"
																				ngbTooltip="{{ 'UI.K_change_requester' | translate }}"
																				placement="left"
																				rippleEffect
																			>
																				<i class="fa-solid fa-arrows-retweet"></i>
																			</button>
																		</div>
																		<div class="d-flex align-items-center mb-1">
																			<div class="avatar mr-half">
																				<div class="avatar" *ngIf="requester?.profile_image">
																					<img
																						[src]="requester?.profile_image"
																						alt="Avatar"
																						width="32"
																						height="32"
																					/>
																				</div>
																				<div
																					*ngIf="!requester?.profile_image"
																					id="divInitial"
																					name="divInitial"
																					[attr.data-testid]="'divInitial'"
																					class="avatar-content"
																					[ngStyle]="{
																						'background-color': requester?.avatar_color
																							? requester?.avatar_color
																							: '#f59518'
																					}"
																				>
																					{{ requester?.full_name | initials | slice: 0:2 }}
																				</div>
																			</div>
																			<div>
																				<div class="fw-500 text-dark mb-0 text-break" id="divFullname" 
																					name="divFullname" [attr.data-testid]="'divFullname'">
																					{{ requester?.full_name }}
																				</div>
							
																				<span class="text-break" id="spEmail" name="spEmail" [attr.data-testid]="'spEmail'">{{ requester?.email }}</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div class="stepper-btn d-flex justify-content-end border-top flex-initial p-1">
															<button
																(click)="horizontalWizardStepperNext(changeRequesterForm)"
																type="submit"
																class="btn btn-primary btn-next"
																rippleEffect
																id="btnAcntNxt"
																name="btnAcntNxt"
																[attr.data-testid]="'btnAcntNxt'"
															>
																<span class="align-middle d-sm-inline-block d-none">{{
																	"UI.k_next" | translate
																}}</span>
																<i class="fa-light fa-arrow-right align-middle ml-50 ml-0"></i>
															</button>
														</div>
													</form>
												</div>
											</div>
											
											<div id="service-details" class="content">
												<div class="modal-body p-0 flex-1 flex-auto-height" tabindex="0" ngbAutofocus>
													<form
														#ServiceChangeForm="ngForm"
														class="modal-body"
														(ngSubmit)="incidentEditSaveEvent(ServiceChangeForm, service_change_data)"
														>
														<div class="px-1 flex-auto-height">
															<!-- Impact Service Selection -->
															<div
																class="form-group"
																*appDnsLicenseHandler="{
																	module_key: 'SERVICEDESK',
																	keys: ['SERVICE_CATALOGUE']
																}"
																[attr.data-testid]="'tree_ImpactService'"
																>
																<label for="txtInciseverviceClassification">
																	{{ "UI.k_impact_service" | translate }}
																</label>
																<ng-container *ngIf="service_config?.is_editable">
																	<app-single-tree-dropdown
																	class="w-100"
																	[items]="dropdown?.formatted_services"
																	[value]="incident_data?.basic_info?.impact_service"
																	[id]="'edit-service' + incident_data?.incident_id"
																	(itemChange)="serviceValueChangeEdit($event)"
																	></app-single-tree-dropdown>
																</ng-container>
																<div
																	class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																	*ngIf="!service_config?.is_editable"
																	(click)="onImpactServiceClick($event, 'impact_service')"
																	>
																<div class="ng-select-container">
																	<div class="ng-value-container">
																		<div class="truncated">
																		{{
																			incident_data?.basic_info?.impact_service_tree_path ||
																			("UI.k_select_option" | translate)
																		}}
																		</div>
																		<div role="combobox" class="ng-input">
																		<input type="text" />
																		</div>
																	</div>
																	<span class="ng-arrow-wrapper"><span class="ng-arrow"></span></span>
																	<span
																		*ngIf="service_config?.is_loading"
																		class="fa fa-spinner fa-spin fa-2x"
																	></span>
																</div>
															</div>
															</div>

															<!-- Service Classification Selection -->
															<div
																class="form-group input-tree-dropdown"
																*appDnsLicenseHandler="{
																	module_key: 'SERVICEDESK',
																	keys: ['SERVICE_CATALOGUE']
																}"
																[attr.data-testid]="'tree_ServiceClassification'"
																>
																<label for="txtInciseverity">
																	{{ "UI.k_service_classification" | translate }}
																</label>
																<ng-container *ngIf="classification_config?.is_editable">
																	<app-single-tree-dropdown
																	class="w-100"
																	[items]="dropdown?.formatted_classification_data"
																	[value]="incident_data.basic_info?.service_classification"
																	[id]="'classification' + incident_data?.incident_id"
																	[treeInputConfig]="{ isParentAllowed: true }"
																	(itemChange)="classificationValueChangeEdit($event)"
																	></app-single-tree-dropdown>
																</ng-container>
																<div
																	class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																	*ngIf="!classification_config?.is_editable"
																	(click)="onImpactServiceClick($event, 'service_classification')"
																>
																	<div class="ng-select-container">
																	<div class="ng-value-container">
																		<div class="">
																		{{
																			incident_data.basic_info?.service_classification_path ||
																			("UI.k_select_option" | translate)
																		}}
																		</div>
																		<div role="combobox" class="ng-input">
																		<input type="text" />
																		</div>
																	</div>
																	<span class="ng-arrow-wrapper"><span class="ng-arrow"></span></span>
																	<span
																		*ngIf="classification_config?.is_loading"
																		class="fa fa-spinner fa-spin fa-2x"
																	></span>
																	</div>
																</div>
															</div>
															<!-- Workflow Details -->
															<div class="modal-body-inner flex-1">
																<ng-scrollbar class="custom-scrollbar section-scrollbar">
																	<div  *ngIf="service_change_data?.current_workflow">
																		<div>
																		<div class="d-grid border service-change-col rounded-top">
																			<ng-container class="border">
																				<div class="border-right">
																					<div class="border-bottom border-1 p-50"> <h4>{{ "UI.k_from" | translate }}</h4></div>
																					<div class="py-50 px-1">
																						<div class="pt-75">
																							<label for="txtFrom">
																								{{ "UI.k_impact_service_tree_path" | translate }}
																								<span class="required-field">&nbsp;*</span>
																								</label>
																								<input
																								type="text"
																								class="form-control"
																								name="inpImpactServiceFrom"
																								[attr.data-testid]="'inpImpactServiceFrom'"
																								[disabled]="true"
																								[(ngModel)]="current_impact_service_name"
																								/>
																						</div>
																						<div class="pt-1">
																							<h6 class="header-title" type="text">
																								{{ "UI.k_workflow" | translate }} -
																								{{ service_change_data?.current_workflow?.workflow_title }}
																							</h6>
																						</div>
																						<div
																							class="form-group pt-50"
																							[attr.data-testid]="'statusPicker'"
																							>
																							<app-status-picker
																								[inputConfig]="{
																								isTextMode: true,
																								stateOptionsMap: dropdown?.state_id_map,
																								name: 'previous_status',
																								id: 'previous_status',
																								bindValue: 'id',
																								isEditDisabled: true
																								}"
																								[options]="service_change_data?.current_workflow?.status || []"
																								(setStatus)="onStatusChangeEdit($event, 'previous_status')"
																								[value]="service_change_data?.current_status?.id"
																							></app-status-picker>
																						</div>
																					</div>
																				</div>
																				<div>
																					<div class="border-bottom border-1 p-50"><h4>{{ "UI.k_to" | translate }}</h4></div>
																					<div class="py-50 px-1">
																						<div class="pt-75">
																							<label for="txtTo">
																								{{ "UI.k_impact_service_tree_path" | translate }}
																								<span class="required-field">&nbsp;*</span>
																								</label>
																								<input
																								type="text"
																								class="form-control"
																								name="inpImpactServiceTo"
																								[attr.data-testid]="'inpImpactServiceTo'"
																								[disabled]="true"
																								[(ngModel)]="service_change_data?.selected_service_config.service_name"
																								/>
																						</div>
																						<div class="pt-1">
																							<h6 class="header-title" type="text">
																								{{ "UI.k_workflow" | translate }} -
																								{{
																									service_change_data?.selected_service_config?.workflow_title
																								}}
																							</h6>
																						</div>
																						<div class="form-group pt-50">
																							<app-status-picker
																								[inputConfig]="{
																								isTextMode: true,
																								stateOptionsMap: dropdown?.state_id_map,
																								name: 'current_status',
																								id: 'current_status',
																								header: 'UI.k_new_status',
																								required: true,
																								hideLabel: true,
																								formRef: ServiceChangeForm
																								}"
																								#sliderStatusPickerComponent
																								name="selected_service_config_status"
																								id="selected_service_config_status"
																								[attr.data-testid]="'selected_service_config_status'"
																								[options]="service_change_data?.selected_service_config?.status || []"
																								(setStatus)="onStatusChangeEdit($event, 'current_status_obj')"
																								[value]="service_change_data.current_status"
																							></app-status-picker>
																						</div>
																					</div>
																				</div>
																			</ng-container>
																		</div>
																		<ng-container *ngIf="custom_field_config">
																			<app-dns-custom-field
																			[formRef]="ServiceChangeForm"
																			placeholder="general"
																			[data]="service_change_data?.custom_field_data"
																			[config]="service_change_data?.selected_service_config?.custom_field_config"
																		></app-dns-custom-field>
																		</ng-container>
																		</div>
																	</div>
	
																	<!-- Reason for Service Change -->
																	<div class="col-md-12 p-0" *ngIf="this.dropdown?.formatted_services.length>0">
																		<div class="form-group">
																		<label for="txtAreaSmartGridServiceChange" class="sm-font">
																			{{ "UI.k_reason_for_service_change" | translate }}
																			<span class="required-field">&nbsp;*</span>
																		</label>
																		<div class="card p-0">
																			<quill-editor
																			[(ngModel)]="serviceChangeComment"
																			#TDSmartGridServiceChangeComment="ngModel"
																			id="txtAreaSmartGridServiceChange"
																			name="txtAreaSmartGridServiceChange"
																			[attr.data-testid]="'txtAreaSmartGridServiceChange'"
																			required
																			[trimOnValidation]="true"
																			[appQuillForm]="ServiceChangeForm"
																			[minLength]="3"
																			[class.error]="
																				TDSmartGridServiceChangeComment?.invalid &&
																				ServiceChangeForm.submitted
																			"
																			placeholder="{{ 'UI.k_insert_text_here' | translate }}..."
																			>
																			<div quill-editor-toolbar class="px-0 py-50">
																				<div class="p-0">
																				<span class="ql-formats pb-50">
																					<button class="ql-bold"></button>
																					<button class="ql-italic"></button>
																					<button class="ql-underline"></button>
																				</span>
																				<span class="ql-formats">
																					<select class="ql-align">
																					<option selected></option>
																					<option value="center"></option>
																					<option value="right"></option>
																					<option value="justify"></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button class="ql-list" value="ordered"></button>
																					<button class="ql-list" value="bullet"></button>
																				</span>
																				</div>
																			</div>
																			</quill-editor>
																		</div>
																		<div>
																			<span
																			*ngIf="
																				ServiceChangeForm.submitted &&
																				TDSmartGridServiceChangeComment?.errors
																			"
																			class="invalid-form"
																			>
																			<small
																				*ngIf="
																				TDSmartGridServiceChangeComment?.errors?.required
																				"
																				class="form-text text-danger xs-font"
																				>{{ "UI.k_field_required" | translate }}</small
																			>
																			<small
																				*ngIf="
																				TDSmartGridServiceChangeComment?.errors?.minlength
																				"
																				class="form-text text-danger xs-font"
																				>{{ "Error.err_min_3_character" | translate }}</small
																			>
																			</span>
																		</div>
																		</div>
																	</div>
	
																	<!-- Business Rule Checkbox -->
																	<div *ngIf="service_change_data?.args?.current_assignment_info?.group" class="card p-1 border d-grid gap-1">
																		<div class="d-flex align-items-center justify-content-between">
																			<div class="d-flex gap-50 align-items-center">
																				<span class="title-color fw-500">{{ "UI.k_business_rule_associated" | translate }}</span>
																				<span>:</span>
																				<a
																					class="cursor-pointer primary-color fw-500"
																					id="btnRedirectToBizRule"
																					routerLink="/business_rule"
																					ngbTooltip="{{
																						'UI.k_view'
																							| translate
																					}}"
																					target="_blank"
																				>
																					{{service_change_data?.args?.current_assignment_info?.rule_name}}
																				</a>
																			</div>
																			<div class="custom-control custom-checkbox">
																				<input type="checkbox" 
																				class="custom-control-input" 
																				[attr.data-testid]="'ckIncludeBusinessRule'" 
																				name="ckIncludeBusinessRule" 
																				id="ckIncludeBusinessRule" 
																				[(ngModel)]="isIncludeBusRule"
																				(change)="changeIncludeBusRule($event)"/>
																				<label class="custom-control-label" for="ckIncludeBusinessRule">{{ "UI.k_include_business_rule" | translate }}</label>
																			</div>
																		</div>
																		<div class="teams-data fw-500" [ngClass]="isIncludeBusRule ? 'title-color' : 'text-muted-color'">
																			<div class="teams-info">
																				<div class="info-label">
																					{{ "UI.k_team" | translate }}
																				</div>
																				<span>:</span>
																				<div class="info-value">
																					{{service_change_data?.args?.current_assignment_info?.group_name}}
																				</div>
																			</div>
																			<div class="teams-info">
																				<div class="info-label">
																					{{ "UI.k_expertise" | translate }}
																				</div>
																				<span>:</span>
																				<div class="info-value">
																					{{service_change_data?.args?.current_assignment_info?.expertise}}
																				</div>
																			</div>
																			<div class="teams-info">
																				<div class="info-label">
																					{{ "UI.k_level" | translate }}
																				</div>
																				<span>:</span>
																				<div class="info-value">
																					{{service_change_data?.args?.current_assignment_info?.level}}
																				</div>
																			</div>
																			<div class="teams-info">
																				<div class="info-label">
																					{{ "UI.k_assignee" | translate }}
																				</div>
																				<span>:</span>
																				<div class="info-value">
																					{{service_change_data?.args?.current_assignment_info?.assignee_profile?.full_name}} 
																				</div>
																				<div class="info-value" *ngIf="!service_change_data?.args?.current_assignment_info?.assignee_profile?.full_name">
																					-
																				</div>
																			</div>
																		</div>
																		<div class="rule-note transparent-primary border-radius-6 d-grid p-1">
																			<span class="sm-font fw-500">{{ "UI.k_note" | translate }}</span>
																			<span class="sm-font">{{ "Message.msg_include_business_rule_note" | translate }}</span>
																		</div>
																	</div>
																</ng-scrollbar>
															</div>
															
															<div class="stepper-btn d-flex justify-content-end border-top flex-initial p-1">
																<button
																	(click)="horizontalWizardStepperPrevious()"
																	class="btn btn-flat-primary btn-prev"
																	rippleEffect
																	id="btnPrsnPrev"
																	name="btnPrsnPrev"
																	[attr.data-testid]="'btnPrsnPrev'"
																	>
																	<i class="fa-light fa-arrow-left align-middle mr-50 mr-0"></i>
																	<span class="align-middle d-sm-inline-block d-none"
																		>{{ "UI.k_back" | translate }}
																	</span>
																</button>
																<button
																	class="btn btn-sm btn-primary btn-next ml-1"
																	id="btnServiceChangeSubmit"
																	rippleEffect
																	type="submit"
																	[attr.data-testid]="'btnServiceChangeSubmit'"
																>
																	<span class="align-middle d-sm-inline-block d-none">
																		{{ "UI.k_submit" | translate }}</span
																	>
																</button>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</form>
				</div>
			</div>
		</ng-template>
	</div>
	<!-- / Modal -->
	<ng-template #appovalChangeComp let-modal>
		<app-approval-config
			[process_data]="process_data"
			[options]="dropdown"
			[approval_status]="temp_status"
			[module_id]="module_id"
			(onApprovalConfigClose)="onApprovalConfigClose($event)"
			(onApprovalConfigSubmit)="onApprovalConfigSubmit($event)"
		>
		</app-approval-config>
	</ng-template>
	<ng-template #previewSignature let-modal>
		<div class="modal-header border-bottom">
			<h6 class="mb-0">
				{{ "UI.k_preview_signature" | translate }}
			</h6>
			<button
				type="button"
				id="previewClose"
				name="previewClose"
				[attr.data-testid]="'previewClose'"
				class="close"
				(click)="modal.dismiss('Cross click')"
				aria-label="Close"
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<div class="d-flex align-items-center justify-content-between mb-1">
				<h3 class="mb-0">
					{{ previewData.text }}
				</h3>
				<span class="cursor-pointer" (click)="editTemplate(data)">
					<i class="fa-light fa-pen-to-square sm-font"></i>
				</span>
			</div>
			<div class="preview" [innerHtml]="previewData.value | safe: 'html'"></div>
		</div>
		<div class="d-flex justify-content-end border-top px-1 py-1">
			<button
				type="button"
				(click)="modal.dismiss('Cross click')"
				class="btn btn-outline-tertiary btn-prev mr-1"
				rippleEffect
			>
				<span class="align-middle"> {{ "UI.k_cancel" | translate }}</span>
			</button>
			<button
				type="button"
				id="previewData"
				name="previewData"
				[attr.data-testid]="'previewData'"
				(click)="useItSignature(previewData)"
				class="btn btn-primary btn-next"
				rippleEffect
			>
				<span class="align-middle">{{ "UI.k_use_it" | translate }}</span>
			</button>
		</div>
	</ng-template>
	<ng-template #CurrentAssigneeComp let-modal>
		<div class="modal-header">
			<h4 class="modal-title" id="myModalLabel1">
				{{ "UI.k_assignment" | translate }}
			</h4>
			<br />
			<!-- <small *ngIf="selfAssignSel">Please select group</small> -->
			<span
				id="btnAssignClose"
				name="btnAssignClose"
				data-dismiss="modal"
				aria-label="Close"
				(click)="closeCurrAssignPopup()"
			>
				<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
			</span>
		</div>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<form #currentAssigneeForm="ngForm">
				<div class="row mb-2">
					<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
						<div class="form-group">
							<label for="txtInciSelAssignGroup"
								>{{ "UI.k_sel_team" | translate }}
								<span class="required-field">&nbsp;*</span>
							</label>
							<ng-select
								[items]="dropdown.teams_and_self_assign"
								(clear)="selfAssignSel = false"
								[hideSelected]="true"
								bindLabel="name"
								bindValue="team_id"
								[(ngModel)]="team_id"
								(change)="groupAssignChange(dropdown.teams, team_id)"
								name="txtInciSelAssignGroup"
								id="txtInciSelAssignGroup"
								placeholder="{{ 'UI.k_select' | translate }} {{
									'UI.k_group' | translate
								}}"
								#TDSelGroup="ngModel"
								[closeOnSelect]="true"
								[class.error]="
									!TDSelGroup.valid && currentAssigneeForm.submitted
								"
								required
								[attr.data-testid]="'sel_team_id'"
							>
								<ng-template ng-label-tmp let-item="item">
									<span
										class="d-flex ng-value-label align-items-center"
										[ngbTooltip]="item.name"
										container="body"
									>
										<span
											class="avatar mr-50 avatar-sm"
											[ngClass]="bg - light - primary"
										>
											<div
												class="avatar-content"
												[ngStyle]="{
													'background-color': stringToColor(
														item.name
													)
												}"
											>
												{{ item.name | initials | slice: 0:2 }}
											</div>
										</span>
										<span class="font-small-2">
											{{ item?.name | slice: 0:28 }}
											{{ item?.name.length > 28 ? ".." : "" }}
										</span>
									</span>
								</ng-template>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
									let-search="searchTerm"
								>
									<span
										class="d-flex"
										(click)="selfAssignSel = false"
										*ngIf="item.team_id !== 'self_assign'"
									>
										<div
											class="avatar mr-1 ml-0"
											[ngStyle]="{
												'background-color': item.avatar_color
													? item.avatar_color
													: '#f59518'
											}"
											style="
												align-content: center;
												width: 32px;
												height: 32px;
											"
										>
											<div
												class="avatar-content"
												[ngStyle]="{
													'background-color': stringToColor(
														item.name
													)
												}"
											>
												{{ item.name | initials | slice: 0:2 }}
											</div>
										</div>
										<div class="cell-line-height">
											<p
												class="lg-font fw-500 line-height-1 mb-0"
												container="body"
												[ngbTooltip]="item.name"
											>
												{{ item.name | slice: 0:20 }}
												{{
													item?.name?.length > 20 ? ".." : ""
												}}
											</p>
											<span class="text-muted xs-font">
												{{ item.group_type }}</span
											>
										</div>
									</span>
									<div
										class="cursor-pointer"
										*ngIf="item.team_id === 'self_assign'"
									>
										<div class="media" (click)="assignToMyself()">
											<div class="avatar mr-1">
												<ng-container
													*ngIf="currentUser?.profile_image"
												>
													<img
														class="avatar-content"
														class=""
														[src]="
															currentUser?.profile_image
														"
														width="32"
														height="32"
													/>
												</ng-container>
												<ng-container
													id="divAssignee"
													*ngIf="!currentUser?.profile_image"
												>
													<span
														[ngStyle]="{
															'background-color':
																currentUser?.profile
																	?.avatar_color !==
																undefined
																	? currentUser
																			?.profile
																			?.avatar_color
																	: '#f59518',
															width: 32,
															height: 32
														}"
														class="avatar-content"
													>
														{{
															this.currentUser
																.first_name +
																"" +
																this.currentUser
																	.last_name
																| initials
																| slice: 0:2
														}}
													</span>
												</ng-container>
											</div>
											<div class="media-body">
												<h5 class="mb-0">
													{{ "UI.k_self_assign" | translate }}
												</h5>
												<small class="text-muted">{{
													this.currentUser.email
												}}</small>
											</div>
										</div>
									</div>
								</ng-template>
							</ng-select>
							<span
								*ngIf="
									currentAssigneeForm.submitted && TDSelGroup.invalid
								"
								class="invalid-form"
							>
								<small
									class="form-text text-danger"
									*ngIf="TDSelGroup.errors.required"
									>{{ "UI.k_field_required" | translate }}</small
								>
							</span>
						</div>
					</div>
					<div
						class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							selfAssignSel && dropdown?.current_user_group?.length > 1
						"
					>
						<label for="txtInciAssignGroup">
							{{ "UI.k_select_group" | translate }}
							<span class="required-field">&nbsp;*</span>
						</label>
						<ng-select
							[items]="dropdown.current_user_group"
							[hideSelected]="true"
							(change)="
								groupChange(
									dropdown.current_user_group,
									currentAssignmentInfo.group
								)
							"
							bindLabel="name"
							bindValue="team_id"
							[(ngModel)]="currentAssignmentInfo.group"
							name="txtInciAssignGroup"
							id="txtInciAssignGroup"
							placeholder="{{ 'UI.k_select_group' | translate }} "
							#TDGroup="ngModel"
							[closeOnSelect]="true"
							[class.error]="
								!TDGroup.valid && currentAssigneeForm.submitted
							"
							required
							[attr.data-testid]="'txtInciAssignGroup'"
						>
							<ng-template
								ng-option-tmp
								let-item="item"
								let-index="index"
								let-search="searchTerm"
							>
								<span class="d-flex">
									<div
										class="avatar mr-1 ml-0"
										[ngStyle]="{
											'background-color': item.avatar_color
												? item.avatar_color
												: '#f59518'
										}"
										style="
											align-content: center;
											width: 32px;
											height: 32px;
										"
									>
										<div
											class="avatar-content"
											[ngStyle]="{
												'background-color': stringToColor(
													item.name
												)
											}"
										>
											{{ item.name | initials | slice: 0:2 }}
										</div>
									</div>
									<div class="cell-line-height">
										<p class="lg-font fw-500 line-height-1 mb-0">
											{{ item.name | slice: 0:25 }}
											{{ item?.name?.length > 25 ? ".." : "" }}
										</p>
										<span class="text-muted xs-font">
											{{ item.group_type }}</span
										>
									</div>
								</span>
							</ng-template>
						</ng-select>
						<span
							*ngIf="currentAssigneeForm.submitted && TDGroup.invalid"
							class="invalid-form"
						>
							<small
								class="form-text text-danger"
								*ngIf="TDGroup.errors.required"
								>{{ "UI.k_field_required" | translate }}</small
							>
						</span>
					</div>

					<div
						class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							selfAssignSel &&
							dropdown?.current_user_expertise?.length > 1
						"
					>
						<label for="txtInciEditExpertiseSelfAssign"
							>{{ "UI.k_select_expertise" | translate }}
							<span class="required-field">&nbsp;*</span>
						</label>
						<ng-select
							[items]="dropdown?.current_user_expertise"
							(change)="expertiseChange(dropdown?.current_user_expertise)"
							[hideSelected]="true"
							bindLabel="expertise"
							bindValue="expertise"
							[(ngModel)]="currentAssignmentInfo.expertise"
							name="txtInciEditExpertiseSelfAssign"
							id="txtInciEditExpertiseSelfAssign"
							placeholder="{{ 'UI.k_select_expertise' | translate }} "
							#TDInciEdiSelfAssignExpertise="ngModel"
							[closeOnSelect]="true"
							[class.error]="
								!TDInciEdiSelfAssignExpertise.valid &&
								currentAssigneeForm.submitted
							"
							[attr.data-testid]="'txtInciEditExpertiseSelfAssign'"
							required
						>
						</ng-select>
						<span
							*ngIf="
								currentAssigneeForm.submitted &&
								TDInciEdiSelfAssignExpertise.invalid
							"
							class="invalid-form"
						>
							<small
								class="form-text text-danger"
								*ngIf="TDInciEdiSelfAssignExpertise.errors.required"
								>{{ "UI.k_field_required" | translate }}</small
							>
						</span>
					</div>

					<div
						class="col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							!selfAssignSel &&
							current_extra_info.group_type === 'Operation and Technical'
						"
					>
						<div class="form-group">
							<label for="txtInciReporterExpertise"
								>{{ "UI.k_expertise" | translate }}
								<!-- <span class="required-field">&nbsp;*</span> -->
							</label>
							<ng-select
								[items]="dropdown?.expertise"
								[hideSelected]="true"
								(change)="expertiseChange(dropdown?.expertise)"
								bindLabel="expertise"
								bindValue="expertise"
								[(ngModel)]="currentAssignmentInfo.expertise"
								name="txtInciExpertise"
								id="txtInciExpertise"
								placeholder="{{ 'UI.k_select_expertise' | translate }} "
								#TDExpertise="ngModel"
								[closeOnSelect]="true"
								[attr.data-testid]="'sel_InciExpertise'"
							>
							</ng-select>
							<!-- <span
								*ngIf="
									currentAssigneeForm.submitted && TDExpertise.invalid
								"
								class="invalid-form"
							>
								<small
									class="form-text text-danger"
									*ngIf="TDExpertise.errors.required"
									>{{ 'UI.k_field_required' | translate }}</small
								>
							</span> -->
						</div>
					</div>
					<div
						class="col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							!selfAssignSel &&
							current_extra_info.group_type === 'Operation and Technical'
						"
					>
						<div class="form-group">
							<label for="txtInciReporterLevel"
								>{{ "UI.k_level" | translate }}
								<span
									*ngIf="currentAssignmentInfo.expertise"
									class="required-field"
									>&nbsp;*</span
								>
							</label>
							<ng-select
								*ngIf="!currentAssignmentInfo.expertise"
								[items]="dropdown.level"
								[hideSelected]="true"
								(change)="levelChange()"
								bindLabel="level"
								bindValue="level"
								[(ngModel)]="currentAssignmentInfo.level"
								name="txtMainReporterLevel"
								id="txtReporterLevel"
								placeholder="{{ 'UI.k_select_level' | translate }}"
								[closeOnSelect]="true"
								[attr.data-testid]="'sel_reporterLevel'"
							>
							</ng-select>
							<ng-container *ngIf="currentAssignmentInfo.expertise">
								<ng-select
									[items]="dropdown.level"
									[hideSelected]="true"
									(change)="levelChange()"
									bindLabel="level"
									bindValue="level"
									[(ngModel)]="currentAssignmentInfo.level"
									name="txtInciMainReporterLevel"
									id="txtInciMainReporterLevel"
									placeholder="{{ 'UI.k_select_level' | translate }}"
									#TDInciMainLevel="ngModel"
									[closeOnSelect]="true"
									[class.error]="
										!TDInciMainLevel?.valid &&
										currentAssigneeForm.submitted
									"
									[attr.data-testid]="'sel_inciMainReporterLevel'"
									required
								>
								</ng-select>
								<span
									*ngIf="
										currentAssigneeForm.submitted &&
										TDInciMainLevel?.invalid
									"
									class="invalid-form"
								>
									<small class="form-text text-danger">{{
										"UI.k_field_required" | translate
									}}</small>
								</span>
							</ng-container>
						</div>
					</div>
					<div
						class="col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="!selfAssignSel"
					>
						<div class="form-group">
							<label for="txtInciAssignee"
								>{{ "UI.k_assignee" | translate }}
								<!-- <span class="required-field">&nbsp;*</span> -->
							</label>
							<ng-select
								[items]="dropdown.assignee"
								#assignee="ngModel"
								[(ngModel)]="currentAssignmentInfo.assignee"
								(change)="assigneeChange()"
								bindLabel="full_name"
								bindValue="profile_id"
								name="txtInciAssignee"
								id="txtInciAssignee"
								[searchable]="true"
								[searchFn]="customSearchFn"
								placeholder="{{ 'UI.k_select_assignee' | translate }} "
								[multiple]="false"
								#TDAssignee="ngModel"
								[closeOnSelect]="true"
								[attr.data-testid]="'sel_txtInciAssignee'"
							>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
									let-search="searchTerm"
								>
									<span class="d-flex">
										<div
											*ngIf="
												dropdown.team_user_profile_map[
													item.profile_id
												]?.profile_image
											"
										>
											<div class="avatar mr-50">
												<span class="avatar-content">
													<img
														class="rounded-circle"
														src="{{
															dropdown
																.team_user_profile_map[
																item.profile_id
															].profile_image
														}}"
														alt="User"
														width="32"
														height="32"
													/>
												</span>
											</div>
										</div>
										<div
											*ngIf="
												!dropdown.team_user_profile_map[
													item.profile_id
												]?.profile_image
											"
										>
											<div
												class="avatar mr-50"
												[ngStyle]="{
													'background-color': dropdown
														.team_user_profile_map[
														item.profile_id
													]?.avatar_color
														? dropdown
																.team_user_profile_map[
																item.profile_id
														  ]?.avatar_color
														: '#f59518'
												}"
												style="
													align-content: center;
													width: 32px;
													height: 32px;
												"
											>
												<div class="avatar-content">
													{{
														item.full_name
															| initials
															| slice: 0:2
													}}
												</div>
											</div>
										</div>
										<div class="cell-line-height">
											<p
												class="lg-font fw-500 line-height-1 mb-0"
												[ngbTooltip]="item.full_name"
											>
												{{ item?.full_name | slice: 0:25 }}
												{{
													item?.full_name?.length > 25
														? ".."
														: ""
												}}
											</p>
											<span class="text-muted xs-font">
												{{ item?.email | slice: 0:25 }}
												{{
													item?.email?.length > 25 ? ".." : ""
												}}</span
											>
										</div>
									</span>
								</ng-template>
							</ng-select>
							<!-- <span
								*ngIf="
									currentAssigneeForm.submitted && TDAssignee.invalid
								"
								class="invalid-form"
							>
								<small
									class="form-text text-danger"
									*ngIf="TDAssignee.errors.required"
									>{{ 'UI.k_field_required' | translate }}</small
								>
							</span> -->
						</div>
					</div>
				</div>
				<div class="col-12 pl-0">
					<app-dns-custom-field
						placeholder="assignment"
						[formRef]="currentAssigneeForm"
						[data]="custom_field_data"
						[config]="custom_field_config"
					></app-dns-custom-field>
				</div>
				<div class="d-flex pull-right pb-half">
					<div class="mr-1">
						<button
							(click)="saveProcessData(currentAssigneeForm)"
							type="submit"
							[disabled]="asgnDisabled"
							class="btn btn-primary"
							*ngIf="
								!(
									process_data?.is_exit &&
									process_data?.basic_info?.status?.state_id === 5
								) &&
								permissions?.edit &&
								disable_config === false
								&& is_ssp_login === false
							"
							rippleEffect
							id="btnAssignSave"
							name="btnAssignSave"
							[attr.data-testid]="'btnAssignSave'"
						>
							<i class="fa-light fa-floppy-disk"></i>&nbsp;&nbsp;{{
								"UI.k_save_changes" | translate
							}}
						</button>
					</div>
				</div>
			</form>
		</div>
	</ng-template>
</div>

<!-- Common Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	overlayClass="modal-backdrop"
	name="attach-knowledge-base"
>
	<app-attach-knowledge-base
		*ngIf="showAttachedKB"
		[kbInput]="{
			'suggested-kb': suggested_kb,
			'attached-kb': filteredKBs,
			filter_string: ''
		}"
		(hideAllKb)="HideAllKb($event)"
		(updateAllKb)="updateAllKb($event)"
	></app-attach-knowledge-base>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-associate-incident"
	overlayClass="modal-backdrop"
>
	<app-associate-relation
		*ngIf="showAddRelation"
		[parent_obj]="process_data_copy"
		[options]="dropdown"
		[permissions]="permissions"
		[relations]="incidentRelations"
		[mapping]="incidentMapping"
		[relationModule]="relationModule"
		(onHideAssociateRelations)="onHideAssociateRelations($event)"
		[module_id]="module_id"
		[module]="module"
		(getRelations)="getRelations($event)"
	>
	</app-associate-relation>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-priority-change-incident-sidebar-inner"
	overlayClass="modal-backdrop"
>
	<ng-container *ngIf="is_priority_change">
		<app-priority-change
			sidebarName="incident-priority-change-incident-sidebar-inner"
			[config_data]="process_data"
			(onPriorityChangeSubmit)="onPriorityChangeSubmit($event)"
			(onPrioritySliderClose)="onPrioritySliderClose($event)"
		>
		</app-priority-change>
	</ng-container>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="app-dns-kb-add" overlayClass="modal-backdrop">
	<app-dns-kb-add
		[event]="kbEvent"
		[item]="kbCurrentItem"
		*ngIf="kbHideProfile"
		(onHideKBProfile)="onHideKBProfile($event)"
		(addedKBID)="getKBID($event)"
		[kbSolution]="kbSolution"
		[isExternalCall]="true"
		[refId]="processID"
	>
	</app-dns-kb-add>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="convt-incident-to-change-view"
	overlayClass="modal-backdrop"
>
	<app-dns-change-view
		*ngIf="showConvIncidentToChangeView"
		[change_data]="change_data"
		[change_id]="process_data?.change?.change_id"
		(onHideChangeView)="onHideConvInciToChangeView($event)"
	>
	</app-dns-change-view>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-incident-request-view"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-request-view
		[request_data]="request_data"
		[request_id]="process_data_copy?.request?.request_id"
		*ngIf="showConvInciToReqView"
		(onHideConvInciToReqView)="onHideConvInciToReqView($event)"
	>
	</app-dns-incident-request-view>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-problem-view"
	overlayClass="modal-backdrop"
>
	<app-common-problem-view
		*ngIf="showConvInciToProblemView"
		[problem_data]="problem_data"
		[problem_id]="process_data?.problem?.problem_id"
		(onHideProblemView)="onHideConvInciToProblemView($event)"
	>
	</app-common-problem-view>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="edit-requester"
	overlayClass="modal-backdrop"
	>
	<app-dns-edit-requester
	*ngIf="hideProfile"
	[action]="currentEvent"
	[requesterId]="currentRequester"
	[refId]="processID"
	(onHideProfile)="onHideProfile($event)"
	(shownRequester)="editRequesterUpdate($event)"
	[reqFromExternal]="true"
	></app-dns-edit-requester>
</core-sidebar>
<!-- End of Common Sidebar -->

<!-- Incident Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="close-resolve-incident"
	overlayClass="modal-backdrop"
>
	<!-- <app-incident-resolve
		*ngIf="load_inci_resolve"
		[incident_id]="process_data_copy?.incident_id"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		[is_call_from_edit]="true"
		(onUpdateResolveClose)="onUpdateResolveClose($event)"
		(onHideResolve)="onHideResolve($event)"
	>
	</app-incident-resolve> -->

	<app-dns-resolve-status
		*ngIf="load_inci_resolve"
		[incident_id]="process_data_copy?.incident_id"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		[is_call_from_edit]="true"
		(onUpdateResolveClose)="onUpdateResolveClose($event)"
		(onHideResolve)="onHideResolve($event)"
		[callFrom]="'panelCard'"
	>
	</app-dns-resolve-status>
</core-sidebar>
<core-sidebar
	class="modal modal-higher modal-slide-in sidebar-todo-modal fade"
	name="incident-service-change-process-view"
	overlayClass="modal-backdrop-higher"
>
	<ng-container *ngIf="is_service_change">
		<app-service-change
			[options]="dropdown"
			sidebarName="incident-service-change-process-view"
			[service_config]="service_change_data"
			(onServiceChangeSubmit)="onServiceChangeSubmit($event)"
			(onServiceSliderClose)="onServiceSliderClose()"
		>
		</app-service-change>
	</ng-container>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-incident-kb-solution"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-kb-solution
		[kbID]="process_data_copy?.kb_id"
		[kbSolution]="kbSolution"
		*ngIf="kbSolutionHideProfile"
		(onHideKBSolution)="onHideKBSolution($event)"
	>
	</app-dns-incident-kb-solution>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-request"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-to-request
		*ngIf="showConvInciToReq"
		[request_data]="process_data"
		(onHideConvInciToReq)="onHideConvInciToReq($event)"
		(addedReqID)="getReqID($event)"
	>
	</app-dns-incident-to-request>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="convt-incident-to-change"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-to-change
		*ngIf="showConvIncidentToChange"
		[incident_data]="process_data"
		(onHideConvInciToChange)="onHideConvInciToChange($event)"
		(addedChangeID)="getChangeID($event)"
		[fromEditCall]="true"
	>
	</app-dns-incident-to-change>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-problem"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-to-problem
		*ngIf="showConvInciToProblem"
		[incident_data]="process_data"
		(onHideConvInciToProblem)="onHideConvInciToProblem($event)"
		(addedProblemID)="getProblemID($event)"
		[fromEditCall]="true"
	>
	</app-dns-incident-to-problem>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-dns-attachment"
	overlayClass="modal-backdrop"
>
	<app-dns-attachment
		*ngIf="!isHideAddAttachment && moduleName === 'Incident'"
		(hideAddAttachment)="hideAddAttachment($event)"
		[incidentId]="processID"
		[displayId]="process_data_copy?.display_id"
		[summary]="process_data_copy?.basic_info?.summary"
		(loadAttachments)="loadAttachments($event)"
	>
	</app-dns-attachment>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="expected-assingee-sidebar"
	overlayClass="modal-backdrop"
>
	<app-expected-assingee-sidebar
		*ngIf="showEscalation"
		[incident]="process_data_copy"
		[escalationLevels]="escalataionData"
		(onHideEscalation)="onHideEscalation($event)"
		(getIncident)="getIncidentData()"
	>
	</app-expected-assingee-sidebar>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="dns-incident-edit-sidebar"
	overlayClass="modal-backdrop"
>
	<dns-incident-edit-sidebar
		*ngIf="hideIncidentInlineEdit"
		#editSidebar
		[incidentId]="processID"
		[incident_data]="process_data"
		[incident_data_copy]="process_data_copy"
		[dropdown]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[inci_extra_info]="process_extra_info"
		[currentUserProfileId]="currentUserProfileId"
		(onhideInlineEdit)="onhideInlineEdit($event)"
		(reloadCommunicationData)="reloadCommunicationData()"
		(getIncident)="getIncidentData()"
		[permissions]="permissions"
	>
	</dns-incident-edit-sidebar>
</core-sidebar>
<!-- End of Incident Sidebar -->

















<!-- Request Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-request-attachment"
	overlayClass="modal-backdrop"
>
	<app-dns-request-attachment
		*ngIf="!isHideAddAttachment && moduleName === 'Request'"
		(hideAddAttachment)="hideAddAttachment($event)"
		[requestId]="processID"
		[displayId]="process_data_copy?.display_id"
		[summary]="process_data_copy?.basic_info?.summary"
		(loadAttachments)="loadAttachments($event)"
	>
	</app-dns-request-attachment>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="process-detail-request-resolve" overlayClass="modal-backdrop">
	<app-dns-request-resolve
		*ngIf="showReqResolve"
		[request_id]="processID"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		(onHideResolve)="onHideResolve($event)"
		(getRequests)="getRrequestData()"
	>
	</app-dns-request-resolve>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="process-detail-request-close" overlayClass="modal-backdrop">
	<app-dns-request-close
		*ngIf="showReqClose"
		[request_id]="processID"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		(onHideClose)="onHideClose($event)"
		(getRequests)="getRrequestData()"
	>
	</app-dns-request-close>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="request-to-change-panelView"
	overlayClass="modal-backdrop"
>
	<app-dns-request-to-change
		*ngIf="showConvReqToChange"
		[request_data]="process_data_copy"
		(onHideConvReqToChange)="onHideConvReqToChange($event)"
		(addedChangeID)="getChangeID($event)"
	>
	</app-dns-request-to-change>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="request-to-incident" overlayClass="modal-backdrop">
	<!-- [options]="dropdown" -->
	<app-dns-request-to-incident
		*ngIf="showConvReqToInci"
		[incident_data]="process_data_copy"
		(onHideConvReqToInci)="onHideConvReqToInci($event)"
		(addedInciID)="getInciID($event)"
	>
	</app-dns-request-to-incident>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-request-incident-view"
	overlayClass="modal-backdrop"
>
	<app-dns-request-incident-view
		[incident_data]="incident_data"
		[incident_id] ="process_data_copy?.incident?.incident_id"
		*ngIf="showConvReqToInciView"
		(onHideConvReqToInciView)="onHideConvReqToInciView($event)"
	>
	</app-dns-request-incident-view>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="request-edit-sidebar"
	overlayClass="modal-backdrop"
>
	<app-dns-request-edit-sidebar
		*ngIf="hideRequestInlineEdit"
		#editSidebar
		[requestId]="processID"
		[request_data]="process_data"
		[request_data_copy]="process_data_copy"
		[dropdown]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[req_extra_info]="process_extra_info"
		[currentUserProfileId]="currentUserProfileId"
		[service_form_details]="service_form_details"
		(onhideInlineEdit)="onhideInlineEdit($event)"
		(reloadCommunicationData)="reloadCommunicationData()"
		(getRequest)="getRrequestData()"
		[permissions]="permissions"
	>
	</app-dns-request-edit-sidebar>
</core-sidebar>
<!-- End of Request Sidebar -->

<!-- Problem Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="problem-edit-sidebar"
	overlayClass="modal-backdrop"
>
	<app-dns-problem-edit-sidebar
		*ngIf="hideProblemInlineEdit"
		#editSidebar
		[problemId]="processID"
		[problem_data]="process_data"
		[problem_data_copy]="process_data_copy"
		[dropdown]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[prob_extra_info]="process_extra_info"
		[currentUserProfileId]="currentUserProfileId"
		(onhideInlineEdit)="onhideInlineEdit($event)"
		(reloadCommunicationData)="reloadCommunicationData()"
		(getProblem)="getProblemData()"
		[permissions]="permissions"
	>
	</app-dns-problem-edit-sidebar>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-problem-attachment"
	overlayClass="modal-backdrop"
>
	<app-dns-problem-attachment
		*ngIf="!isHideAddAttachment && moduleName === 'Problem'"
		(hideAddAttachment)="hideAddAttachment($event)"
		[problemId]="processID"
		[displayId]="process_data_copy?.display_id"
		[summary]="process_data_copy?.basic_info?.summary"
		(loadAttachments)="loadAttachments($event)"
	>
	</app-dns-problem-attachment>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="process-detail-problem-resolve"
	overlayClass="modal-backdrop"
>
	<app-dns-problem-resolve
		*ngIf="showProbResolve"
		[problem_id]="processID"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		(onHideResolve)="onHideResolve($event)"
		(getproblems)="getProblemData()"
	>
	</app-dns-problem-resolve>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="process-detail-problem-close" overlayClass="modal-backdrop">
	<app-dns-problem-close
		*ngIf="showProbClose"
		[problem_id]="processID"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		(onHideClose)="onHideClose($event)"
		(getproblems)="getProblemData()"
	>
	</app-dns-problem-close>
</core-sidebar>



<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="add-problem-to-change"
	overlayClass="modal-backdrop"
>
	<app-dns-problem-to-change
		*ngIf="showConvProbToChange"
		[problem_data]="process_data_copy"
		(onHideConvProbToChange)="onHideConvProbToChange($event)"
		(addedChangeID)="getChangeID($event)"
	>
	</app-dns-problem-to-change>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="problem-to-incident"
	overlayClass="modal-backdrop"
>
	<app-dns-problem-to-incident
		*ngIf="showConvProbToInci"
		[incident_data]="process_data_copy"
		(onHideConvProbToInci)="onHideConvProbToInci($event)"
		(addedInciID)="getInciID($event)"
	>
	</app-dns-problem-to-incident>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-problem-incident-view"
	overlayClass="modal-backdrop"
>
	<app-dns-problem-incident-view
		[incident_data]="incident_data"
		[incident_id]="process_data_copy?.incident?.incident_id"
		*ngIf="showConvProbToInciView"
		(onHideConvProbToInciView)="onHideConvProbToInciView($event)"
	>
	</app-dns-problem-incident-view>
</core-sidebar>



<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" overlayClass="modal-backdrop" name="app-problem-analysis-sidebar">
	<app-dns-problem-analysis-sidebar
		[action]="action"
		*ngIf="!hideProblemSideBar" 
		[problem_data]="process_data_copy"
		[options]="dropdown"
		[root_cause_id]="current_root_cause_id"
		(onHideProfile)="hideAnaysisModal($event)"
	></app-dns-problem-analysis-sidebar>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" overlayClass="modal-backdrop" name="app-problem-solution-sidebar">
	<app-dns-problem-solution-sidebar 
		[currentSolutionType]="'create'"
		[options]="dropdown"
		[problem_data]="process_data_copy"
		[root_cause_id]="current_root_cause_id"
		[root_cause_type]="current_root_cause_type"
		*ngIf="!hideProblemSolutionSideBar" 
		(onHideProfile)="hideSolnModal($event)"
	></app-dns-problem-solution-sidebar>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" overlayClass="modal-backdrop" name="app-view-draft-sidebar">
	<app-dns-problem-view-draft-sidebar
		[action]="action"
		[problem_id]="process_data_copy?.problem_id"
		[options]="dropdown"
		[problem_data]="process_data_copy"
		*ngIf="!hideProblemDraftSideBar" 
		(onHideProfile)="hideAnaysisDraftModal($event)"
		[disable_config]="disable_config"
	></app-dns-problem-view-draft-sidebar>
</core-sidebar>
<!-- End of Problem Sidebar -->
<!-- Change Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-change-attachment"
	overlayClass="modal-backdrop"
>
	<app-dns-change-attachment
		*ngIf="!isHideAddAttachment && moduleName === 'Change'"
		(hideAddAttachment)="hideAddAttachment($event)"
		[changeId]="processID"
		[displayId]="process_data_copy?.display_id"
		[summary]="process_data_copy?.basic_info?.summary"
		(loadAttachments)="loadAttachments($event)"
	>
	</app-dns-change-attachment>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="change-to-incident" overlayClass="modal-backdrop">
	<!-- [options]="dropdown" -->
	<app-dns-change-to-incident
		*ngIf="showConvChnToInci"
		[incident_data]="process_data_copy"
		(onHideConvChnToInci)="onHideConvChnToInci($event)"
		(addedInciID)="getInciID($event)"
	>
	</app-dns-change-to-incident>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-change-incident-view"
	overlayClass="modal-backdrop"
>
	<app-dns-change-incident-view
		[incident_data]="incident_data"
		[incident_id] ="process_data_copy?.incident?.incident_id"
		*ngIf="showConvChnToInciView"
		(onHideConvChnToInciView)="onHideConvChnToInciView($event)"
	>
	</app-dns-change-incident-view>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="change-impact" overlayClass="modal-backdrop">
	<app-dns-change-impact
		*ngIf="showImpactAdd"
		[change_data]="process_data"
		[impact_id]="impact_id"
		[options]="dropdown"
		(onHideImpact)="onHideImpact($event)"
	></app-dns-change-impact>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="risk-sidebar" overlayClass="modal-backdrop">
	<app-dns-change-risk
		*ngIf="hideRiskProfile"
		[change_data]="process_data"
		[risk_id]="risk_id"
		[options]="dropdown"
		(onHideRisk)="onHideRisk($event)"
	>
	</app-dns-change-risk>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="create-task-sidebar"
	overlayClass="modal-backdrop"
>
	<app-dns-create-task
		*ngIf="hideTaskProfile"
		[change_data]="process_data"
		[task_id]="task_id"
		[options]="dropdown"
		(onHideTask)="onHideTask($event)"
	></app-dns-create-task>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="view-task-sidebar"
	overlayClass="modal-backdrop"
>
	<app-dns-view-task
		*ngIf="hideViewTaskProfile"
		[change_data]="process_data"
		[task_id]="task_id"
		[options]="dropdown"
		(onHideTask)="onHideTask($event)"
	></app-dns-view-task>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="change-edit-sidebar"
	overlayClass="modal-backdrop"
>
	<app-dns-change-edit-sidebar
		*ngIf="hideChangeInlineEdit"
		#editSidebar
		[changeId]="processID"
		[change_data]="change_process_data"
		[change_data_copy]="process_data_copy"
		[dropdown]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[change_extra_info]="process_extra_info"
		[currentUserProfileId]="currentUserProfileId"
		(onhideInlineEdit)="onhideInlineEdit($event)"
		(reloadCommunicationData)="reloadCommunicationData()"
		(getChange)="getChangeData()"
		[permissions]="permissions"
	>
	</app-dns-change-edit-sidebar>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="process-detail-Change-close" overlayClass="modal-backdrop">
	<app-dns-change-close
		*ngIf="showChangeClose"
		[change_id]="processID"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		(onHideClose)="onHideClose($event)"
		(getChanges)="getChangeData()"
	>
	</app-dns-change-close>
</core-sidebar>
<!-- End of Change Sidebar -->
<!-- Release Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="release-edit-sidebar"
	overlayClass="modal-backdrop"
>
	<app-dns-release-edit-sidebar
		*ngIf="hideReleaseInlineEdit"
		#editSidebar
		[release_data_copy]="process_data_copy"
		[dropdown]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[release_extra_info]="process_extra_info"
		[currentUserProfileId]="currentUserProfileId"
		(onhideInlineEdit)="onhideInlineEdit($event)"
		(reloadCommunicationData)="reloadCommunicationData()"
		(getRelease)="getReleaseData()"
		[permissions]="permissions"
		[taskMaxDueDate]="taskMaxDueDate"
	>
	</app-dns-release-edit-sidebar>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="add-task" overlayClass="modal-backdrop">
	<app-dns-release-add-task
		*ngIf="isAddTask"
		[release_data]="process_data"
		[options]="dropdown"
		[type_id] = "task_type_id"
		(onHideTask)="onHideTask($event)"
	></app-dns-release-add-task>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="view-task" overlayClass="modal-backdrop">
	<app-dns-release-view-task
		*ngIf="isViewTask"
		[featureViewTaskData]="featureViewTaskData"
		[options]="dropdown"
		[release_data]="process_data"
		[disable_config]="disable_config"
        (getTaskList)="getTaskList()"
		(onHideQuickEdit)="onHideQuickEdit($event)"
	></app-dns-release-view-task>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-release-attachment"
	overlayClass="modal-backdrop"
>
	<app-dns-release-attachment
		*ngIf="!isHideAddAttachment && moduleName === 'Release'"
		(hideAddAttachment)="hideAddAttachment($event)"
		[release_id]="processID"
		[displayId]="process_data?.display_id"
		[summary]="process_data?.basic_info?.summary"
		(loadAttachments)="loadAttachments($event)"
	>
	</app-dns-release-attachment>
</core-sidebar>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="process-detail-release-close" overlayClass="modal-backdrop">
	<app-dns-release-close
		*ngIf="showReleaseClose"
		[release_id]="processID"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		(onHideClose)="onHideClose($event)"
		(getReleases)="getReleaseData()"
	>
	</app-dns-release-close>
</core-sidebar>
<!-- End of Release Sidebar -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="asset-select-view"
	overlayClass="modal-backdrop"
>
	<dns-asset-select
		*ngIf="showAssetListView"
		(hideAddAsset)="addAsset($event)"
		[requester]="process_data?.requester"
		[selected_impacted_asset]="process_data?.impacted_asset"
	>
	</dns-asset-select>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-ai-kb-component"
	overlayClass="modal-backdrop"
>
	<app-ai-kb-component
		[moduleType]="communicationModuleType"
		[refData]="kbAISolution" 
		*ngIf="showAIKKSolution"
		(onHideAIKBSolution)="onHideAIKBSolution()"
		(openKbDraftSidebar)="openKbDraftSidebar($event)"
		(toggleToKbSidebar)="toggleInciToKbSidebar('app-dns-kb-add')"
		
	> 
	</app-ai-kb-component>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="close-ticket"
	overlayClass="modal-backdrop"
>
	<app-close-ticket-sidebar
		*ngIf="showCloseTicketSidebar"
		[incident_id]="processID"
		[sidebarName]="closeSSPSidebarName"
		[action]="resolve_action"
		(onHideClose)="onHideSSPClose($event)"
	>
	</app-close-ticket-sidebar >
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="close-request"
	overlayClass="modal-backdrop"
>
	<app-close-ticket-sidebar
		*ngIf="showCloseRequestSidebar"
		[request_id]="processID"
		[sidebarName]="closeSSPSidebarName"
		[action]="resolve_action"
		(onHideClose)="onHideSSPClose($event)"
	>
	</app-close-ticket-sidebar >
</core-sidebar>