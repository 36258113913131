<ng-scrollbar class="custom-scrollbar section-scrollbar" track="all">
	<table class="table data-table-main-block-sec">
		<thead>
			<tr>
				<ng-container *ngFor="let headerItem of tableHeader">
					<th
						scope="col"
						[class.t-width]="
							headerItem.key == 'las1' ||
							headerItem.key == 'las3' ||
							headerItem.key == 'las7' ||
							headerItem.key == 'las15' ||
							headerItem.key == 'las30' ||
							headerItem.key == 'plus30day' ||
							headerItem.value == 'key_based'
						"
					>
						{{ headerItem.name }}
					</th>
				</ng-container>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let item of tableData">
				<tr>
					<td scope="row">
						<div style="text-align: left; padding: 0px 8px">
							{{ item.name }}
						</div>
					</td>
					<td>
						<div class="table-dat-sec-total w-total">
							{{ item.total }}
						</div>
					</td>
					<td>
						<span
							class="table-dat-sec"
							*ngIf="item.las1"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ item.las1 }}% of total"
							placement="top"
							(click)="showEventTable(widgetdata, item.alarms.last1)"
						>
							<a (click)="showEventTable(widgetData, item.alarms.last1)">{{ item.tlas1 }}</a>
						</span>
					</td>
					<td>
						<span
							class="table-dat-sec"
							*ngIf="item.las3"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ item.las3 }}% of total"
							placement="top"
						>
							<a (click)="showEventTable(widgetData, item.alarms.last3)">{{ item.tlas3 }}</a>
						</span>
					</td>
					<td>
						<span
							class="table-dat-sec"
							*ngIf="item.las7"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ item.las7 }}% of total"
							placement="top"
						>
							<a (click)="showEventTable(widgetData, item.alarms.last7)">{{ item.tlas7 }}</a>
						</span>
					</td>
					<td>
						<span
							class="table-dat-sec"
							*ngIf="item.las15"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ item.las15 }}% of total"
							placement="top"
						>
							<a (click)="showEventTable(widgetData, item.alarms.last15)">{{ item.tlas15 }}</a>
						</span>
					</td>
					<td>
						<span
							class="table-dat-sec"
							*ngIf="item.las30"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ item.las30 }}% of total"
							placement="top"
						>
							<a (click)="showEventTable(widgetData, item.alarms.last30)">{{ item.tlas30 }}</a>
						</span>
					</td>
					<td>
						<span
							class="table-dat-sec"
							*ngIf="item.plus30days"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ item.plus30days }}% of total"
							placement="top"
						>
							<a (click)="showEventTable(widgetData, item.alarms.last30p)">{{ item.tplus30days }}</a>
						</span>
					</td>
					<ng-container *ngFor="let headerItem of tableHeader">
						<td *ngIf="item?.key_based && (headerItem.key != 'title' && headerItem.key != 'total') ">
							<span
								class="table-dat-sec cursor-pointer"
								*ngIf="item[headerItem.key]"
								tooltipClass="fadeInAnimation"
								ngbTooltip="{{ item['t' + headerItem.key] }}% of total"
								placement="top"
								(click)="showAssetSidebar(widgetData, item, headerItem.key)"
							>
								<a>{{ item[headerItem.key] }}</a>
							</span>
						</td>
					</ng-container>
				</tr>
			</ng-container>
		</tbody>
	</table>
</ng-scrollbar>
